import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  Grid as MuiGrid,
  Link as MuiLink,
  Paper as MuiPaper,
  Table as MuiTable,
  Button as MuiButton,
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableBody as MuiTableBody,
  IconButton as MuiIconButton,
  Typography as MuiTypography,
  TableContainer as MuiTableContainer,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import {
  Add as MuiAddIcon,
  Edit as MuiEditIcon,
  Delete as MuiDeleteIcon,
  AddCircle as MuiAddCircleIcon,
  Attachment as MuiAttachmentIcon,
  NewReleases as MuiNewReleasesIcon,
  PriceChange as MuiPriceChangeIcon,
  CloudDownload as MuiCloudDownloadIcon,
  PlaylistAddRounded as MuiPlaylistAddRoundedIcon,
  AssignmentReturnedOutlined as MuiAssignmentReturnedOutlinedIcon,
} from "@mui/icons-material";
import { useAlerts, useUsers, Theme } from "common";
import {
  getBudget,
  putBudgetByComponentId,
} from "app/services/budgetingService";
import { useBudgetingContext } from "app/services/budgetingContext";
import {
  ComponentTypes,
  LinkToFacultyNewHireTemplate,
} from "app/shared/constants";
import { useDownload } from "app/shared/ui/Download";
import fileTypes from "app/shared/ui/DropZone/fileTypes.json";
import { InlineForm } from "./InlineForm";
import { BudgetTableHeader } from "./BudgetTableHeader";
import { BudgetTableFooter } from "./BudgetTableFooter";
import { InformationPanel } from "./InformationPanel";

export const BudgetTable = ({
  setSnackBar,
  isBudgetEditableByCurrentUser,
  loading: inhertiedLoading,
}) => {
  const { t } = useTranslation();
  const { currentUser } = useUsers();
  const { permissions } = currentUser;
  const { getDocument, downloadInProgress } = useDownload();

  const { setAlert, clearAlert } = useAlerts();

  // State variables
  const [budget, setBudget] = useState([]);
  const [loading, setLoading] = useState(inhertiedLoading);

  const [editComponent, setEditComponent] = useState(0);

  const [refresh, setRefresh] = useState(0);

  // context
  const {
    orgCode,
    orgName,
    setInfoPanelDisabled,
    budgetFiscalYear,
    budgetType,
    getQueryParams,
  } = useBudgetingContext();

  const colorForAllOtherButtons = editComponent ? "disabled" : "inherit";

  // Styles
  const parentCellStyles = {
    borderTop: `1px solid ${Theme.palette.grey[300]}`,
  };
  const childCellPrimaryStyles = {
    textIndent: 20,
    borderBottomStyle: "none",
    padding: "2px 2px",
  };
  const childCellSecondaryStyles = {
    borderBottomStyle: "none",
    padding: "2px 8px",
  };

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    orgCode && budgetFiscalYear
      ? getBudget(
          getQueryParams, // includes the fiscalYear value
          setBudget,
          setLoading,
          setAlert,
          clearAlert,
          cancelSource
        )
      : setBudget([]);
    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [budgetFiscalYear, orgCode, budgetType, refresh]);

  return (
    <>
      <MuiGrid container item xs={12} mb={2} justifyContent="space-between">
        <MuiGrid item xs={10}>
          <MuiPaper elevation={2}>
            <InformationPanel />
          </MuiPaper>
        </MuiGrid>
        <MuiGrid item>
          {isBudgetEditableByCurrentUser() &&
            permissions.CREATE_REQUEST_COMMITMENTS && (
              <RouterLink
                style={{ textDecoration: "none" }}
                to={{
                  pathname: "/commitments/requests/new",
                }}
                state={{
                  orgCode,
                  orgName,
                  currentFiscalYear: budgetFiscalYear,
                  budgetEditableByCurrentUser: isBudgetEditableByCurrentUser(),
                }}
              >
                <MuiButton
                  variant="contained"
                  color="primary"
                  startIcon={<MuiAddCircleIcon />}
                >
                  {t(`Budgeting.mainView.newRequest`)}
                </MuiButton>
              </RouterLink>
            )}
        </MuiGrid>
      </MuiGrid>
      <MuiTableContainer component={MuiPaper}>
        <MuiTable
          aria-label="Commitments for the current fiscal year"
          aria-live="polite"
          stickyHeader
        >
          <BudgetTableHeader budgetFiscalYear={budgetFiscalYear} />
          <MuiTableBody>
            {loading && (
              <MuiTableRow>
                <MuiTableCell colSpan="16" align="center">
                  <MuiCircularProgress />
                </MuiTableCell>
              </MuiTableRow>
            )}
            {!loading && !budget.commitments && (
              <MuiTableRow>
                <MuiTableCell colSpan="16" align="center">
                  {t("globals.list.messages.noData")}
                </MuiTableCell>
              </MuiTableRow>
            )}
            {!loading &&
              budget?.commitments?.values.map((i, indexI) => (
                <Fragment key={indexI}>
                  <MuiTableRow>
                    <MuiTableCell align="left" sx={parentCellStyles}>
                      <MuiTypography variant="body2" noWrap>
                        {i.commitmentType === "REQUEST" && (
                          <MuiTooltip
                            title={t(`Budgeting.mainView.newRequest`)}
                          >
                            <MuiNewReleasesIcon
                              size="small"
                              sx={{
                                color: Theme.palette.warning.main,
                                verticalAlign: "text-bottom",
                                marginRight: "5px",
                              }}
                            />
                          </MuiTooltip>
                        )}

                        <MuiTooltip title={i.commitmentName} placement="top">
                          {getCommitmentName(
                            editComponent,
                            i.commitmentId,
                            i.commitmentName
                          )}
                        </MuiTooltip>
                      </MuiTypography>
                    </MuiTableCell>
                    <MuiTableCell align="right" sx={parentCellStyles}>
                      <MuiTypography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.totalCommitmentRequested)}
                      </MuiTypography>
                    </MuiTableCell>
                    <MuiTableCell align="right" sx={parentCellStyles}>
                      <MuiTypography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyActualsThree)}
                      </MuiTypography>
                    </MuiTableCell>
                    <MuiTableCell align="right" sx={parentCellStyles}>
                      <MuiTypography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyActualsTwo)}
                      </MuiTypography>
                    </MuiTableCell>

                    <MuiTableCell align="right" sx={parentCellStyles}>
                      <MuiTypography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.totalExpenditure)}
                      </MuiTypography>
                    </MuiTableCell>

                    <MuiTableCell align="right" sx={parentCellStyles}>
                      <MuiTypography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.unspentCommitment)}
                      </MuiTypography>
                    </MuiTableCell>
                    <MuiTableCell align="right" sx={parentCellStyles}>
                      <MuiTypography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyActualsOne)}
                      </MuiTypography>
                    </MuiTableCell>
                    <MuiTableCell align="right" sx={parentCellStyles}>
                      <MuiTypography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyBudgetOne)}
                      </MuiTypography>
                    </MuiTableCell>
                    <MuiTableCell align="right" sx={parentCellStyles}>
                      <MuiTypography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyProjectionOne)}
                      </MuiTypography>
                    </MuiTableCell>
                    <MuiTableCell align="right" sx={parentCellStyles}>
                      <MuiTypography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyBudget)}
                      </MuiTypography>
                    </MuiTableCell>
                    <MuiTableCell align="right" sx={parentCellStyles}>
                      <MuiTypography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyPlanOne)}
                      </MuiTypography>
                    </MuiTableCell>
                    <MuiTableCell align="right" sx={parentCellStyles}>
                      <MuiTypography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyPlanTwo)}
                      </MuiTypography>
                    </MuiTableCell>
                    <MuiTableCell align="right" sx={parentCellStyles}>
                      <MuiTypography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.availableAfterFYPlanTwo)}
                      </MuiTypography>
                    </MuiTableCell>
                    <MuiTableCell align="right" sx={parentCellStyles}>
                      <MuiTypography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyBudgetCap)}
                      </MuiTypography>
                    </MuiTableCell>
                    <MuiTableCell
                      align="left"
                      sx={parentCellStyles}
                    ></MuiTableCell>
                    <MuiTableCell align="left" sx={parentCellStyles}>
                      <MuiGrid
                        container
                        justifyContent="flex-start"
                        wrap="nowrap"
                      >
                        {isBudgetEditableByCurrentUser() &&
                          permissions.CREATE_REQUEST_COMPONENTS && (
                            <MuiGrid item>
                              <RouterLink
                                style={{ textDecoration: "none" }}
                                to={{
                                  pathname: `/commitments/${i.commitmentId}/components/requests/new`,
                                }}
                                state={{
                                  currentFiscalYear: budgetFiscalYear,
                                }}
                              >
                                <MuiTooltip
                                  title={t("Budgeting.mainView.newComponent")}
                                  placement="top"
                                  disableFocusListener={Boolean(editComponent)}
                                  disableHoverListener={Boolean(editComponent)}
                                  disableTouchListener={Boolean(editComponent)}
                                >
                                  <MuiPlaylistAddRoundedIcon
                                    size="small"
                                    color={colorForAllOtherButtons}
                                  />
                                </MuiTooltip>
                              </RouterLink>
                            </MuiGrid>
                          )}
                        {isBudgetEditableByCurrentUser() &&
                          permissions.EDIT_REQUEST_COMMITMENTS &&
                          i.commitmentType === "REQUEST" && (
                            <MuiGrid item>
                              <RouterLink
                                style={{ textDecoration: "none" }}
                                to={{
                                  pathname: `/commitments/requests/${i.commitmentId}/edit`,
                                }}
                                state={{
                                  currentFiscalYear: budgetFiscalYear,
                                }}
                              >
                                <MuiTooltip
                                  title={t("Budgeting.mainView.editRequest")}
                                  placement="top"
                                  disableFocusListener={Boolean(editComponent)}
                                  disableHoverListener={Boolean(editComponent)}
                                  disableTouchListener={Boolean(editComponent)}
                                >
                                  <MuiEditIcon
                                    size="small"
                                    color={colorForAllOtherButtons}
                                  />
                                </MuiTooltip>
                              </RouterLink>
                            </MuiGrid>
                          )}
                        {isBudgetEditableByCurrentUser() &&
                          permissions.DELETE_REQUEST_COMMITMENTS &&
                          i.commitmentType === "REQUEST" && (
                            <MuiGrid item>
                              <RouterLink
                                style={{ textDecoration: "none" }}
                                to={{
                                  pathname: `/commitments/requests/${i.commitmentId}/delete`,
                                }}
                                state={{
                                  currentFiscalYear: budgetFiscalYear,
                                }}
                              >
                                <MuiTooltip
                                  title={t("Budgeting.mainView.deleteRequest")}
                                  placement="top"
                                  disableFocusListener={Boolean(editComponent)}
                                  disableHoverListener={Boolean(editComponent)}
                                  disableTouchListener={Boolean(editComponent)}
                                >
                                  <MuiDeleteIcon
                                    size="small"
                                    color={colorForAllOtherButtons}
                                  />
                                </MuiTooltip>
                              </RouterLink>
                            </MuiGrid>
                          )}
                        {isBudgetEditableByCurrentUser() &&
                          permissions.UPLOAD_COMPLETED_NEW_HIRE_EXCEL_TEMPLATE &&
                          i.commitmentRequestType === "RECRUITMENT" && (
                            <>
                              <MuiGrid item>
                                <RouterLink
                                  style={{ textDecoration: "none" }}
                                  to={{
                                    pathname: getRecruitmentDocumentPageUrl(i),
                                  }}
                                  state={{
                                    docVariant: "NEW_HIRE_EXCEL_TEMPLATE",
                                    documentType: "RECRUITMENT",
                                    documentName: "New Hire Template Complete",
                                    documentNotes: "New Hire Template Complete",
                                    departmentAdminView: true,
                                    acceptedFiles: [
                                      "application/vnd.ms-excel",
                                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                    ],
                                    dropzoneText: t(
                                      "Commitments.commitmentDocuments.create.form.fields.uploadedDocument.label2",
                                      {
                                        extensionList: fileTypes[
                                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        ]
                                          .map((x) => `.${x}`)
                                          .join(", "),
                                      }
                                    ),
                                  }}
                                >
                                  <MuiTooltip
                                    title={t(
                                      "Commitments.commitmentDocuments.mainView.addNewHireTemplate"
                                    )}
                                    placement="top"
                                    disableFocusListener={Boolean(
                                      editComponent
                                    )}
                                    disableHoverListener={Boolean(
                                      editComponent
                                    )}
                                    disableTouchListener={Boolean(
                                      editComponent
                                    )}
                                  >
                                    <span
                                      style={{
                                        position: "relative",
                                        "& .decorativeIcon": {
                                          height: "16px",
                                          width: "16px",
                                          fontWeight: "bold",
                                          bottom: 0,
                                          right: "-6px",
                                          border: "1px solid transparent",
                                          borderRadius: "300px",
                                          position: "absolute",
                                        },
                                      }}
                                    >
                                      <MuiAttachmentIcon
                                        size="small"
                                        color={colorForAllOtherButtons}
                                      />
                                      <MuiAddIcon
                                        size="small"
                                        color={colorForAllOtherButtons}
                                        sx={{
                                          height: "16px",
                                          width: "16px",
                                          fontWeight: "bold",
                                          bottom: 0,
                                          right: "-6px",
                                          border: "1px solid transparent",
                                          borderRadius: "300px",
                                          position: "absolute",
                                        }}
                                      />
                                    </span>
                                  </MuiTooltip>
                                </RouterLink>
                              </MuiGrid>
                              <MuiGrid item>
                                <MuiTooltip
                                  title={
                                    t(
                                      "Commitments.commitmentDocuments.mainView.list.downloadCommitmentDocumentsTooltip"
                                    ) +
                                    " " +
                                    t(
                                      "Commitments.create.recruitment.templateDownloadTooltip"
                                    )
                                  }
                                  placement="top"
                                  disableFocusListener={Boolean(editComponent)}
                                  disableHoverListener={Boolean(editComponent)}
                                  disableTouchListener={Boolean(editComponent)}
                                >
                                  {/* This button triggers a file download from a cloud CDN location */}
                                  <MuiIconButton
                                    size="small"
                                    component={MuiLink}
                                    href={
                                      LinkToFacultyNewHireTemplate.downloadUrl
                                    }
                                    download={
                                      LinkToFacultyNewHireTemplate.filename
                                    }
                                  >
                                    <MuiAssignmentReturnedOutlinedIcon
                                      size="small"
                                      color={colorForAllOtherButtons}
                                    />
                                  </MuiIconButton>
                                </MuiTooltip>
                              </MuiGrid>
                            </>
                          )}
                        {i._links?.completedNewHireDocument && (
                          <MuiGrid item justifyContent="flex-end">
                            <MuiTooltip
                              title={t(
                                "Commitments.commitmentDocuments.mainView.viewNewHireTemplate"
                              )}
                              placement="top"
                              disableFocusListener={Boolean(editComponent)}
                              disableHoverListener={Boolean(editComponent)}
                              disableTouchListener={Boolean(editComponent)}
                            >
                              <span>
                                <MuiIconButton
                                  size="small"
                                  disabled={!!downloadInProgress}
                                  onClick={() => {
                                    let url = parseUrl(
                                      i._links?.completedNewHireDocument.href
                                    );
                                    getDocument(
                                      axios.defaults.baseURL + url.pathname,
                                      i._links?.completedNewHireDocument?.title
                                    );
                                  }}
                                >
                                  {downloadInProgress ? (
                                    <MuiCircularProgress size={15} />
                                  ) : (
                                    <MuiCloudDownloadIcon />
                                  )}
                                </MuiIconButton>
                              </span>
                            </MuiTooltip>
                          </MuiGrid>
                        )}
                      </MuiGrid>
                    </MuiTableCell>
                  </MuiTableRow>
                  {i?.componentType?.values?.map((j, indexJ) => (
                    <Fragment key={indexJ}>
                      {/* Component Type Label/Header Row */}
                      <MuiTableRow
                        sx={{
                          backgroundColor: Theme.palette.grey[100],
                          "&:last-child td": {
                            paddingBottom: "20px",
                          },
                        }}
                      >
                        <MuiTableCell sx={childCellPrimaryStyles} align="left">
                          <MuiTypography
                            align="left"
                            variant="body2"
                            sx={{ fontWeight: 600 }}
                          >
                            {t(ComponentTypes[j.componentType])}
                          </MuiTypography>
                        </MuiTableCell>
                        <MuiTableCell
                          colSpan="15"
                          sx={childCellPrimaryStyles}
                        ></MuiTableCell>
                      </MuiTableRow>

                      {/* Component Rows */}
                      {j.components.values.map((k, indexK) =>
                        k.componentId === editComponent ? (
                          <InlineForm
                            row={k}
                            commitmentId={i.commitmentId}
                            setEditComponent={setEditComponent}
                            putBudgetByComponentId={putBudgetByComponentId}
                            componentId={k.componentId}
                            budgetFiscalYear={budgetFiscalYear}
                            setSnackBar={setSnackBar}
                            setRefresh={setRefresh}
                            editComponent={editComponent}
                            setInfoPanelDisabled={setInfoPanelDisabled}
                            key={indexK}
                          />
                        ) : (
                          <MuiTableRow
                            key={indexK}
                            sx={{
                              backgroundColor: Theme.palette.grey[100],
                              "&:last-child td": {
                                paddingBottom: "20px",
                              },
                            }}
                          >
                            <MuiTableCell sx={childCellPrimaryStyles}>
                              <MuiTypography
                                variant="body2"
                                noWrap
                                sx={{
                                  textOverflow: "ellipsis",
                                  width: "280px",
                                }}
                              >
                                {k.componentType === "REQUEST" && (
                                  <MuiTooltip
                                    title={t(`Budgeting.mainView.newComponent`)}
                                  >
                                    <MuiNewReleasesIcon
                                      size="small"
                                      sx={{
                                        color: Theme.palette.warning.main,
                                        verticalAlign: "text-bottom",
                                        marginRight: "5px",
                                      }}
                                    />
                                  </MuiTooltip>
                                )}
                                <RenderPTAs
                                  component={k}
                                  editComponent={editComponent}
                                  viewPtas={permissions.VIEW_PTAS}
                                />{" "}
                                <MuiTooltip
                                  title={k.componentName}
                                  placement="top"
                                >
                                  {getComponentLink(
                                    editComponent,
                                    i.commitmentId,
                                    k.componentId,
                                    k.componentName
                                  )}
                                </MuiTooltip>
                              </MuiTypography>
                            </MuiTableCell>
                            <MuiTableCell
                              align="right"
                              sx={childCellSecondaryStyles}
                            >
                              <MuiTypography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.totalComponentAmountRequested)}
                              </MuiTypography>
                            </MuiTableCell>
                            <MuiTableCell
                              align="right"
                              sx={childCellSecondaryStyles}
                            >
                              <MuiTypography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyActualsThree)}
                              </MuiTypography>
                            </MuiTableCell>
                            <MuiTableCell
                              align="right"
                              sx={childCellSecondaryStyles}
                            >
                              <MuiTypography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyActualsTwo)}
                              </MuiTypography>
                            </MuiTableCell>
                            <MuiTableCell
                              align="right"
                              sx={childCellSecondaryStyles}
                            >
                              <MuiTypography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.totalExpenditure)}
                              </MuiTypography>
                            </MuiTableCell>
                            <MuiTableCell
                              align="right"
                              sx={childCellSecondaryStyles}
                            >
                              <MuiTypography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.totalUnspentAmount)}
                              </MuiTypography>
                            </MuiTableCell>
                            <MuiTableCell
                              align="right"
                              sx={childCellSecondaryStyles}
                            >
                              <MuiTypography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyActualsOne)}
                              </MuiTypography>
                            </MuiTableCell>
                            <MuiTableCell
                              align="right"
                              sx={childCellSecondaryStyles}
                            >
                              <MuiTypography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyBudgetOne)}
                              </MuiTypography>
                            </MuiTableCell>
                            <MuiTableCell
                              align="right"
                              sx={childCellSecondaryStyles}
                            >
                              <MuiTypography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyProjectionOne)}
                              </MuiTypography>
                            </MuiTableCell>
                            <MuiTableCell
                              align="right"
                              sx={childCellSecondaryStyles}
                            >
                              <MuiTypography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyBudget)}
                              </MuiTypography>
                            </MuiTableCell>
                            <MuiTableCell
                              align="right"
                              sx={childCellSecondaryStyles}
                            >
                              <MuiTypography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyPlanOne)}
                              </MuiTypography>
                            </MuiTableCell>
                            <MuiTableCell
                              align="right"
                              sx={childCellSecondaryStyles}
                            >
                              <MuiTypography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyPlanTwo)}
                              </MuiTypography>
                            </MuiTableCell>
                            <MuiTableCell
                              align="right"
                              sx={childCellSecondaryStyles}
                            >
                              <MuiTypography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.availableAfterFYPlanTwo)}
                              </MuiTypography>
                            </MuiTableCell>
                            <MuiTableCell
                              align="right"
                              sx={childCellSecondaryStyles}
                            >
                              <MuiTypography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyBudgetCap)}
                              </MuiTypography>
                            </MuiTableCell>
                            <MuiTableCell
                              align="left"
                              sx={childCellSecondaryStyles}
                            >
                              <MuiTypography
                                variant="body2"
                                component="span"
                                align="left"
                              >
                                {k.comments}
                              </MuiTypography>
                            </MuiTableCell>
                            <MuiTableCell
                              align="left"
                              sx={childCellSecondaryStyles}
                            >
                              <MuiGrid
                                container
                                justifyContent="flex-start"
                                wrap="nowrap"
                              >
                                {k._links?.edit && (
                                  <MuiGrid item>
                                    <MuiTooltip
                                      title={t(
                                        "Budgeting.mainView.list.editBudgetTooltip"
                                      )}
                                      placement="top"
                                      disableFocusListener={Boolean(
                                        editComponent
                                      )}
                                      disableHoverListener={Boolean(
                                        editComponent
                                      )}
                                      disableTouchListener={Boolean(
                                        editComponent
                                      )}
                                    >
                                      <MuiIconButton
                                        size="small"
                                        onClick={() => {
                                          if (!editComponent) {
                                            setEditComponent(k.componentId);
                                            setInfoPanelDisabled(true);
                                          }
                                        }}
                                      >
                                        <MuiPriceChangeIcon
                                          size="small"
                                          color={colorForAllOtherButtons}
                                        />
                                      </MuiIconButton>
                                    </MuiTooltip>
                                  </MuiGrid>
                                )}

                                {k.componentType === "REQUEST" &&
                                  k._links?.edit && (
                                    <MuiGrid item>
                                      <RouterLink
                                        style={{ textDecoration: "none" }}
                                        to={{
                                          pathname: `/commitments/${i.commitmentId}/components/requests/${k.componentId}/edit`,
                                        }}
                                        state={{
                                          currentFiscalYear: budgetFiscalYear,
                                        }}
                                      >
                                        <MuiTooltip
                                          title={t(
                                            "Budgeting.mainView.editComponent"
                                          )}
                                          placement="top"
                                          disableFocusListener={Boolean(
                                            editComponent
                                          )}
                                          disableHoverListener={Boolean(
                                            editComponent
                                          )}
                                          disableTouchListener={Boolean(
                                            editComponent
                                          )}
                                        >
                                          <MuiEditIcon
                                            size="small"
                                            color={colorForAllOtherButtons}
                                          />
                                        </MuiTooltip>
                                      </RouterLink>
                                    </MuiGrid>
                                  )}

                                {k.componentType === "REQUEST" &&
                                  k._links?.edit && (
                                    <MuiGrid item>
                                      <RouterLink
                                        style={{ textDecoration: "none" }}
                                        to={{
                                          pathname: `/commitments/${i.commitmentId}/components/requests/${k.componentId}/delete`,
                                        }}
                                        state={{
                                          currentFiscalYear: budgetFiscalYear,
                                        }}
                                      >
                                        <MuiTooltip
                                          title={t(
                                            "Budgeting.mainView.deleteComponent"
                                          )}
                                          placement="top"
                                          disableFocusListener={Boolean(
                                            editComponent
                                          )}
                                          disableHoverListener={Boolean(
                                            editComponent
                                          )}
                                          disableTouchListener={Boolean(
                                            editComponent
                                          )}
                                        >
                                          <MuiDeleteIcon
                                            size="small"
                                            color={colorForAllOtherButtons}
                                          />
                                        </MuiTooltip>
                                      </RouterLink>
                                    </MuiGrid>
                                  )}
                              </MuiGrid>
                            </MuiTableCell>
                          </MuiTableRow>
                        )
                      )}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
          </MuiTableBody>
          <BudgetTableFooter
            budget={budget}
            budgetFiscalYear={budgetFiscalYear}
          />
        </MuiTable>
      </MuiTableContainer>
    </>
  );
};

// HELPER FUNCTIONS

// getAmount - to format amount
export const getAmount = (amount) => {
  if (amount) {
    return CurrencyFormatMarkup(amount);
  } else {
    return `$0`;
  }
};

//Formatting of Project,task and string
function PTAFormat(project, task, award) {
  return `${project ? project : "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}-${
    task ? task : "\u00A0\u00A0\u00A0"
  }-${award ? award : "\u00A0\u00A0\u00A0\u00A0\u00A0"}`;
}

export const RenderPTAs = ({ component, editComponent, viewPtas }) => {
  if (component.validProject && component.validTask && component.validAward) {
    const pta = PTAFormat(component.project, component.task, component.award);
    if (!editComponent && viewPtas) {
      return (
        <RouterLink
          target="_blank"
          to={`/budgeting/ptas/${pta}`}
          style={{ textDecoration: "none" }}
        >
          <MuiTypography variant="body2" component="span">
            {pta}
          </MuiTypography>
        </RouterLink>
      );
    } else {
      return (
        <MuiTypography variant="body2" component="span">
          {pta}
        </MuiTypography>
      );
    }
  } else {
    return PTAFormat(component.project, component.task, component.award);
  }
};

export const getComponentLink = (
  editComponent,
  commitmentId,
  componentId,
  componentName
) => {
  if (!editComponent) {
    return (
      <RouterLink
        style={{ textDecoration: "none" }}
        target="_blank"
        to={`/commitments/${commitmentId}/components/${componentId}/financial-history`}
      >
        <MuiTypography
          display="inline"
          component={"span"}
          style={{ marginLeft: 10 }}
          variant="body2"
        >
          {componentName}
        </MuiTypography>
      </RouterLink>
    );
  } else {
    return (
      <MuiTypography
        display="inline"
        component={"span"}
        style={{ marginLeft: 10 }}
        variant="body2"
      >
        {componentName}
      </MuiTypography>
    );
  }
};

const getCommitmentName = (editComponent, commitmentId, commitmentName) => {
  if (!editComponent) {
    return (
      <RouterLink
        style={{ textDecoration: "none" }}
        target="_blank"
        to={`/budgeting/commitments/${commitmentId}/view`}
      >
        <MuiTypography
          noWrap
          variant="body2"
          display="inline"
          component={"span"}
        >
          {commitmentName}
        </MuiTypography>
      </RouterLink>
    );
  } else {
    return (
      <MuiTypography noWrap variant="body2" display="inline" component={"span"}>
        {commitmentName}
      </MuiTypography>
    );
  }
};

export function CurrencyFormatMarkup(value) {
  return (
    <>
      <MuiTypography variant="inherit" component="span">
        {value !== undefined && "$"}
      </MuiTypography>
      <MuiTypography
        component="span"
        variant="inherit"
        color={`${(value < 0 && "error") || "inherit"}`}
      >
        {value !== undefined &&
          `${(parseFloat(value) < 0 && "(") || ""}${new Intl.NumberFormat(
            "en-US",
            {
              style: "currency",
              currency: "USD",
            }
          )
            .format(Math.abs(value))
            .split("$")[1]
            .replace(/\D00$/, "")}${(parseFloat(value) < 0 && ")") || ""}`}
      </MuiTypography>
    </>
  );
}

const getRecruitmentDocumentPageUrl = (commitment) => {
  const documentId = !!commitment?.recruitmentDocument?._links?.edit
    ? new URL(commitment.recruitmentDocument._links.edit.href).pathname
        .split("/")
        .pop()
    : null;

  return documentId
    ? `/commitments/${commitment.commitmentId}/documents/${documentId}/edit`
    : `/commitments/${commitment.commitmentId}/documents/new`;
};

/**
 * Parses any URL into it's component parts.
 *
 * @param {string} url to be parsed
 * @returns {object} map of URL components
 */
const parseUrl = (url) => {
  var tmpLink = document.createElement("a");
  tmpLink.href = url;

  let { protocol, host, hostname, port, pathname, hash, search, origin } =
    tmpLink;

  // IE does not prefix with a slash
  if (pathname && pathname[0] !== "/") {
    pathname = "/" + pathname;
  }
  return { protocol, host, hostname, port, pathname, hash, search, origin };
};
