import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik } from "formik";
import { NumericFormat } from "react-number-format";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Grid as MuiGrid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  Autocomplete as MuiAutocomplete,
  InputAdornment as MuiInputAdornment,
} from "@mui/material";
import {
  AttachMoney as MuiAttachMoneyIcon,
  NavigateNext as MuiNavigateNextIcon,
} from "@mui/icons-material";
import { useAlerts, useUsers } from "common";
import {
  getGLPeriodsByFiscalYear,
  postFinancialTransaction,
} from "app/services/componentsService";
import { TransactionTypes } from "app/shared/constants";
import { FormButton } from "app/shared/ui/FormButton";

export const FinancialTransactionForm = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  // current user object
  const { currentUser } = useUsers();
  // de-structuring currentUser object from permissions
  const { permissions } = currentUser;
  const { clearAlert, setAlert } = useAlerts();
  // handle close for form
  const handleClose = () => {
    navigate(
      `/commitments/${params.commitmentId}/components/${params.componentId}/financial-history`
    );
  };
  // state for fiscalYear
  const [fiscalYear] = useState(params.fiscalYear || "");
  // state for formData for Formik library
  const [formData] = useState({
    type: "",
    glPeriod: "",
    amount: "",
    note: "",
  });
  // state for GL Period for MuiAutocomplete input
  const [glPeriodOptions, setGLPeriodOptions] = useState([]);
  // de-structuring pageType prop
  const pageType = props.pageType;
  // Fn formatCurrency to render amount
  const formatCurrency = (value) => {
    if (value) {
      return parseFloat(value).toFixed(2);
    } else {
      if (pageType === "new") {
        return "";
      } else {
        return "0.00";
      }
    }
  };

  // React useEffect
  useEffect(() => {
    clearAlert();
    getGLPeriodsByFiscalYear(
      params.fiscalYear,
      setGLPeriodOptions,
      clearAlert,
      setAlert
    );
    // eslint-disable-next-line
  }, [params.fiscalYear]);

  // Common Translation String
  const commonTranslationString =
    "Commitments.financialHistory.financialTransaction.create";

  // Yup validation object for Formik library
  const financialTransactionValidation = Yup.object().shape({
    type: Yup.string().required(),
    glPeriod: Yup.object().required(),
    amount: Yup.number().required(),
    note: Yup.string().required(),
  });

  return (
    props.pageType === "new" &&
    permissions.CREATE_COMPONENT_FINANCIAL_PLAN && (
      <Formik
        initialValues={formData}
        enableReinitialize
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          postFinancialTransaction(
            params.commitmentId,
            params.componentId,
            params.fiscalYear,
            values,
            clearAlert,
            setAlert,
            t,
            navigate
          );
        }}
        validationSchema={financialTransactionValidation}
      >
        {(props) => {
          const {
            values,
            handleSubmit,
            dirty,
            isValid,
            setFieldValue,
            handleChange,
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              <MuiGrid container sx={{ margin: "0px 16px" }}>
                <MuiGrid item xs={12}>
                  <MuiBreadcrumbs
                    separator={<MuiNavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    <RouterLink
                      style={{ textDecoration: "none" }}
                      to={`/commitments`}
                    >
                      <MuiTypography color="textSecondary">
                        {t("Commitments.mainView.title")}
                      </MuiTypography>
                    </RouterLink>
                    <RouterLink
                      style={{ textDecoration: "none" }}
                      to={`/commitments/${params.commitmentId}/components/${params.componentId}/financial-history`}
                    >
                      <MuiTypography color="textSecondary">
                        {t("Commitments.financialHistory.mainView.title")}
                      </MuiTypography>
                    </RouterLink>
                    <MuiTypography color="textPrimary">
                      {pageType === "new" &&
                        t(`${commonTranslationString}.breadcrumbTitle`)}
                    </MuiTypography>
                  </MuiBreadcrumbs>
                </MuiGrid>
                <MuiGrid item xs={12}>
                  <MuiTypography variant="h1">
                    {pageType === "new" &&
                      `${t(`${commonTranslationString}.title`, {
                        fiscalYear,
                      })}`}
                  </MuiTypography>
                </MuiGrid>
                <MuiGrid item container spacing={2} sx={{ marginTop: "16px" }}>
                  <MuiGrid item xs={4}>
                    <MuiAutocomplete
                      id="type"
                      options={Object.values(TransactionTypes)}
                      getOptionLabel={(option) => t(option)}
                      onChange={(event, value) => {
                        setFieldValue(
                          "type",
                          Object.keys(TransactionTypes).find(
                            (key) => TransactionTypes[key] === value
                          )
                        );
                        setFieldValue("glPeriod", "");
                      }}
                      onInputChange={(event, value) => {}}
                      clearIcon={false}
                      renderInput={(params) => {
                        return (
                          <MuiTextField
                            {...params}
                            variant={"outlined"}
                            required
                            label={`${t(
                              `${commonTranslationString}.form.fields.type.label`
                            )}`}
                          />
                        );
                      }}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={4}>
                    <NumericFormat
                      id="amount"
                      label={`${t(
                        `${commonTranslationString}.form.fields.amount.label`
                      )}`}
                      customInput={MuiTextField}
                      thousandSeparator
                      decimalScale={2}
                      value={formatCurrency(values.amount)}
                      isAllowed={(values) => {
                        const { value } = values;
                        return (
                          value === "-" ||
                          (value >= -999999999.99 && value <= 999999999.99)
                        );
                      }}
                      required
                      allowNegative
                      onValueChange={(value) => {
                        setFieldValue("amount", value.value);
                      }}
                      fullWidth
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        startAdornment: (
                          <MuiInputAdornment position="start">
                            <MuiAttachMoneyIcon
                              fontSize="small"
                              color="disabled"
                            />
                          </MuiInputAdornment>
                        ),
                      }}
                    />
                  </MuiGrid>
                </MuiGrid>
                <MuiGrid item container spacing={2} sx={{ marginTop: "16px" }}>
                  <MuiGrid item xs={4}>
                    <MuiAutocomplete
                      id="glPeriod"
                      options={glPeriodOptions}
                      getOptionLabel={(option) => option.glPeriod || ""}
                      onChange={(event, value) => {
                        setFieldValue("glPeriod", value);
                      }}
                      value={values.glPeriod}
                      onInputChange={(event, value) => {}}
                      clearIcon={false}
                      renderInput={(params) => {
                        return (
                          <MuiTextField
                            {...params}
                            variant={"outlined"}
                            required
                            label={`${t(
                              `${commonTranslationString}.form.fields.glPeriod.label`
                            )}`}
                          />
                        );
                      }}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={4}>
                    <MuiTextField
                      id="note"
                      label={`${t(
                        `${commonTranslationString}.form.fields.note.label`
                      )}`}
                      required
                      fullWidth
                      variant="outlined"
                      autoComplete="off"
                      multiline
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                  </MuiGrid>
                </MuiGrid>
                <MuiGrid item container spacing={2} sx={{ marginTop: "16px" }}>
                  <MuiGrid item xs={8} container justifyContent="flex-end">
                    <MuiGrid
                      item
                      container
                      justifyContent="flex-end"
                      xs={7}
                      spacing={2}
                    >
                      <FormButton
                        cancel={handleClose}
                        save={{ disabled: !dirty || !isValid }}
                      />
                    </MuiGrid>
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
            </form>
          );
        }}
      </Formik>
    )
  );
};
