import { useTranslation } from "react-i18next";
import {
  Button as MuiButton,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableFooter as MuiTableFooter,
} from "@mui/material";
import { AddCircle as MuiAddCircleIcon } from "@mui/icons-material";
import { CurrencyFormatMarkup } from "app/components/Budgeting/BudgetTable";

export const CommitmentComponentRequestFooter = (props) => {
  const { t } = useTranslation();

  const records = props.components || [];
  let totals = {
    amount: records.reduce(
      (accum, currentObj) =>
        accum + parseFloat(currentObj?.componentAmount || 0),
      0
    ),
    fyBudget: records.reduce(
      (accum, currentObj) =>
        accum + parseFloat(currentObj?.budgetPlan?.fyBudget || 0),
      0
    ),
    fyPlanOne: records.reduce(
      (accum, currentObj) =>
        accum + parseFloat(currentObj?.budgetPlan?.fyPlanOne || 0),
      0
    ),
    fyPlanTwo: records.reduce(
      (accum, currentObj) =>
        accum + parseFloat(currentObj?.budgetPlan?.fyPlanTwo || 0),
      0
    ),
    fyProjectionOne: records.reduce(
      (accum, currentObj) =>
        accum + parseFloat(currentObj?.budgetPlan?.fyProjectionOne || 0),
      0
    ),
  };

  return (
    <MuiTableFooter>
      <MuiTableRow>
        <MuiTableCell align="right" /*variant="head"*/ scope="row">
          {t(
            "Commitments.commitmentComponents.mainView.list.budgetTableFooters.commitTotal"
          ) + ":"}
        </MuiTableCell>
        <MuiTableCell align="right" sx={{ paddingRight: "20px" }}>
          {CurrencyFormatMarkup(totals.amount)}
        </MuiTableCell>
        <MuiTableCell align="right" sx={{ paddingRight: "20px" }}>
          {CurrencyFormatMarkup(totals.fyProjectionOne)}
        </MuiTableCell>
        <MuiTableCell align="right" sx={{ paddingRight: "20px" }}>
          {CurrencyFormatMarkup(totals.fyBudget)}
        </MuiTableCell>
        <MuiTableCell align="right" sx={{ paddingRight: "20px" }}>
          {CurrencyFormatMarkup(totals.fyPlanOne)}
        </MuiTableCell>
        <MuiTableCell align="right" sx={{ paddingRight: "20px" }}>
          {CurrencyFormatMarkup(totals.fyPlanTwo)}
        </MuiTableCell>

        <MuiTableCell align="right" colSpan={!props.isViewOnly ? "4" : "3"}>
          {!props.isViewOnly && props.budgetEditableByCurrentUser && (
            <MuiButton
              color="inherit"
              size="small"
              variant="outlined"
              startIcon={<MuiAddCircleIcon />}
              onClick={() => {
                props?.arrayHelpers.current.push(props?.getEmptyComponent());
              }}
            >
              {t("Commitments.mainView.list.addComponentTooltip")}
            </MuiButton>
          )}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableFooter>
  );
};
