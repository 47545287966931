import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const FinancialHistoryGLPeriodTableHead = () => {
  const { t } = useTranslation();
  const tableColumnsStr =
    "Commitments.financialHistory.mainView.list.glPeriodTable.tableColumns.";
  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell align="left" key="glPeriod" width="20%">
          {t(`${tableColumnsStr}glPeriod`)}
        </MuiTableCell>
        <MuiTableCell align="right" key="mtdExpenses" width="24%">
          {t(`${tableColumnsStr}mtdExpenses`)}
        </MuiTableCell>
        <MuiTableCell align="right" key="mtdTransfers" width="30%">
          {t(`${tableColumnsStr}mtdTransfers`)}
        </MuiTableCell>
        <MuiTableCell align="right" key="mtdActuals">
          {t(`${tableColumnsStr}mtdActuals`)}
        </MuiTableCell>
        <MuiTableCell key="manualEntryDisplay"></MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};
