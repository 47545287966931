import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Select as MuiSelect,
  TextField as MuiTextField,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import {
  getInitialExpensesData,
  getGLPeriod,
} from "app/services/expensesService";
import { useExpensesContext } from "app/services/expensesContext";
import {
  ExpensesPosted,
  ExpensesConditionType,
  ExpensesNotification,
} from "app/shared/constants";
import { ResetFilter } from "app/shared/ui/FilterPanel/ResetFilter";

export const ExpensesFilter = () => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const {
    expensesFilters,
    setExpensesFilters,
    pagination,
    setPagination,
    lastUploadedInfo,
  } = useExpensesContext();

  const [fiscalYearOptions, setFiscalYearOptions] = useState([]);
  const [glPeriodOptions, setGLPeriodOptions] = useState([]);

  // Controllable States - Fiscal year
  const [fiscalYear, setFiscalYear] = useState(
    lastUploadedInfo.lastUploadFiscalYear || ""
  );

  // Controllable States - GL Period
  const [glPeriod, setGLPeriod] = useState(
    lastUploadedInfo.lastUploadGLPeriod || ""
  );

  const [glPeriodError, setGLPeriodError] = useState({
    exist: false,
    message: null,
  });

  // Controllable State - Posted

  const [posted, setPosted] = useState(expensesFilters.posted);

  // Controllable State - Condition Type

  const [conditionType, setConditionType] = useState(
    expensesFilters.conditionType
  );

  // Controllable State - Notification Type

  const [notification, setNotification] = useState(
    expensesFilters.notification
  );

  const expenseFilterPanelStr = "UploadedExpenses.filterPanelItems.options";

  useEffect(() => {
    getInitialExpensesData(
      setFiscalYearOptions,
      setGLPeriodOptions,
      setGLPeriodError,
      {
        size: 25,
        fiscalYear: lastUploadedInfo.lastUploadFiscalYear || "",
      }
    );
  }, [lastUploadedInfo.lastUploadFiscalYear]);

  useEffect(() => {
    const params = {
      fiscalYear,
      glPeriod,
      posted,
      conditionType,
      notification,
    };
    const queryParams = Object.keys(params)
      .filter((key) => params[key])
      .map((key) => {
        return `${key}=${params[key]}`;
      })
      .join("&");
    navigate(`${location.pathname}?${queryParams}`);
  }, [
    fiscalYear,
    glPeriod,
    posted,
    conditionType,
    notification,
    navigate,
    location.pathname,
  ]);

  useEffect(() => {
    setFiscalYear(lastUploadedInfo.lastUploadFiscalYear || "");
    setGLPeriod(lastUploadedInfo.lastUploadGLPeriod || "");
  }, [lastUploadedInfo]);

  // Function to reset the Filter value and re-load Deals data accordingly
  const resetFn = () => {
    setFiscalYear(lastUploadedInfo.lastUploadFiscalYear || "");
    setGLPeriod(lastUploadedInfo.lastUploadGLPeriod || "");
    setPosted("");
    setConditionType("");
    setNotification("");
    setExpensesFilters({
      fiscalYear: lastUploadedInfo.lastUploadFiscalYear || "",
      glPeriod: lastUploadedInfo.lastUploadGLPeriod || "",
      posted: "",
      conditionType: "",
      notification: "",
    });
    setPagination({
      page: 0,
      rowsPerPage: 10,
    });
  };

  const getPostedJSX = () => (
    <>
      <option value="" />
      {Object.keys(ExpensesPosted).map((key, index) => (
        <option value={key === "POSTED" ? true : false} key={key}>
          {t(ExpensesPosted[key])}
        </option>
      ))}
    </>
  );

  const getConditionTypeJSX = () => (
    <>
      <option value="" />
      {Object.keys(ExpensesConditionType).map((key, index) => (
        <option value={key.toLowerCase()} key={key}>
          {t(ExpensesConditionType[key])}
        </option>
      ))}
    </>
  );

  const getNotificationTypeJSX = () => (
    <>
      <option value="" />
      {Object.keys(ExpensesNotification).map((key, index) => (
        <option value={key.toLowerCase()} key={key}>
          {t(ExpensesNotification[key])}
        </option>
      ))}
    </>
  );
  return (
    <>
      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          id="fiscalYear"
          options={fiscalYearOptions || []}
          getOptionLabel={(option) => option && String(option.displayText)}
          value={{ displayText: fiscalYear }}
          onChange={(event, newValue) => {
            const fiscalYear = newValue ? newValue.displayText : "";
            setFiscalYear(fiscalYear);
            getGLPeriod(
              setGLPeriodOptions,
              setGLPeriod,
              expensesFilters,
              setExpensesFilters,
              pagination,
              setPagination,
              setGLPeriodError,
              {
                fiscalYear: (newValue && newValue.displayText) || "",
                size: 25,
                value: "",
              }
            );
            setExpensesFilters({
              ...expensesFilters,
              fiscalYear,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          clearOnEscape
          clearIcon={false}
          blurOnSelect
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t(`${expenseFilterPanelStr}.fiscalYear.label`)}
              variant="outlined"
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          id="glPeriod"
          options={glPeriodOptions}
          getOptionLabel={(option) => option.displayText}
          clearOnEscape
          clearIcon={false}
          value={{ displayText: glPeriod }}
          onChange={(event, newValue) => {
            const glPeriod = newValue ? newValue.displayText : "";
            setGLPeriod(glPeriod);
            setExpensesFilters({
              ...expensesFilters,
              glPeriod,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          blurOnSelect
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t(`${expenseFilterPanelStr}.glPeriod.label`)}
              variant="outlined"
              error={glPeriodError.exist}
              helperText={glPeriodError.message}
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined" size="small">
        <MuiInputLabel id="postedLabel">
          {t(`${expenseFilterPanelStr}.posted.label`)}
        </MuiInputLabel>
        <MuiSelect
          native
          labelId="postedSelectLabel"
          label={t(`${expenseFilterPanelStr}.posted.label`)}
          id="posted"
          onChange={(event) => {
            const posted = event.target.value;
            setPosted(posted);
            setExpensesFilters({
              ...expensesFilters,
              posted,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          value={posted}
        >
          {getPostedJSX()}
        </MuiSelect>
      </MuiFormControl>
      <MuiFormControl variant="outlined" size="small">
        <MuiInputLabel id="conditionTypeLabel">
          {t(`${expenseFilterPanelStr}.conditionType.label`)}
        </MuiInputLabel>
        <MuiSelect
          native
          labelId="conditionTypeSelectLabel"
          label={t(`${expenseFilterPanelStr}.conditionType.label`)}
          id="conditionType"
          onChange={(event) => {
            const conditionType = event.target.value;
            setConditionType(conditionType);
            setExpensesFilters({
              ...expensesFilters,
              conditionType,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          value={conditionType}
        >
          {getConditionTypeJSX()}
        </MuiSelect>
      </MuiFormControl>
      <MuiFormControl variant="outlined" size="small">
        <MuiInputLabel id="notificationLabel">
          {t(`${expenseFilterPanelStr}.notification.label`)}
        </MuiInputLabel>
        <MuiSelect
          native
          labelId="notificationSelectLabel"
          label={t(`${expenseFilterPanelStr}.notification.label`)}
          id="notification"
          onChange={(event) => {
            const notification = event.target.value;
            setNotification(notification);
            setExpensesFilters({
              ...expensesFilters,
              notification,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          value={notification}
        >
          {getNotificationTypeJSX()}
        </MuiSelect>
      </MuiFormControl>
      <ResetFilter resetFn={resetFn} />
    </>
  );
};
