import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Select as MuiSelect,
  TextField as MuiTextField,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import {
  getDepartmentByMatch,
  getCommitmentNameByMatch,
  getCommitmentProjectByMatch,
  getCommitmentAwardByMatch,
  getCommitmentTaskByMatch,
} from "app/services/commitmentsService";
import { useCommitmentContext } from "app/services/commitmentContext";
import { ResetFilter } from "app/shared/ui/FilterPanel/ResetFilter";

export const CommitmentsFilter = () => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  // Filter Context
  const { filters, setFilters, pagination, setPagination } =
    useCommitmentContext();
  // State variable and its function for Commitment Name
  const [dealName, setDealName] = useState(filters.dealName);
  // State variable and its function for Department Name
  const [departmentName, setDepartmentName] = useState(filters.departmentName);
  // State variable and its function for Department Code
  const [departmentValue, setDepartmentValue] = useState(
    filters.departmentValue
  );
  // State variable and its function for Status
  const [status, setStatus] = useState(filters.status);

  // State variable and its function for commitment project
  const [project, setProject] = useState(filters.project);

  // State variable and its function for commitment task
  const [task, setTask] = useState(filters.task);

  // State variable and its function for commitment award
  const [award, setAward] = useState(filters.award);

  // State variable and its function for Commitment Options
  const [commitmentNameOptions, setCommitmentNameOptions] = useState([]);
  // State variable and its function for Department Options
  const [departmentNameOptions, setDepartmentNameOptions] = useState([]);

  // State variable and its function for commitment project Options
  const [projectOptions, setProjectOptions] = useState([]);

  // State variable and its function for commitment task Options
  const [taskOptions, setTaskOptions] = useState([]);

  // State variable and its function for commitment award Options
  const [awardOptions, setAwardOptions] = useState([]);

  const [disableTaskNumber, setDisableTaskNumber] = useState(
    filters.project || filters.task ? false : true
  );

  const [departmentFilterError, setDepartmentFilterError] = useState({
    exist: false,
    message: null,
  });

  const [commitmentNameFilterError, setCommitmentNameFilterError] = useState({
    exist: false,
    message: null,
  });

  const [commitmentProjectFilterError, setCommitmentProjectFilterError] =
    useState({
      exist: false,
      message: null,
    });

  const [commitmentTaskFilterError, setCommitmentTaskFilterError] = useState({
    exist: false,
    message: null,
  });

  const [commitmentAwardFilterError, setCommitmentAwardFilterError] = useState({
    exist: false,
    message: null,
  });

  // Function to reset the Filter value and re-load Deals data accordingly
  const resetFn = () => {
    setDealName("");
    setDepartmentName("");
    setDepartmentValue("");
    setStatus("");
    setProject("");
    setTask("");
    setAward("");
    setFilters({
      dealName: "",
      departmentName: "",
      departmentValue: "",
      status: "",
      project: "",
      task: "",
      award: "",
    });
    setCommitmentNameOptions([]);
    setDepartmentNameOptions([]);
    setProjectOptions([]);
    setTaskOptions([]);
    setAwardOptions([]);
    setDisableTaskNumber(true);
  };

  // useEffect to act on the change of Filter Data variables.
  useEffect(() => {
    const params = { dealName, departmentName, status, project, task, award };
    const queryParams = Object.keys(params)
      .filter((key) => params[key])
      .map((key) => {
        return `${key}=${encodeURIComponent(params[key])}`;
      })
      .join("&");
    navigate(`${location.pathname}?${queryParams}`);
  }, [
    dealName,
    departmentName,
    departmentValue,
    status,
    project,
    task,
    award,
    navigate,
    location.pathname,
  ]);

  return (
    <>
      {/* Commitments Filter function to show MuiAutocomplete 
    inputs and filter data accordingly. */}

      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          id="commitmentNameFilter"
          freeSolo
          autoSelect
          options={commitmentNameOptions || []}
          getOptionLabel={(option) => option}
          value={dealName}
          clearOnEscape
          loading={loading}
          /**
           * On change event for MuiAutocomplete
           *
           * 1. Setting up the values for Filter via useState variable.
           * */
          onChange={(e, value) => {
            const dealName = value;
            setDealName(dealName);
            setFilters({
              ...filters,
              dealName,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          clearIcon={false}
          blurOnSelect
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t(
                "Commitments.filterPanelItems.options.commitmentName.label"
              )}
              variant="outlined"
              onChange={(e, value) => {
                getCommitmentNameByMatch(
                  e.target.value,
                  setCommitmentNameOptions,
                  setLoading,
                  setCommitmentNameFilterError
                );
              }}
              error={commitmentNameFilterError.exist}
              helperText={commitmentNameFilterError.message}
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          id="departmentNameFilter"
          options={departmentNameOptions || []}
          getOptionLabel={(option) => option}
          value={departmentName}
          clearOnEscape
          loading={loading}
          /**
           * On change event for MuiAutocomplete
           *
           * 1. Setting up the values for Filter via useState variable.
           * */
          onChange={(e, value) => {
            const departmentName = value;
            const departmentValue = value
              ? value.split("(").pop().split(")")[0].trim()
              : "";
            setDepartmentName(departmentName);
            setDepartmentValue(departmentValue);
            setFilters({
              ...filters,
              departmentName,
              departmentValue,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          clearIcon={false}
          blurOnSelect
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t(
                "Commitments.filterPanelItems.options.departmentName.label"
              )}
              variant="outlined"
              onChange={(e, value) => {
                getDepartmentByMatch(
                  e.target.value,
                  setDepartmentNameOptions,
                  setLoading,
                  setDepartmentFilterError
                );
              }}
              error={departmentFilterError.exist}
              helperText={departmentFilterError.message}
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined" size="small">
        <MuiInputLabel id="statusLabel">
          {t("Commitments.filterPanelItems.options.status.label")}
        </MuiInputLabel>
        <MuiSelect
          native
          labelId="statusSelectLabel"
          label={t("Commitments.filterPanelItems.options.status.label")}
          id="statusFilter"
          value={status || ""}
          onChange={(e, value) => {
            const status = e.target.value;
            setStatus(status);
            document.getElementById("statusFilter").blur();
            setFilters({
              ...filters,
              status,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
        >
          <option aria-label="" value="" />
          <option value={0}>
            {t(
              "Commitments.filterPanelItems.options.statusDropdownValues.statusActiveDropdownValue"
            )}
          </option>
          <option value={1}>
            {t(
              "Commitments.filterPanelItems.options.statusDropdownValues.statusInactiveDropdownValue"
            )}
          </option>
        </MuiSelect>
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          id="commitmentProjectFilters"
          options={projectOptions || []}
          getOptionLabel={(option) => option}
          value={project}
          clearOnEscape
          loading={loading}
          /**
           * On change event for MuiAutocomplete
           *
           * 1. Setting up the values for Filter via useState variable.
           * 2. Loading the Task list of corresponding Project Number.
           * 3. Enabling the Task Number list after calling up the
           * function for loading up the data for Task list.
           * */
          onChange={(e, value) => {
            const project = value;
            setProject(value);
            setDisableTaskNumber(false);
            setFilters({
              ...filters,
              project,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          clearIcon={false}
          blurOnSelect
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t("PTAs.filterPanelItems.options.project.label")}
              variant="outlined"
              error={commitmentProjectFilterError.exist}
              helperText={commitmentProjectFilterError.message}
              onChange={(e, value) => {
                getCommitmentProjectByMatch(
                  e.target.value,
                  setProjectOptions,
                  setLoading,
                  setCommitmentProjectFilterError
                );

                if (e.target.value === "") {
                  setTask("");
                  setDisableTaskNumber(true);
                }
              }}
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          id="commitmentTaskFilters"
          options={taskOptions || []}
          getOptionLabel={(option) => option}
          value={task}
          clearOnEscape
          loading={loading}
          // Disabling/Enabling the Task Number MuiAutocomplete
          disabled={disableTaskNumber}
          /**
           * On change event for MuiAutocomplete
           *
           * 1. Setting up the values for Filter via useState variable.
           * */
          onChange={(e, value) => {
            const task = value;
            setTask(value);
            setFilters({
              ...filters,
              task,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          clearIcon={false}
          blurOnSelect
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t("PTAs.filterPanelItems.options.task.label")}
              variant="outlined"
              error={commitmentTaskFilterError.exist}
              helperText={commitmentTaskFilterError.message}
              onChange={(e, value) => {
                getCommitmentTaskByMatch(
                  e.target.value,
                  setTaskOptions,
                  project,
                  setLoading,
                  setCommitmentTaskFilterError
                );
              }}
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          id="commitmentAwardFilters"
          options={awardOptions || []}
          getOptionLabel={(option) => option}
          value={award}
          clearOnEscape
          loading={loading}
          /**
           * On change event for MuiAutocomplete
           *
           * 1. Setting up the values for Filter via useState variable.
           * */
          onChange={(e, value) => {
            const award = value;
            setAward(value);
            setFilters({
              ...filters,
              award,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          clearIcon={false}
          blurOnSelect
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t("PTAs.filterPanelItems.options.award.label")}
              variant="outlined"
              error={commitmentAwardFilterError.exist}
              helperText={commitmentAwardFilterError.message}
              onChange={(e, value) => {
                getCommitmentAwardByMatch(
                  e.target.value,
                  setAwardOptions,
                  setLoading,
                  setCommitmentAwardFilterError
                );
              }}
            />
          )}
        />
      </MuiFormControl>
      <ResetFilter resetFn={resetFn} />
    </>
  );
};
