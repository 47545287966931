import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box as MuiBox,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Table as MuiTable,
  Button as MuiButton,
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
  IconButton as MuiIconButton,
  TableContainer as MuiTableContainer,
  TableSortLabel as MuiTableSortLabel,
  TablePagination as MuiTablePagination,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import {
  Edit as MuiEditIcon,
  Info as MuiInfoIcon,
  Error as MuiErrorIcon,
  Check as MuiCheckIcon,
  Warning as MuiWarningIcon,
  CloudUpload as MuiCloudUploadIcon,
  FormatListNumberedRtl as MuiFormatListNumberedRtlIcon,
} from "@mui/icons-material";
import { Theme, useAlerts } from "common";
import { getAllTransfers } from "app/services/transfersService";
import { useTransfersContext } from "app/services/transfersContext";
import { TransferTypes } from "app/shared/constants";
import { CurrencyFormat } from "app/shared/ui/CurrencyFormat";
import { whiteTableHeaderLabel } from "app/shared/ui/sharedStyles";
import { UploadTransfers } from "./UploadTransfers/UploadTransfers";
import { RedistributeTransfers } from "./RedistributeTransfers/RedistributeTransfers";

function EnhancedTableHead(props) {
  const { t } = useTranslation();
  const { onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { sort } = useTransfersContext();
  const tableColumnsStr = "UploadedTransfers.mainView.list.tableColumns";

  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell
          align="left"
          key="notification"
          width="98px"
          sortDirection={
            sort.column === "notification.status" ? sort.orderBy : false
          }
        >
          <MuiTableSortLabel
            active={sort.column === "notification.status"}
            direction={
              sort.column === "notification.status" ? sort.orderBy : "asc"
            }
            onClick={createSortHandler("notification.status")}
          >
            {t(`${tableColumnsStr}.notification`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="fiscalYear"
          width="65px"
          sortDirection={sort.column === "fiscalYear" ? sort.orderBy : "asc"}
        >
          <MuiTableSortLabel
            active={sort.column === "fiscalYear"}
            direction={sort.column === "fiscalYear" ? sort.orderBy : "asc"}
            onClick={createSortHandler("fiscalYear")}
          >
            {t(`${tableColumnsStr}.fiscalYear`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="glPeriod"
          width="67px"
          sortDirection={sort.column === "glPeriod" ? sort.orderBy : "asc"}
        >
          <MuiTableSortLabel
            active={sort.column === "glPeriod"}
            direction={sort.column === "glPeriod" ? sort.orderBy : "asc"}
            onClick={createSortHandler("glPeriod")}
          >
            {t(`${tableColumnsStr}.glPeriod`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="commitmentName"
          width="108px"
          sortDirection={
            sort.column === "commitment.name" ? sort.orderBy : "asc"
          }
        >
          <MuiTableSortLabel
            active={sort.column === "commitment.name"}
            direction={sort.column === "commitment.name" ? sort.orderBy : "asc"}
            onClick={createSortHandler("commitment.name")}
          >
            {t(`${tableColumnsStr}.commitmentName`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="componentName"
          width="102px"
          sortDirection={
            sort.column === "component.componentName" ? sort.orderBy : "asc"
          }
        >
          <MuiTableSortLabel
            active={sort.column === "component.componentName"}
            direction={
              sort.column === "component.componentName" ? sort.orderBy : "asc"
            }
            onClick={createSortHandler("component.componentName")}
          >
            {t(`${tableColumnsStr}.componentName`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="pta"
          width="52px"
          sortDirection={sort.column === "pta" ? sort.orderBy : "asc"}
        >
          <MuiTableSortLabel
            active={sort.column === "pta"}
            direction={sort.column === "pta" ? sort.orderBy : "asc"}
            onClick={createSortHandler("pta")}
          >
            {t(`${tableColumnsStr}.pta`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="journalSource"
          width="70px"
          sortDirection={sort.column === "journalSource" ? sort.orderBy : "asc"}
        >
          <MuiTableSortLabel
            active={sort.column === "journalSource"}
            direction={sort.column === "journalSource" ? sort.orderBy : "asc"}
            onClick={createSortHandler("journalSource")}
          >
            {t(`${tableColumnsStr}.journalSource`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="journalName"
          width="70px"
          sortDirection={sort.column === "journalName" ? sort.orderBy : "asc"}
        >
          <MuiTableSortLabel
            active={sort.column === "journalName"}
            direction={sort.column === "journalName" ? sort.orderBy : "asc"}
            onClick={createSortHandler("journalName")}
          >
            {t(`${tableColumnsStr}.journalName`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="transferType"
          width="75px"
          sortDirection={sort.column === "transferType" ? sort.orderBy : "asc"}
        >
          <MuiTableSortLabel
            active={sort.column === "transferType"}
            direction={sort.column === "transferType" ? sort.orderBy : "asc"}
            onClick={createSortHandler("transferType")}
          >
            {t(`${tableColumnsStr}.transferType`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="amount"
          width="85px"
          sortDirection={sort.column === "amount" ? sort.orderBy : "asc"}
        >
          <MuiTableSortLabel
            active={sort.column === "amount"}
            direction={sort.column === "amount" ? sort.orderBy : "asc"}
            onClick={createSortHandler("amount")}
          >
            {t(`${tableColumnsStr}.amount`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="center"
          key="posted"
          width="65px"
          sortDirection={sort.column === "isPosted" ? sort.orderBy : "asc"}
        >
          <MuiTableSortLabel
            active={sort.column === "isPosted"}
            direction={sort.column === "isPosted" ? sort.orderBy : "asc"}
            onClick={createSortHandler("isPosted")}
          >
            {t(`${tableColumnsStr}.posted`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="errorWarnings"
          width="113px"
          sortDirection={
            sort.column === "validateStatus" ? sort.orderBy : "asc"
          }
        >
          <MuiTableSortLabel
            active={sort.column === "validateStatus"}
            direction={sort.column === "validateStatus" ? sort.orderBy : "asc"}
            onClick={createSortHandler("validateStatus")}
          >
            {t(`${tableColumnsStr}.errorWarnings`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="center" key="actions" width="55px">
          {t(`${tableColumnsStr}.actions`)}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export const Transfers = (props) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("validateStatus");
  const [, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [transfers, setTransfers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const { clearAlert, setAlert } = useAlerts();

  // Open/Close redistribute dialog
  const [openRedistributeDialog, setOpenRedistributeDialog] = useState(false);

  const [showDetailsView, setShowDetailsView] = useState(false);

  // Upload Transfers dialog
  const [openUploadTransfersDialog, setOpenUploadTransfersDialog] =
    useState(false);

  const location = useLocation();

  const {
    filters,
    sort,
    setSort,
    pagination,
    setPagination,
    lastUploadedInfo,
    initialLoad,
  } = useTransfersContext();

  const [transferIdToRedistribute, setTransferIdToRedistribute] = useState();

  const [transferIndexToUpdate, setTransferIndexToUpdate] = useState();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);
    setSort({
      column: property,
      orderBy: isAsc,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    var pageValue = page;
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      totalCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      setPage(0);
      pageValue = 0;
    }
    setPagination({
      page: pageValue,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const displayLastGLPeriod = () => {
    return t("UploadedTransfers.mainView.subTitle", {
      glPeriod: lastUploadedInfo.lastUploadGLPeriod || "",
    });
  };

  useEffect(() => {
    !initialLoad &&
      getAllTransfers(
        filters,
        pagination,
        sort,
        setTransfers,
        setTotalCount,
        setLoading,
        clearAlert,
        setAlert
      );
    // eslint-disable-next-line
  }, [filters, pagination, sort]);

  const getStatusName = (status) => {
    const commontTranslationString =
      "UploadedTransfers.filterPanelItems.options.notificationDropdownValues";
    switch (status) {
      case "UNRESOLVED":
        return t(`${commontTranslationString}.unprocessed`);
      case "INPROCESS":
        return t(`${commontTranslationString}.inProcess`);
      case "RESOLVED":
        return t(`${commontTranslationString}.resolved`);
      default:
        return "";
    }
  };

  const commonTranslationString = "UploadedTransfers.mainView.list";

  return (
    <MuiGrid>
      <MuiTypography
        variant="subtitle1"
        sx={{ color: Theme.palette.grey[700] }}
      >
        {displayLastGLPeriod()}
      </MuiTypography>

      <MuiGrid
        container
        alignItems="flex-start"
        justifyContent="flex-end"
        direction="row"
        spacing={2}
      >
        <MuiGrid item xs={12}>
          <MuiBox display="flex" justifyContent="flex-end">
            <MuiButton
              variant="outlined"
              color="inherit"
              startIcon={<MuiCloudUploadIcon />}
              onClick={() => {
                setOpenUploadTransfersDialog(true);
              }}
            >
              {t("UploadedTransfers.mainView.uploadTransfersButton")}
            </MuiButton>
          </MuiBox>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <MuiTableContainer component={MuiPaper}>
            <MuiTable
              aria-label="customized table"
              stickyHeader
              sx={whiteTableHeaderLabel}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <MuiTableBody>
                {loading && (
                  <MuiTableRow>
                    <MuiTableCell colSpan="13" sx={{ textAlign: "center" }}>
                      <MuiCircularProgress />
                    </MuiTableCell>
                  </MuiTableRow>
                )}
                {!loading &&
                  transfers.map((row, index) => (
                    <MuiTableRow key={index}>
                      <MuiTableCell align="left" component="th" scope="row">
                        <MuiBox display="flex" flexDirection="row">
                          <MuiBox>
                            <MuiTypography variant="body2">
                              {row.transfersNotification &&
                                getStatusName(row.transfersNotification.status)}
                            </MuiTypography>
                          </MuiBox>

                          {row.transfersNotification &&
                            row.transfersNotification.status.toLowerCase() !==
                              "resolved" && (
                              <MuiBox>
                                <MuiTooltip
                                  title={t(
                                    `${commonTranslationString}.editNotificationToolTip`
                                  )}
                                >
                                  <MuiIconButton size="small" color="inherit">
                                    <MuiEditIcon />
                                  </MuiIconButton>
                                </MuiTooltip>
                              </MuiBox>
                            )}
                        </MuiBox>
                      </MuiTableCell>
                      <MuiTableCell align="left">{row.fiscalYear}</MuiTableCell>
                      <MuiTableCell align="left">{row.glPeriod}</MuiTableCell>
                      <MuiTableCell align="left">
                        {row.commitment && (
                          <RouterLink
                            style={{ textDecoration: "none" }}
                            to={{
                              pathname:
                                "/transfers/" +
                                row.id +
                                "/commitments/" +
                                row.commitment.id +
                                "/edit",
                            }}
                            target={"blank_" + row.commitment.id}
                          >
                            <MuiTooltip
                              title={row.commitment.name}
                              placement="left"
                            >
                              <MuiTypography
                                noWrap
                                variant="body2"
                                sx={{
                                  textOverflow: "ellipsis",
                                  width: "108px",
                                }}
                              >
                                {row.commitment.name}
                              </MuiTypography>
                            </MuiTooltip>
                          </RouterLink>
                        )}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {row.component && (
                          <RouterLink
                            style={{ textDecoration: "none" }}
                            to={{
                              pathname:
                                "/transfers/" +
                                row.id +
                                "/commitments/" +
                                row.commitment.id +
                                "/components/" +
                                row.component.id +
                                "/edit",
                            }}
                            target={`${row.commitment.id}${row.component.id}`}
                          >
                            <MuiTooltip
                              title={row.component.componentName}
                              placement="left"
                            >
                              <MuiTypography
                                noWrap
                                variant="body2"
                                sx={{
                                  textOverflow: "ellipsis",
                                  width: "102px",
                                }}
                              >
                                {row.component.componentName}
                              </MuiTypography>
                            </MuiTooltip>
                          </RouterLink>
                        )}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {row.pta && (
                          <RouterLink
                            style={{ textDecoration: "none" }}
                            to={{
                              pathname:
                                "/transfers/" +
                                row.id +
                                "/ptas/" +
                                row.pta._links.self.href.split("/").pop(),
                            }}
                            target={"blank_" + row.ptaNumber}
                          >
                            {row.ptaNumber}
                          </RouterLink>
                        )}
                        {!row.pta && (
                          <MuiTypography
                            variant="body2"
                            sx={{
                              textOverflow: "ellipsis",
                              width: "52px",
                            }}
                          >
                            {!row.task &&
                              `${row.project}-${"\u00A0\u00A0\u00A0"}-${
                                row.award
                              }`}
                            {row.task && row.ptaNumber}
                          </MuiTypography>
                        )}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {row.journalSource}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        <MuiTooltip title={row.journalName} placement="left">
                          <MuiTypography
                            noWrap
                            variant="body2"
                            sx={{
                              textOverflow: "ellipsis",
                              width: "75px",
                            }}
                          >
                            {row.journalName}
                          </MuiTypography>
                        </MuiTooltip>
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {t(TransferTypes[row.transferType])}
                      </MuiTableCell>
                      <MuiTableCell align="right">
                        {CurrencyFormat(row.amount)}
                      </MuiTableCell>
                      <MuiTableCell align="center">
                        {row.posted ? <MuiCheckIcon /> : ""}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {row?.transfersNotification?.errorWarnings?.map(
                          (anObjectMapped, index) => {
                            return (
                              <MuiBox
                                display="flex"
                                flexDirection="row"
                                key={index}
                              >
                                {row.validateStatus &&
                                  row.validateStatus.toLowerCase() ===
                                    "warning" && (
                                    <MuiBox>
                                      <MuiTooltip
                                        title={t(
                                          `${commonTranslationString}.warningToolTip`
                                        )}
                                      >
                                        <MuiWarningIcon
                                          sx={{
                                            color: Theme.palette.warning.main,
                                          }}
                                        />
                                      </MuiTooltip>
                                    </MuiBox>
                                  )}{" "}
                                {row.validateStatus &&
                                  row.validateStatus.toLowerCase() ===
                                    "error" && (
                                    <MuiBox>
                                      <MuiTooltip
                                        title={t(
                                          `${commonTranslationString}.errorToolTip`
                                        )}
                                      >
                                        <MuiErrorIcon
                                          sx={{
                                            color: Theme.palette.error.main,
                                          }}
                                        />
                                      </MuiTooltip>
                                    </MuiBox>
                                  )}
                                <MuiBox>
                                  <MuiTypography gutterBottom variant="body2">
                                    {anObjectMapped === "UNPROCESSED"
                                      ? t(
                                          `${commonTranslationString}.unprocessed`
                                        )
                                      : anObjectMapped}
                                  </MuiTypography>
                                </MuiBox>
                              </MuiBox>
                            );
                          }
                        )}
                        {row.validateStatus &&
                          row.validateStatus.toLowerCase() ===
                            "redistributed" && (
                            <MuiGrid container>
                              <MuiTooltip
                                title={t(
                                  `${commonTranslationString}.redistributedDetailsTooltip`
                                )}
                              >
                                <MuiInfoIcon
                                  sx={{ color: Theme.palette.primary.main }}
                                />
                              </MuiTooltip>
                              <MuiTypography
                                gutterBottom
                                variant="body2"
                                display={"inline"}
                              >
                                {t(
                                  `${commonTranslationString}.redistributedLabel`
                                )}
                              </MuiTypography>
                            </MuiGrid>
                          )}{" "}
                      </MuiTableCell>
                      <MuiTableCell align="center">
                        {row.validateStatus &&
                          row.validateStatus.toLowerCase() === "error" && (
                            <MuiTooltip
                              title={t(
                                `${commonTranslationString}.redistributeToolTip`
                              )}
                            >
                              <MuiIconButton
                                size="small"
                                onClick={() => {
                                  setShowDetailsView(false);
                                  setOpenRedistributeDialog(true);
                                  setTransferIdToRedistribute(row.id);
                                  setTransferIndexToUpdate(index);
                                }}
                              >
                                <MuiFormatListNumberedRtlIcon />
                              </MuiIconButton>
                            </MuiTooltip>
                          )}
                      </MuiTableCell>
                    </MuiTableRow>
                  ))}
                {!loading && !transfers.length && (
                  <MuiTableRow>
                    <MuiTableCell colSpan="13">
                      <MuiTypography
                        variant="body1"
                        color="inherit"
                        align="center"
                        paragraph={false}
                      >
                        {t("globals.list.messages.noData")}
                      </MuiTypography>
                    </MuiTableCell>
                  </MuiTableRow>
                )}
              </MuiTableBody>
            </MuiTable>
            <MuiTablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={pagination.rowsPerPage}
              page={pagination.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MuiTableContainer>
        </MuiGrid>
      </MuiGrid>

      {openRedistributeDialog && (
        <RedistributeTransfers
          openDialog={openRedistributeDialog}
          setOpenDialog={setOpenRedistributeDialog}
          transferIdToRedistribute={transferIdToRedistribute}
          setTransfers={setTransfers}
          transferIndex={transferIndexToUpdate}
          showDetailsView={showDetailsView}
        />
      )}

      {openUploadTransfersDialog && (
        <UploadTransfers
          openDialog={openUploadTransfersDialog}
          setOpenDialog={setOpenUploadTransfersDialog}
          glPeriod={lastUploadedInfo.lastUploadGLPeriod}
          location={location}
        />
      )}
    </MuiGrid>
  );
};
