import { AccessDenied, useUsers } from "common";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { Page } from "./shared/ui/Page";
import { CommitmentsFilter } from "./components/Commitments/Commitments.filters";
import { Commitments } from "./components/Commitments/Commitments";
import { CommitmentProvider } from "./services/commitmentContext";
import { CommitmentForm } from "./components/Commitments/CommitmentForm/CommitmentForm";
import { CommitmentRequestForm } from "./components/Commitments/CommitmentRequest/CommitmentRequestForm";
import { CommitmentDocuments } from "./components/Commitments/CommitmentDocuments/CommitmentDocuments";
import { CreateEditCommitmentDocumentForm } from "./components/Commitments/CommitmentDocuments/CreateEditCommitmentDocumentForm";
import { CommitmentComponentForm } from "./components/Commitments/CommitmentComponents/CommitmentComponentForm/CommitmentComponentForm";
import { CommitmentComponentRequestForm } from "./components/Commitments/CommitmentComponents/CommitmentComponentRequest/CommitmentComponentRequestForm";
import { FinancialHistoryForm } from "./components/Commitments/CommitmentComponents/FinancialHistory/FinancialHistoryForm";
import { FinancialHistory } from "./components/Commitments/CommitmentComponents/FinancialHistory/FinancialHistory";
import { FinancialTransactionForm } from "./components/Commitments/CommitmentComponents/FinancialHistory/FinancialTransactionForm";
import { ExpensesProvider } from "./services/expensesContext";
import { ExpensesFilter } from "./components/Expenses/Expenses.filters";
import { Expenses } from "./components/Expenses/Expenses";
import { PTADetails } from "./components/PTAs/PTADetails";
import { TransfersProvider } from "./services/transfersContext";
import { Transfers } from "./components/Transfers/Transfers";
import { TransfersFilter } from "./components/Transfers/Transfers.filters";
import { Requests } from "./components/Requests/Requests";
import { RequestsProvider } from "./services/requestsContext";
import { RequestsFilter } from "./components/Requests/Requests.filters";
import { BudgetingProvider } from "./services/budgetingContext";
import { Budgeting } from "./components/Budgeting/Budgeting";
import { PtaProvider } from "./services/ptasContext";
import { PTAs } from "./components/PTAs/PTAs";
import { PTAsFilter } from "./components/PTAs/PTAs.filters";

export const AppRoutes = () => {
  const { accessDenied } = useUsers();
  const { t } = useTranslation();

  if (accessDenied) {
    return <AccessDenied supportEmail={t("app.supportEmail")} />;
  }

  return (
    <Routes>
      {/* Routes for Commitments */}
      <Route
        path="/"
        element={
          <CommitmentProvider>
            <Page
              title="Commitments"
              filter={<CommitmentsFilter />}
              main={<Commitments />}
            />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments"
        element={
          <CommitmentProvider>
            <Page
              title="Commitments"
              filter={<CommitmentsFilter />}
              main={<Commitments />}
            />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/new"
        element={
          <CommitmentProvider>
            <Page main={<CommitmentForm pageType="new" />} />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/:CommitmentId/edit"
        element={
          <CommitmentProvider>
            <Page main={<CommitmentForm pageType="edit" />} />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/:CommitmentId/view"
        element={
          <CommitmentProvider>
            <Page main={<CommitmentForm pageType="view" />} />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/requests/new"
        element={
          <CommitmentProvider>
            <Page main={<CommitmentRequestForm pageType="new" />} />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/requests/:CommitmentId/edit"
        element={
          <CommitmentProvider>
            <Page main={<CommitmentRequestForm pageType="edit" />} />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/requests/:CommitmentId/delete"
        element={
          <CommitmentProvider>
            <Page main={<CommitmentRequestForm pageType="delete" />} />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/:commitmentId/documents"
        element={
          <CommitmentProvider>
            <Page main={<CommitmentDocuments />} />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/:commitmentId/documents/new"
        element={
          <CommitmentProvider>
            <Page main={<CreateEditCommitmentDocumentForm pageType="new" />} />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/:commitmentId/documents/:documentId/edit"
        element={
          <CommitmentProvider>
            <Page main={<CreateEditCommitmentDocumentForm pageType="edit" />} />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/:commitmentId/components/new"
        element={
          <CommitmentProvider>
            <Page main={<CommitmentComponentForm pageType="new" />} />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/:commitmentId/components/:componentId/edit"
        element={
          <CommitmentProvider>
            <Page main={<CommitmentComponentForm pageType="edit" />} />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/:commitmentId/components/:componentId/view"
        element={
          <CommitmentProvider>
            <Page main={<CommitmentComponentForm pageType="view" />} />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/:commitmentId/components/requests/:componentId/edit"
        element={
          <CommitmentProvider>
            <Page main={<CommitmentComponentRequestForm pageType="edit" />} />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/:commitmentId/components/requests/:componentId/delete"
        element={
          <CommitmentProvider>
            <Page main={<CommitmentComponentRequestForm pageType="delete" />} />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/:commitmentId/components/requests/new"
        element={
          <CommitmentProvider>
            <Page main={<CommitmentComponentRequestForm pageType="new" />} />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/:commitmentId/components/:componentId/financial-history/new"
        element={<Page main={<FinancialHistoryForm pageType="new" />} />}
      />
      <Route
        path="/commitments/:commitmentId/components/:componentId/financial-history/:fiscalYear/:componentPlanId/edit"
        element={<Page main={<FinancialHistoryForm pageType="edit" />} />}
      />
      <Route
        path="/commitments/:commitmentId/components/:componentId/financial-history"
        element={
          <CommitmentProvider>
            <Page main={<FinancialHistory pageType="newTab" />} />
          </CommitmentProvider>
        }
      />
      <Route
        path="/commitments/:commitmentId/components/:componentId/financial-history/:fiscalYear/financial-transaction/new"
        element={
          <CommitmentProvider>
            <Page main={<FinancialTransactionForm pageType="new" />} />
          </CommitmentProvider>
        }
      />
      {/** Routes for Expenses */}
      <Route
        path="/expenses"
        element={
          <ExpensesProvider>
            <Page
              title="Expenses"
              filter={<ExpensesFilter />}
              main={<Expenses />}
            />
          </ExpensesProvider>
        }
      />
      <Route
        path="/expenses/:expenseId/ptas/:PtaId"
        element={
          <ExpensesProvider>
            <Page main={<PTADetails pageType="newTab" />} />
          </ExpensesProvider>
        }
      />
      <Route
        path="/expenses/:expenseId/commitments/:CommitmentId/edit"
        element={
          <ExpensesProvider>
            <Page main={<CommitmentForm pageType="newTab" />} />
          </ExpensesProvider>
        }
      />
      <Route
        path="/expenses/:expenseId/commitments/:commitmentId/components/:componentId/edit"
        element={
          <ExpensesProvider>
            <Page main={<CommitmentComponentForm pageType="newTab" />} />
          </ExpensesProvider>
        }
      />
      {/** Routes for Transfers */}
      <Route
        path="/transfers"
        element={
          <TransfersProvider>
            <Page
              title="Transfers"
              filter={<TransfersFilter />}
              main={<Transfers />}
            />
          </TransfersProvider>
        }
      />
      <Route
        path="/transfers/:expenseId/ptas/:PtaId"
        element={
          <TransfersProvider>
            <Page main={<PTADetails pageType="newTab" />} />
          </TransfersProvider>
        }
      />
      <Route
        path="/transfers/:expenseId/commitments/:CommitmentId/edit"
        element={
          <TransfersProvider>
            <Page main={<CommitmentForm pageType="newTab" />} />
          </TransfersProvider>
        }
      />
      <Route
        path="/transfers/:expenseId/commitments/:commitmentId/components/:componentId/edit"
        element={
          <TransfersProvider>
            <Page main={<CommitmentComponentForm pageType="newTab" />} />
          </TransfersProvider>
        }
      />
      {/** Routes for Budgeting */}
      <Route
        path="/budgeting"
        element={
          <BudgetingProvider>
            <Page title="Budgeting" main={<Budgeting />} />
          </BudgetingProvider>
        }
      />
      <Route
        path="/budgeting/ptas/:PtaId"
        element={
          <BudgetingProvider>
            <Page main={<PTADetails pageType="newTab" />} />
          </BudgetingProvider>
        }
      />
      <Route
        path="/budgeting/commitments/:CommitmentId/view"
        element={
          <BudgetingProvider>
            <Page main={<CommitmentForm pageType="view" parent="other" />} />
          </BudgetingProvider>
        }
      />
      {/** Routes for Requests */}
      <Route
        path="/requests"
        element={
          <RequestsProvider>
            <Page
              title="Requests"
              filter={<RequestsFilter />}
              main={<Requests />}
            />
          </RequestsProvider>
        }
      />
      {/** Routes for PTAS */}
      <Route
        path="/ptas"
        element={
          <PtaProvider>
            <Page title="PTAs" filter={<PTAsFilter />} main={<PTAs />} />
          </PtaProvider>
        }
      />
      <Route
        path="/ptas/:PtaId"
        element={
          <PtaProvider>
            <Page main={<PTADetails />} />
          </PtaProvider>
        }
      />
    </Routes>
  );
};
