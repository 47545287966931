import { Theme } from "common";

// Styles for disabled text fields
export const disabledTextFieldStyles = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: Theme.palette.text.primary,
  },
  "& .MuiInput-underline.Mui-disabled:before": {
    borderBottomStyle: "solid",
  },
};

// Styles to keep the Table Header Label text white
export const whiteTableHeaderLabel = {
  "& .MuiTableSortLabel-root": {
    color: "white !important",
  },
  "& .MuiTableSortLabel-root:hover": {
    color: "white",
  },
  "&.Mui-active": {
    color: "white",
  },
  "& .MuiTableSortLabel-icon": {
    color: "white !important",
    alignSelf: "center",
  },
};
