import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  TextField as MuiTextField,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import { getAllPresentationSubcategories } from "app/services/commitmentsService";

export const CommitmentPresentationSubcategoryField = ({
  required,
  error,
  helperText,
  InputLabelProps,
  variant = "outlined",
  ...passedParams
}) => {
  const { t } = useTranslation();

  // Presentation Subcategories
  const [presentationSubcategories, setPresentationSubcategories] = useState(
    []
  );

  const [presentationSubcategoryError, setPresentationSubcategoryError] =
    useState({
      exist: false,
      message: null,
    });

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();

    getAllPresentationSubcategories(
      setPresentationSubcategories,
      setPresentationSubcategoryError,
      cancelSource
    );
    return () => {
      cancelSource.cancel();
    };
  }, []);

  return (
    <>
      {presentationSubcategories && (
        <MuiAutocomplete
          freeSolo
          options={presentationSubcategories}
          getOptionLabel={(option) => option.name ?? ""}
          autoHighlight
          handleHomeEndKeys
          openOnFocus
          fullWidth
          required={required}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t(
                `Commitments.create.form.fields.presentationSubCategory.label`
              )}
              required={required}
              variant={variant}
              InputLabelProps={InputLabelProps}
              error={presentationSubcategoryError.exist || error}
              helperText={presentationSubcategoryError.message || helperText}
            />
          )}
          {...passedParams}
        />
      )}
    </>
  );
};
