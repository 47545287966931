import { useTranslation } from "react-i18next";
import {
  TextField as MuiTextField,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import { CommitmentTypes } from "app/shared/constants";

export const CommitmentTypeField = ({
  required,
  includeRequestTypes,
  commitmentIsDeferred,
  error,
  helperText,
  ...passedParams
}) => {
  const { t } = useTranslation();

  const getCommitmentTypeOptions = () => {
    if (commitmentIsDeferred) {
      const deferredOption = Object.values(CommitmentTypes).filter(
        (x) => x === CommitmentTypes["DEFERRED"]
      );
      return deferredOption;
    }

    const allTypeValues = Object.values(CommitmentTypes);
    const nonRequestValues = Object.values(CommitmentTypes).filter(
      (x) =>
        x !== CommitmentTypes["REQUEST"] && x !== CommitmentTypes["DEFERRED"]
    );

    return !!includeRequestTypes ? allTypeValues : nonRequestValues;
  };

  return (
    <MuiAutocomplete
      id="commitmentType"
      name="commitmentType"
      options={getCommitmentTypeOptions()}
      getOptionLabel={(option) => t(option)}
      isOptionEqualToValue={(option, value) => t(option) === value}
      autoHighlight
      disableClearable
      handleHomeEndKeys
      openOnFocus
      fullWidth
      clearIcon={false}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          required={required}
          error={error}
          helperText={helperText}
          label={t(`Commitments.create.form.fields.commitmentType.label`)}
        />
      )}
      {...passedParams}
    />
  );
};
