import { useEffect, useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  Select as MuiSelect,
  Tooltip as MuiTooltip,
  MenuItem as MuiMenuItem,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
  IconButton as MuiIconButton,
  OutlinedInput as MuiOutlinedInput,
} from "@mui/material";
import {
  Check as MuiCheckIcon,
  Close as MuiCloseIcon,
} from "@mui/icons-material";
import { patchComponentRequest } from "app/services/requestsService";
import { NumberFormatField } from "app/shared/ui/NumberFormatField";
import { CurrencyFormat } from "app/shared/ui/CurrencyFormat";
import { ComponentDispositionTypes } from "app/shared/constants";

export const ComponentRequestTableRowForm = ({
  component,
  commitmentName,
  commitmentId,
  setLoading,
  pushSnackbarMessage,
  formData,
  setFormData,
  setRefresh,
  setInlineEditRow,
}) => {
  const { t } = useTranslation();
  const [openToolTip, setOpenToolTip] = useState(true);

  const onBeforeSend = () => {
    setLoading(true);
  };

  const onError = (error) => {
    if (!axios.isCancel(error)) {
      setLoading(false);
      pushSnackbarMessage("error", error.message);
    }
  };
  const onSuccess = (values) => {
    setLoading(false);
    pushSnackbarMessage(
      "success",
      t("Commitments.commitmentComponents.edit.notification.success", {
        componentName: `"${component.componentName.trim()}"`,
        commitmentName: `"${commitmentName.trim()}"`,
      })
    );
    setFormData(values);
    setRefresh((value) => value + 1);
    setInlineEditRow(false);
  };

  const saveComponent = (values) => {
    const cancelSource = axios.CancelToken.source();
    patchComponentRequest(
      values,
      commitmentId,
      component.componentId,
      onBeforeSend,
      onError,
      onSuccess,
      cancelSource
    );
  };

  const validationSchema = Yup.object().shape({
    dispositionStatus: Yup.mixed().required(
      t("globals.form.fieldIsRequired", {
        fieldName: t(`Requests.inlineEdit.form.fields.dispositionStatus.label`),
      })
    ),
    totalComponentAmountApproved: Yup.number().when("dispositionStatus", {
      is: (i) => i && i.toLowerCase() === "approved",
      then: (schema) =>
        schema
          .moreThan(
            0,
            t(
              `Requests.inlineEdit.form.fields.totalComponentAmountApproved.validation.greaterThanZero`
            )
          )
          .required(),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  // Cancel out of inline editing with the ESC key
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setInlineEditRow(false);
      }
    };
    window.addEventListener("keydown", handleEsc, false);
    return () => {
      window.removeEventListener("keydown", handleEsc, false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Formik
      initialValues={formData}
      enableReinitialize
      validateOnMount
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        saveComponent(values);
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        const {
          values,
          setFieldValue,
          dirty,
          isValid,
          errors,
          handleChange,
          handleSubmit,
        } = formikProps;
        return (
          <>
            {/* Year */}
            <MuiTableCell>{component?.budgetFiscalYear}</MuiTableCell>

            {/* Disposition Status */}
            <MuiTableCell>
              <MuiTooltip
                title={errors?.dispositionStatus ?? ""}
                placement="bottom"
                open={!!errors.dispositionStatus && openToolTip}
              >
                <span>
                  <MuiSelect
                    size="small"
                    autoFocus
                    labelId="statusSelectLabel"
                    inputProps={{
                      "aria-label": t(
                        "Commitments.filterPanelItems.options.dispositionStatus.label"
                      ),
                    }}
                    id="statusFilter"
                    value={values?.dispositionStatus || ""}
                    onChange={(e) => {
                      setFieldValue("dispositionStatus", e.target.value);
                      if (
                        e.target.value !==
                        t(ComponentDispositionTypes["APPROVED"]).toUpperCase()
                      ) {
                        // Using setTimeout as a workaround to Formik validating with the previous values
                        setTimeout(() => {
                          setFieldValue(
                            "totalComponentAmountApproved",
                            0,
                            false
                          );
                        }, "200");
                      } else {
                        setTimeout(() => {
                          setFieldValue(
                            "totalComponentAmountApproved",
                            values?.totalComponentAmountRequested,
                            false
                          );
                          document
                            .getElementById("totalComponentAmountApproved")
                            .focus();
                        }, "200");
                      }
                    }}
                    onBlur={() => setOpenToolTip(true)}
                    onFocus={() => setOpenToolTip(false)}
                    sx={{
                      width: "110px",
                      fontSize: "14px",
                    }}
                    error={!!errors.dispositionStatus}
                  >
                    {Object.entries(ComponentDispositionTypes).map(
                      ([key, value], index) => (
                        <MuiMenuItem key={index} value={key}>
                          {t(value)}
                        </MuiMenuItem>
                      )
                    )}
                  </MuiSelect>
                </span>
              </MuiTooltip>
            </MuiTableCell>

            {/* Component Name */}
            <MuiTableCell>
              {component.componentName && (
                <MuiTooltip
                  title={component?.componentName ?? ""}
                  placement="left"
                >
                  <MuiTypography variant="caption" component="div">
                    <span>{component?.componentName}</span>
                  </MuiTypography>
                </MuiTooltip>
              )}
            </MuiTableCell>

            {/* Amounts */}
            <MuiTableCell align="right">
              {CurrencyFormat(values?.totalComponentAmountRequested || 0)}
            </MuiTableCell>
            <MuiTableCell align="right">
              <MuiTooltip
                title={errors?.totalComponentAmountApproved ?? ""}
                placement="bottom"
                open={!!errors.totalComponentAmountApproved && openToolTip}
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, 20],
                      },
                    },
                  ],
                }}
              >
                <span>
                  <NumberFormatField
                    size="small"
                    id="totalComponentAmountApproved"
                    setFieldValue={setFieldValue}
                    formValues={values?.totalComponentAmountApproved}
                    restrictions
                    sx={{
                      "& .MuiOutlinedInput-input, & .MuiInput-input": {
                        textAlign: "right",
                        fontSize: "14px",
                      },
                    }}
                    disabled={values?.dispositionStatus !== "APPROVED"}
                    variant={
                      values?.dispositionStatus !== "APPROVED"
                        ? "standard"
                        : "outlined"
                    }
                    onBlur={() => {
                      setOpenToolTip(true);
                    }}
                    onFocus={() => {
                      setOpenToolTip(false);
                    }}
                    error={!!errors.totalComponentAmountApproved}
                  />
                </span>
              </MuiTooltip>
            </MuiTableCell>
            <MuiTableCell align="right">
              {CurrencyFormat(values?.fyProjectionOne)}
            </MuiTableCell>
            <MuiTableCell align="right">
              {CurrencyFormat(values?.fyBudget)}
            </MuiTableCell>

            {/* Note Columns */}
            <MuiTableCell
              align="left"
              sx={{
                borderBottomStyle: "none",
                padding: "2px 8px",
              }}
            >
              <MuiOutlinedInput
                size="small"
                id="dispositionNotes"
                multiline
                fullWidth
                sx={{ fontSize: "14px" }}
                value={values?.dispositionNotes}
                onChange={handleChange}
                inputProps={{
                  maxLength: 1000,
                }}
              />
            </MuiTableCell>
            <MuiTableCell
              align="left"
              sx={{
                borderBottomStyle: "none",
                padding: "2px 8px",
              }}
            >
              <MuiOutlinedInput
                size="small"
                id="dispositionComments"
                multiline
                fullWidth
                sx={{ fontSize: "14px" }}
                value={values?.dispositionComments}
                onChange={handleChange}
                inputProps={{
                  maxLength: 1000,
                }}
              />
            </MuiTableCell>

            {/* Actions */}
            <MuiTableCell align="left">
              {/* Submit */}
              <MuiIconButton
                size="small"
                disabled={!dirty || !isValid}
                onClick={() => handleSubmit()}
              >
                <MuiCheckIcon size="small" />
              </MuiIconButton>
              {/* Cancel */}
              <MuiIconButton
                size="small"
                onClick={() => {
                  setInlineEditRow(false);
                }}
              >
                <MuiCloseIcon size="small" />
              </MuiIconButton>
            </MuiTableCell>
          </>
        );
      }}
    </Formik>
  );
};
