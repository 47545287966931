import {
  TextField as MuiTextfield,
  Typography as MuiTypography,
  InputAdornment as MuiInputAdornment,
} from "@mui/material";
import { NumericFormat } from "react-number-format";

export const NumberFormatField = (props) => {
  let otherProps = { ...props };
  Object.hasOwn(otherProps, "setFieldValue") && delete otherProps.setFieldValue;
  Object.hasOwn(otherProps, "formValues") && delete otherProps.formValues;
  Object.hasOwn(otherProps, "restrictions") && delete otherProps.restrictions;
  if (otherProps.variant === undefined) {
    otherProps.variant = "outlined";
  }

  return (
    <NumericFormat
      thousandSeparator
      decimalScale={2}
      customInput={MuiTextfield}
      value={props.formValues}
      isAllowed={(values) => {
        const { value } = values;
        if (props.restrictions) {
          return value !== "-" && value >= 0 && value <= 999999999;
        } else {
          return value === "-" || (value >= -999999999 && value <= 999999999);
        }
      }}
      fixedDecimalScale
      autoComplete="off"
      allowNegative={!props.restrictions}
      InputProps={{
        startAdornment: (
          <MuiInputAdornment component="div" position="start">
            <MuiTypography variant="body2">$</MuiTypography>
          </MuiInputAdornment>
        ),
      }}
      onValueChange={(data) => {
        props.setFieldValue(props.id, data.value);
      }}
      {...props}
    />
  );
};
