import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider as MuiLocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns as MuiAdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { getIsValidDate } from "app/shared/utils";

export const ComponentStartDateField = ({
  value,
  expirationDate,
  onChange,
  required = false,
  size = "medium",
  ...passedParams
}) => {
  const { t } = useTranslation();

  return (
    <MuiLocalizationProvider dateAdapter={MuiAdapterDateFns}>
      <MuiDatePicker
        {...passedParams}
        required={required}
        value={getIsValidDate(value)}
        onChange={(val) => {
          onChange(val);
        }}
        inputVariant="outlined"
        maxDate={(expirationDate && new Date(expirationDate)) || undefined}
        maxDateMessage={t(
          `Commitments.commitmentComponents.create.form.fields.startDate.validation.maxDateMessage`
        )}
        size={size}
        inputFormat="MM/dd/yyyy"
        clearable
        fullWidth
        inputProps={{ autoComplete: "off" }}
        renderInput={(innerProps) => (
          <TextField {...innerProps} required={required} size={size} />
        )}
      />
    </MuiLocalizationProvider>
  );
};
