export const navbar = {
  items: [
    {
      name: "Commitments",
      menuItem: [],
      route: "/commitments",
    },
    {
      name: "Expenses",
      menuItem: [],
      route: "/expenses",
    },
    {
      name: "Transfers",
      menuItem: [],
      route: "/transfers",
    },
    {
      name: "Budgeting",
      menuItem: [],
      route: "/budgeting",
    },
    {
      name: "Requests",
      menuItem: [],
      route: "/requests",
    },
    {
      name: "PTAs",
      menuItem: [],
      route: "/ptas",
    },
  ],
};
