import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { format, addMonths } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Dialog as MuiDialog,
  Backdrop as MuiBackdrop,
  TextField as MuiTextField,
  Typography as MuiTypography,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import { Done as MuiDoneIcon } from "@mui/icons-material";
import { useAlerts } from "common";
import { useTransfersContext } from "app/services/transfersContext";
import { postUploadTransfers } from "app/services/transfersService";
import { DropZone } from "app/shared/ui/DropZone/DropZone";
import { ConfirmationDialog } from "./ConfirmationDialog";

export const UploadTransfers = ({
  openDialog,
  setOpenDialog,
  glPeriod,
  location,
}) => {
  const [open, setOpen] = useState(openDialog);
  const [loading, setLoading] = useState(false);
  const [uploadDocumentError, setUploadDocumentError] = useState(null);
  const [uploadedDocument, setUploadedDocument] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { t } = useTranslation();

  const { setAlert, clearAlert } = useAlerts();

  const uploadTranfersDialogStr = "UploadedTransfers.uploadTransfersDialog";

  const allowedMimeTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];
  const formData = {
    file: "",
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDialog(false);
  };

  const handleDocumentUpload = (files) => {
    files && files[0] && setUploadedDocument(files[0]);
  };

  const { lastUploadedInfo, setFilters, setLastUploadedInfo } =
    useTransfersContext();

  const uploadTransfers = () => {
    postUploadTransfers(
      uploadedDocument,
      setLoading,
      setUploadDocumentError,
      setLastUploadedInfo,
      handleClose,
      setAlert,
      clearAlert,
      setFilters
    );
  };

  const getFormattedFileName = (file) => {
    return (
      file.name.split(".")[0].substring(0, 40) +
      (file.name.length > 40 ? "... ." : ".") +
      file.name.split(".").pop()
    );
  };

  const getNextGLPeriod = (glPeriod) => {
    return glPeriod
      ? format(addMonths(new Date(glPeriod), 1), "LLL-yyyy").toUpperCase()
      : "";
  };

  useEffect(() => {
    setOpen(openDialog);
    clearAlert();
    // eslint-disable-next-line
  }, [openDialog]);

  const handleCloseConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  return (
    <Formik
      initialValues={formData}
      enableReinitialize
      validationSchema={uploadTransfersValidation}
    >
      {(props) => {
        const {
          // values,
          // handleChange,
          handleSubmit,
          setFieldValue,
          dirty,
          isValid,
        } = props;
        return (
          <>
            <form onSubmit={handleSubmit}>
              <MuiDialog open={open} fullWidth maxWidth="sm">
                <MuiDialogTitle>
                  {t(`${uploadTranfersDialogStr}.title`)}
                </MuiDialogTitle>
                <MuiDialogContent>
                  <MuiGrid container spacing={2} alignItems={"center"}>
                    <MuiGrid item xs={6}>
                      <MuiTextField
                        label={`${t(
                          `${uploadTranfersDialogStr}.fields.glPeriod.label`
                        )}`}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={getNextGLPeriod(glPeriod)}
                        readOnly
                        fullWidth
                        variant="standard"
                      />
                    </MuiGrid>
                    <MuiGrid item xs={6} sx={{ textAlign: "right" }}>
                      <MuiButton
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setShowConfirmationDialog(true);
                        }}
                        disabled={!lastUploadedInfo}
                      >
                        {t(
                          `${uploadTranfersDialogStr}.advanceGLPeriod.ctaButtonText`
                        )}
                      </MuiButton>
                    </MuiGrid>
                    <MuiGrid item xs={12}>
                      <MuiBackdrop open={loading} invisible sx={{ zIndex: 99 }}>
                        <MuiCircularProgress />
                      </MuiBackdrop>
                      <DropZone
                        acceptedFiles={allowedMimeTypes}
                        dropzoneText={t(
                          `${uploadTranfersDialogStr}.fields.transferUploader.label`
                        )}
                        handleDocumentUpload={handleDocumentUpload}
                        handleDrop={() => {
                          setUploadDocumentError(null);
                          setFieldValue("file", handleDocumentUpload);
                        }}
                      />
                    </MuiGrid>
                    <MuiGrid item xs={12}>
                      {uploadDocumentError && (
                        <MuiTypography color="error" variant="body2">
                          {`${getFormattedFileName(
                            uploadedDocument
                          )} ${uploadDocumentError}`}
                        </MuiTypography>
                      )}
                      {!uploadDocumentError &&
                        uploadedDocument &&
                        uploadedDocument.name && (
                          <MuiTypography display="block" variant="body2">
                            <MuiDoneIcon
                              size="small"
                              sx={{ verticalAlign: "text-bottom" }}
                            />{" "}
                            {getFormattedFileName(uploadedDocument)}
                          </MuiTypography>
                        )}
                    </MuiGrid>
                  </MuiGrid>
                </MuiDialogContent>
                <MuiDialogActions>
                  <MuiButton onClick={handleClose} color="primary">
                    {t(`${uploadTranfersDialogStr}.actionButtons.cancel.label`)}
                  </MuiButton>
                  <MuiButton
                    color="primary"
                    onClick={() => {
                      uploadTransfers();
                    }}
                    disabled={!dirty || !isValid}
                  >
                    {t(`${uploadTranfersDialogStr}.actionButtons.upload.label`)}
                  </MuiButton>
                </MuiDialogActions>
              </MuiDialog>
            </form>
            {
              <ConfirmationDialog
                open={showConfirmationDialog}
                setShowConfirmationDialog={setShowConfirmationDialog}
                setOpenUploadTransfersDialog={setOpenDialog}
                handleClose={handleCloseConfirmationDialog}
                advanceGLPeriod={getNextGLPeriod(glPeriod)}
                location={location}
              />
            }
          </>
        );
      }}
    </Formik>
  );
};

const uploadTransfersValidation = Yup.object().shape({
  file: Yup.mixed().required(),
});
