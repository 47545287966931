import React from "react";
import qs from "qs";
import { useLocation } from "react-router-dom";
import { getLastUploadedInfo } from "app/services/expensesService";
import { useAlerts } from "common";

export const ExpensesContext = React.createContext();

export const ExpensesProvider = ({ children }) => {
  // Getting location object from useLocation (react-router-dom)
  const location = useLocation();

  // State variable to store last uploaded info to display and set as default filters
  const [lastUploadedInfo, setLastUploadedInfo] = React.useState("");

  // State for filters
  const params = qs.parse(location.search);
  const [expensesFilters, setExpensesFilters] = React.useState({
    fiscalYear: params.fiscalYear || "",
    glPeriod: params.glPeriod || "",
    posted: params.posted || "",
    conditionType: params.conditionType || "",
    notification: params.notification || "",
  });

  // State for Sort
  const [sort, setSort] = React.useState({
    column: "validateStatus",
    orderBy: "asc",
  });

  // State for pagination
  const [pagination, setPagination] = React.useState({
    page: 0,
    rowsPerPage: 10,
  });

  const [initialLoad, setInitialLoad] = React.useState(true);
  const { clearAlert, setAlert } = useAlerts();

  React.useEffect(() => {
    if (location.pathname.split("/")[1] !== "expenses") {
      setExpensesFilters({
        fiscalYear: lastUploadedInfo.lastUploadFiscalYear,
        glPeriod: lastUploadedInfo.lastUploadGLPeriod,
        posted: "",
        conditionType: "",
        notification: "",
      });
      setSort({
        column: "validateStatus",
        orderBy: "asc",
      });
      setPagination({
        page: 0,
        rowsPerPage: 10,
      });
    }
  }, [location.pathname, lastUploadedInfo]);

  React.useEffect(() => {
    if (location.pathname.split("/")[1] === "expenses") {
      getLastUploadedInfo(
        setLastUploadedInfo,
        setExpensesFilters,
        setInitialLoad,
        clearAlert,
        setAlert
      );
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <ExpensesContext.Provider
      value={{
        expensesFilters,
        setExpensesFilters,
        sort,
        setSort,
        pagination,
        setPagination,
        lastUploadedInfo,
        setLastUploadedInfo,
        initialLoad,
      }}
    >
      {children}
    </ExpensesContext.Provider>
  );
};

export const useExpensesContext = () => {
  return React.useContext(ExpensesContext);
};
