import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, Link as RouterLink } from "react-router-dom";
import {
  Grid as MuiGrid,
  Paper as MuiPaper,
  Table as MuiTable,
  Button as MuiButton,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  TableContainer as MuiTableContainer,
} from "@mui/material";
import {
  ArrowBack as MuiArrowBackIcon,
  NavigateNext as MuiNavigateNextIcon,
} from "@mui/icons-material";
import { Theme, useAlerts, useUsers } from "common";
import { getPTAById } from "app/services/ptasService";

export const PTADetails = (props) => {
  const { t } = useTranslation();
  // useLocation hook from "react-router-dom"
  const location = useLocation();
  const params = useParams();
  const [ptaData, setPtaData] = useState({});
  const { clearAlert, setAlert } = useAlerts();
  const { currentUser } = useUsers();

  const ptasDetailView = "PTAs.detailsView";

  useEffect(() => {
    getPTAById(params.PtaId, setPtaData, setAlert, clearAlert);
    // eslint-disable-next-line
  }, []);

  return (
    currentUser.permissions.VIEW_PTA_DETAILS && (
      <MuiGrid container>
        {props.pageType !== "newTab" && (
          <MuiGrid container item xs={12}>
            <MuiGrid item container xs={6} justifyContent="flex-start">
              <MuiBreadcrumbs
                separator={<MuiNavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <RouterLink
                  to={`/ptas${location.search}`}
                  style={{ textDecoration: "none" }}
                >
                  <MuiTypography color="textSecondary" variant="body2">
                    {t("PTAs.mainView.title")}
                  </MuiTypography>
                </RouterLink>
                <MuiTypography color="textPrimary" variant="subtitle1">
                  {t(`${ptasDetailView}.title`)}
                </MuiTypography>
              </MuiBreadcrumbs>
            </MuiGrid>
            <MuiGrid item container xs={6} justifyContent="flex-end">
              <RouterLink
                style={{ textDecoration: "none" }}
                to={`/ptas${location.search}`}
              >
                <MuiButton startIcon={<MuiArrowBackIcon />}>
                  {t(`${ptasDetailView}.goBackButtonLabel`)}
                </MuiButton>
              </RouterLink>
            </MuiGrid>
          </MuiGrid>
        )}

        <MuiGrid item xs={12}>
          <MuiTypography variant="h1">
            {t(`PTAs.mainView.list.tableColumns.pta`)}{" "}
            {ptaData && ptaData.project && ptaData.task && ptaData.award
              ? `${ptaData.project.projectNumber}-${ptaData.task.taskNumber}-${ptaData.award.awardNumber}`
              : ""}
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <MuiTypography
            variant="subtitle1"
            display="inline"
            color="textSecondary"
          >
            {t(`Commitments.mainView.list.tableColumns.status`)}:&nbsp;
          </MuiTypography>
          <MuiTypography display="inline" variant="subtitle1">
            {ptaData && ptaData.ptaStatus}
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <PTAs location={location} tableData={ptaData} />
        </MuiGrid>
        <MuiGrid item xs={12}>
          {props.pageType !== "newTab" && (
            <MuiGrid container justifyContent="flex-end" paddingTop="16px">
              <RouterLink
                style={{ textDecoration: "none" }}
                to={`/ptas${location.search}`}
              >
                <MuiButton
                  variant="outlined"
                  size="large"
                  startIcon={<MuiArrowBackIcon />}
                >
                  {t(`${ptasDetailView}.goBackButtonLabel`)}
                </MuiButton>
              </RouterLink>
            </MuiGrid>
          )}
        </MuiGrid>
      </MuiGrid>
    )
  );
};

const EnhancedTableHead = (props) => {
  const { t } = useTranslation();
  const { currentUser } = useUsers();

  const ptaDetailsTableStr = "PTAs.detailsView.tableLabels";

  return (
    currentUser.permissions.VIEW_PTA_DETAILS && (
      <MuiTableHead>
        <MuiTableRow>
          <MuiTableCell align="center" key="blank" width="200px">
            &nbsp;
          </MuiTableCell>
          <MuiTableCell align="center" key="project">
            {t(`${ptaDetailsTableStr}.project`)}
          </MuiTableCell>
          <MuiTableCell align="center" key="task">
            {t(`${ptaDetailsTableStr}.task`)}
          </MuiTableCell>
          <MuiTableCell align="center" key="award">
            {t(`${ptaDetailsTableStr}.award`)}
          </MuiTableCell>
        </MuiTableRow>
      </MuiTableHead>
    )
  );
};

const PTAs = (props) => {
  const { t } = useTranslation();
  const tableData = props.tableData;
  const ptaDetailsTableStr = "PTAs.detailsView.tableLabels";

  return (
    <MuiTableContainer component={MuiPaper}>
      <MuiTable
        aria-label="customized table"
        stickyHeader
        sx={{
          "& .MuiTableCell-root": {
            borderRight: `1px solid ${Theme.palette.grey[400]}`,
          },
        }}
      >
        <EnhancedTableHead />
        <MuiTableBody>
          {tableData &&
          tableData.project &&
          tableData.task &&
          tableData.award ? (
            <>
              <MuiTableRow key={1}>
                <MuiTableCell
                  align="left"
                  sx={{
                    backgroundColor: Theme.palette.primary.light,
                  }}
                >
                  {t(`${ptaDetailsTableStr}.number`)}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.project.projectNumber}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.task.taskNumber}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.award.awardNumber}
                </MuiTableCell>
              </MuiTableRow>

              <MuiTableRow key={2}>
                <MuiTableCell
                  align="left"
                  sx={{
                    backgroundColor: Theme.palette.primary.light,
                  }}
                >
                  {t(`${ptaDetailsTableStr}.name`)}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.project.projectName}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.task.taskName}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.award.awardName}
                </MuiTableCell>
              </MuiTableRow>

              <MuiTableRow key={3}>
                <MuiTableCell
                  align="left"
                  sx={{
                    backgroundColor: Theme.palette.primary.light,
                  }}
                >
                  {t(`${ptaDetailsTableStr}.startDate`)}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.project.projectStartDate &&
                    new Date(
                      tableData.project.projectStartDate
                    ).toLocaleDateString()}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.task.taskStartDate &&
                    new Date(tableData.task.taskStartDate).toLocaleDateString()}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.award.awardStartDate &&
                    new Date(
                      tableData.award.awardStartDate
                    ).toLocaleDateString()}
                </MuiTableCell>
              </MuiTableRow>

              <MuiTableRow key={4}>
                <MuiTableCell
                  align="left"
                  sx={{
                    backgroundColor: Theme.palette.primary.light,
                  }}
                >
                  {t(`${ptaDetailsTableStr}.endDate`)}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.project.projectEndDate &&
                    new Date(
                      tableData.project.projectEndDate
                    ).toLocaleDateString()}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.task.taskEndDate &&
                    new Date(tableData.task.taskEndDate).toLocaleDateString()}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.award.awardEndDate &&
                    new Date(tableData.award.awardEndDate).toLocaleDateString()}
                </MuiTableCell>
              </MuiTableRow>

              <MuiTableRow key={6}>
                <MuiTableCell
                  align="left"
                  sx={{
                    backgroundColor: Theme.palette.primary.light,
                  }}
                >
                  {t(`${ptaDetailsTableStr}.org`)} (
                  {t(`${ptaDetailsTableStr}.orgCodeName`)})
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.project.projectOrgCodeName} (
                  {tableData.project.projectOrgCode})
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.task.taskOrgCodeName} ({tableData.task.taskOrgCode}
                  )
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.award.awardOrgCodeName} (
                  {tableData.award.awardOrgCode})
                </MuiTableCell>
              </MuiTableRow>

              <MuiTableRow key={7}>
                <MuiTableCell
                  align="left"
                  sx={{
                    backgroundColor: Theme.palette.primary.light,
                  }}
                >
                  {t(`${ptaDetailsTableStr}.ownerFullName`)}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.project.projectOwnerFullName}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.task.taskOwnerFullName}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.award.awardOwnerFullName}
                </MuiTableCell>
              </MuiTableRow>

              <MuiTableRow key={8}>
                <MuiTableCell
                  align="left"
                  sx={{
                    backgroundColor: Theme.palette.primary.light,
                  }}
                >
                  {t(`${ptaDetailsTableStr}.managerFullName`)}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.project.projectManagerFullName}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.task.taskManagerFullName}
                </MuiTableCell>
                <MuiTableCell align="center">
                  {tableData.award.awardManagerFullName}
                </MuiTableCell>
              </MuiTableRow>
            </>
          ) : (
            <MuiTableRow>
              <MuiTableCell colSpan="4">
                <MuiTypography variant="body1" align="center" paragraph={false}>
                  {t("globals.list.messages.noData")}
                </MuiTypography>
              </MuiTableCell>
            </MuiTableRow>
          )}
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
};
