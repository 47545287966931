import { useState } from "react";
import { Grid as MuiGrid, Typography as MuiTypography } from "@mui/material";
import { FilterPanel } from "app/shared/ui/FilterPanel/FilterPanel";

export const Page = ({ title = "", filter = null, main }) => {
  const [open, setOpen] = useState(true);

  function handleDrawerToggle() {
    setOpen(!open);
  }

  return (
    <MuiGrid
      container
      alignContent="flex-start"
      wrap="nowrap"
      gap="16px"
      paddingBottom="16px"
    >
      {filter && (
        <MuiGrid item>
          <FilterPanel open={open} handleDrawerToggle={handleDrawerToggle}>
            {filter}
          </FilterPanel>
        </MuiGrid>
      )}
      <MuiGrid item container direction="column" paddingTop="16px">
        <MuiGrid item>
          {title && <MuiTypography variant="h1">{title}</MuiTypography>}
        </MuiGrid>
        <MuiGrid item>{main}</MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
};
