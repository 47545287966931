import { useEffect, useState } from "react";
import {
  List as MuiList,
  Popover as MuiPopover,
  ListItem as MuiListItem,
  Typography as MuiTypography,
  ListItemText as MuiListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Theme } from "common";

export const ListPopover = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    props.trigger ? setAnchorEl(props.trigger.target) : setAnchorEl(null);
  }, [props.trigger]);

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  return (
    <MuiPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: 20,
        horizontal: -40,
      }}
      TransitionProps={{
        onExited: () => {
          props.setListEvent(undefined);
        },
      }}
    >
      <MuiList sx={{ padding: "0", width: 250 }}>
        {/* Details Header */}
        <MuiListItem
          sx={{
            backgroundColor: Theme.palette.grey[700],
            color: Theme.palette.common.white,
          }}
        >
          <MuiListItemText
            primary={
              <MuiTypography align="center">
                {`${capitalize(props.header)} ${props.entityValue}`}
              </MuiTypography>
            }
          />
        </MuiListItem>
        <ListItems items={props.data} entity={props.entity} />
      </MuiList>
    </MuiPopover>
  );
};

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const ListItems = (props) => {
  const { t } = useTranslation();
  const items = t("Commitments.commitmentComponents.create.ptaDetails", {
    returnObjects: true,
  });
  return Object.entries(items).map((item, index) => (
    <MuiListItem
      sx={{
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: Theme.palette.grey[500],
        paddingTop: 0,
        paddingBottom: 0,
      }}
      key={index}
    >
      <MuiListItemText
        sx={{ marginTop: 0, marginBottom: 0 }}
        primary={
          <MuiTypography color="textSecondary" fontSize="12px">
            {item[1]}
          </MuiTypography>
        }
        secondary={
          <MuiTypography color="textPrimary" fontSize="14px">
            {item[0] === "org"
              ? `${props.items[`${props.entity}OrgCodeName`]} (${
                  props.items[`${props.entity}OrgCode`]
                })`
              : props.items[props.entity + capitalize(item[0])]}
          </MuiTypography>
        }
      />
    </MuiListItem>
  ));
};
