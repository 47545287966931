import { useTranslation } from "react-i18next";
import {
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableHead as MuiTableHead,
  TableCell as MuiTableCell,
} from "@mui/material";
import { Info as MuiInfoIcon } from "@mui/icons-material";
import {
  getThreeYearsAgoActualsHeading,
  getYearBeforeLastActualsHeading,
  getLastYearActualsHeading,
  getLastYearBudgetHeading,
  getLastYearProjectionHeading,
  getCurrentYearBudgetHeading,
  getCurrentYearBudgetCapHeading,
  getNextYearPlanHeading,
  getYearAfterNextPlanHeading,
  getYearAfterNextAvailableHeading,
} from "./Budgeting";

export const BudgetTableHeader = ({ budgetFiscalYear }) => {
  const { t } = useTranslation();
  const commonTranslationStr = "Budgeting.mainView.list.tableColumns";
  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell align="left" key="commitmentName" sx={{ minWidth: 280 }}>
          {t(`${commonTranslationStr}.commitmentName`)}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="totalCommitment"
          sx={{ minWidth: 100 }}
        >
          {t(`${commonTranslationStr}.totalCommitment`)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyActualsThree" sx={{ minWidth: 100 }}>
          {getThreeYearsAgoActualsHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyActualsTwo" sx={{ minWidth: 100 }}>
          {getYearBeforeLastActualsHeading(t, budgetFiscalYear)}
        </MuiTableCell>

        <MuiTableCell
          align="right"
          key="totalExpenditures"
          sx={{ minWidth: 120 }}
        >
          {t(`${commonTranslationStr}.totalExpenditures`)}
        </MuiTableCell>

        <MuiTableCell
          align="right"
          key="unspentCommitments"
          sx={{ whiteSpace: "pre-line", minWidth: 140 }}
        >
          <MuiTooltip
            title={t("Budgeting.mainView.list.unspentCommitmentInfoTooltip")}
            placement="bottom"
          >
            <MuiInfoIcon size="small" style={{ verticalAlign: "bottom" }} />
          </MuiTooltip>
          {t(`${commonTranslationStr}.unspentCommitmentsAsOf`, {
            unspentCommitmentsAsOfDate: `09/01/${budgetFiscalYear - 2}`,
          })}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="fyActualsOne"
          sx={{ whiteSpace: "pre-line", minWidth: 120 }}
        >
          {getLastYearActualsHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyBudgetOne" sx={{ minWidth: 100 }}>
          {getLastYearBudgetHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyProjection" sx={{ minWidth: 140 }}>
          {getLastYearProjectionHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyBudgetTwo" sx={{ minWidth: 140 }}>
          {getCurrentYearBudgetHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyPlanOne" sx={{ minWidth: 140 }}>
          {getNextYearPlanHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="fyPlanTwo"
          width="140"
          sx={{ minWidth: 140 }}
        >
          {getYearAfterNextPlanHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="availableAfter" sx={{ minWidth: 140 }}>
          {getYearAfterNextAvailableHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyBudgetCap" sx={{ minWidth: 100 }}>
          {getCurrentYearBudgetCapHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="left" key="comments" sx={{ minWidth: 186 }}>
          {t(`${commonTranslationStr}.comments`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="actions" sx={{ minWidth: 160 }}>
          {t(`${commonTranslationStr}.actions`)}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};
