import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid as MuiGrid,
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  IconButton as MuiIconButton,
  Typography as MuiTypography,
} from "@mui/material";
import { PriceChange as MuiPriceChangeIcon } from "@mui/icons-material";
import { CurrencyFormat } from "app/shared/ui/CurrencyFormat";
import { ComponentDispositionTypes } from "app/shared/constants";
import { ComponentRequestTableRowForm } from "./ComponentRequestTableRowForm";
import { useUsers } from "common";

export const ComponentRequestTableRow = ({
  component,
  commitmentId,
  commitmentName,
  commitmentFinalized,
  budgetFiscalYear,
  inlineEditRow,
  setInlineEditRow,
  pushSnackbarMessage,
  setRefresh,
  setLoading,
}) => {
  const { currentUser } = useUsers();
  const { permissions, roles } = currentUser;
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    requestOperation: "DISPOSITION_REQUEST",
    budgetFiscalYear: budgetFiscalYear,
    componentId: component?.componentId,
    componentName: component?.componentName || "",
    componentType: component?.componentType || "REQUEST",
    dispositionComments: component?.dispositionComments || "",
    dispositionStatus: component.dispositionStatus,
    dispositionNotes: component?.dispositionNotes || "",
    fyBudget: component?.fyBudget || 0,
    fyProjectionOne: component?.fyProjectionOne || 0,
    totalComponentAmountRequested:
      component?.totalComponentAmountRequested || 0,
    totalComponentAmountApproved: component?.totalComponentAmountApproved || 0,
  });

  const rowId = `component${component.componentId}`;

  const isCommitmentAdmin = roles.includes("COMMITMENT_ADMIN");

  return (
    <MuiTableRow>
      {rowId === inlineEditRow ? (
        <ComponentRequestTableRowForm
          component={component}
          componentId={component.componentId}
          commitmentId={commitmentId}
          commitmentName={commitmentName}
          budgetFiscalYear={budgetFiscalYear}
          pushSnackbarMessage={pushSnackbarMessage}
          setLoading={setLoading}
          formData={formData}
          setFormData={setFormData}
          setRefresh={setRefresh}
          setInlineEditRow={setInlineEditRow}
        />
      ) : (
        <>
          {/* Year */}
          <MuiTableCell>{component.budgetFiscalYear}</MuiTableCell>

          {/* Disposition Status */}
          <MuiTableCell>
            <span>
              {component.dispositionStatus &&
                t(ComponentDispositionTypes[component.dispositionStatus])}
            </span>
          </MuiTableCell>

          {/* Component Name */}
          <MuiTableCell>
            {component.componentName && (
              <MuiTooltip title={component.componentName} placement="left">
                <MuiTypography noWrap variant="caption" component="div">
                  <span>{component.componentName}</span>
                </MuiTypography>
              </MuiTooltip>
            )}
          </MuiTableCell>

          {/* Amounts */}
          <MuiTableCell align="right">
            <span>
              {CurrencyFormat(component.totalComponentAmountRequested || 0)}
            </span>
          </MuiTableCell>
          <MuiTableCell align="right">
            <span>
              {CurrencyFormat(component.totalComponentAmountApproved || 0)}
            </span>
          </MuiTableCell>
          <MuiTableCell align="right">
            <span>{CurrencyFormat(component.fyProjectionOne || 0)}</span>
          </MuiTableCell>
          <MuiTableCell align="right">
            <span>{CurrencyFormat(component.fyBudget || 0)}</span>
          </MuiTableCell>

          {/* Note Columns */}
          {isCommitmentAdmin && (
            <MuiTableCell
              align="left"
              sx={{
                borderBottomStyle: "none",
                padding: "2px 8px",
              }}
            >
              {component.dispositionNotes && (
                <MuiTooltip title={component.dispositionNotes} placement="left">
                  <MuiTypography noWrap variant="caption" component="div">
                    <span>{component.dispositionNotes}</span>
                  </MuiTypography>
                </MuiTooltip>
              )}
            </MuiTableCell>
          )}
          <MuiTableCell
            align="left"
            sx={{
              borderBottomStyle: "none",
              padding: "2px 8px",
            }}
          >
            {component.dispositionComments && (
              <MuiTooltip
                title={component.dispositionComments}
                placement="left"
              >
                <MuiTypography noWrap variant="caption" component="div">
                  <span>{component.dispositionComments}</span>
                </MuiTypography>
              </MuiTooltip>
            )}
          </MuiTableCell>

          {/* Actions */}
          <MuiTableCell align="left">
            <MuiGrid container justifyContent="flex-start" wrap="nowrap">
              {component._links?.disposition &&
                permissions.DISPOSITION_REQUESTS && (
                  <MuiGrid item>
                    <MuiTooltip
                      title={getEditComponentTooltipText(
                        component,
                        commitmentFinalized,
                        t
                      )}
                      placement="top"
                      disableFocusListener={rowId === inlineEditRow}
                      disableHoverListener={rowId === inlineEditRow}
                      disableTouchListener={rowId === inlineEditRow}
                    >
                      <span>
                        <MuiIconButton
                          size="small"
                          onClick={() => {
                            if (rowId !== inlineEditRow) {
                              setInlineEditRow(rowId);
                            }
                          }}
                          disabled={
                            !!inlineEditRow ||
                            commitmentFinalized ||
                            component.componentType !== "REQUEST"
                          }
                        >
                          <MuiPriceChangeIcon size="small" />
                        </MuiIconButton>
                      </span>
                    </MuiTooltip>
                  </MuiGrid>
                )}
            </MuiGrid>
          </MuiTableCell>
        </>
      )}
    </MuiTableRow>
  );
};

const getEditComponentTooltipText = (component, commitmentFinalized, t) => {
  return component.componentType === "REQUEST" && !commitmentFinalized
    ? t("Requests.mainView.list.editComponentTooltip")
    : t("Requests.mainView.list.editComponentDisabledTooltip");
};
