import axios from "axios";
import qs from "qs";

export const getLastUploadedInfo = async (
  setLastUploadedInfo,
  setFilters,
  setInitialLoad,
  clearAlert,
  setAlert
) => {
  try {
    clearAlert();
    const response = await axios(`transfers/upload-info`);
    setInitialLoad(false);
    setLastUploadedInfo(response.data);
    setFilters({
      fiscalYear: response.data.lastUploadFiscalYear || "",
      glPeriod: response.data.lastUploadGLPeriod,
    });
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getLastUploadedData = async (
  setTransfersPreference,
  clearAlert,
  setAlert,
  { location }
) => {
  try {
    clearAlert();
    const response = await axios(`transfers/upload-info`);
    const params = qs.parse(location.search);
    response.data &&
      setTransfersPreference((previousState) => ({
        transfers: {
          ...previousState.transfers,
          filters: {
            ...previousState.transfers.filters,
            fiscalYear: params.fiscalYear || response.data.lastUploadFiscalYear,
            glPeriod: params.glPeriod || response.data.lastUploadGLPeriod,
          },
          lastUploaded: {
            fiscalYear: response.data.lastUploadFiscalYear,
            glPeriod: response.data.lastUploadGLPeriod,
          },
        },
      }));
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const postUploadTransfers = async (
  file,
  setLoading,
  setUploadDocumentError,
  setLastUploadedInfo,
  handleClose,
  setAlert,
  clearAlert,
  setFilters
) => {
  try {
    clearAlert();
    setUploadDocumentError(null);
    setLoading(true);
    // creating formData
    const formData = new FormData();
    // setting up file data
    formData.set("inputFile", file);
    // axios call to upload file
    await axios({
      method: "post",
      url: `transfers/data-import`,
      data: formData,
      timeout: 20000,
    });
    // settings up last uploaded info
    const response = await axios(`transfers/upload-info`);

    // settings arguments to message page for showing file upload success
    setAlert(
      "success",
      `${file.name} Uploaded for ${response.data.lastUploadGLPeriod || ""}`,
      true
    );
    // closing the modal up after success
    handleClose();

    setFilters({
      fiscalYear: response.data.lastUploadFiscalYear,
      glPeriod: response.data.lastUploadGLPeriod,
    });
    setLastUploadedInfo({
      lastUploadFiscalYear: response.data.lastUploadFiscalYear,
      lastUploadGLPeriod: response.data.lastUploadGLPeriod,
    });
  } catch (error) {
    // setting error to error.code, getting from API

    const [{ message }] =
      error.response.data.detail && error.response.data.detail.properties;
    if (error.status === 400 && error.code === "INVALID_VALUE") {
      setUploadDocumentError(message);
    } else {
      setUploadDocumentError(message);
    }
  } finally {
    // finally setting up loading to false
    setLoading(false);
  }
};

export const getFiscalYearsByMatch = async (
  value,
  setFiscalYearOptions,
  setFiscalYearError
) => {
  const params = {
    size: 25,
    value: value || undefined,
  };
  try {
    setFiscalYearError({
      exist: false,
      message: null,
    });

    const response = await axios({
      url: `/transfers/fiscal-years/autocomplete`,
      params,
    });
    response.data.count && setFiscalYearOptions(response.data.values);
  } catch (error) {
    setFiscalYearError({
      exist: true,
      message: error.message,
    });
  }
};

export const getGLPeriodsByMatch = async (
  value,
  setGLPeriodOptions,
  setGLPeriodError,
  fiscalYear
) => {
  const params = {
    size: 25,
    value: value || undefined,
    fiscalYear,
  };
  try {
    setGLPeriodError({
      exist: false,
      message: null,
    });
    const response = await axios({
      url: `/transfers/gl-periods/autocomplete`,
      params,
    });
    response.data.count && setGLPeriodOptions(response.data.values);
  } catch (error) {
    setGLPeriodError({
      exist: true,
      message: error.message,
    });
  }
};

export const getJournalSourcesByMatch = async (
  value,
  setJournalSourceOptions,
  setJournalSourcesError
) => {
  const params = {
    size: 25,
    value: value || undefined,
  };
  try {
    setJournalSourcesError({
      exist: false,
      message: null,
    });
    const response = await axios({
      url: `/transfers/journal-sources/autocomplete`,
      params,
    });
    response.data.count && setJournalSourceOptions(response.data.values);
  } catch (error) {
    setJournalSourcesError({
      exist: true,
      message: error.message,
    });
  }
};

export const getAllTransfers = async (
  filters,
  pagination,
  sort,
  setTransfers,
  setTotalCount,
  setLoading,
  clearAlert,
  setAlert
) => {
  try {
    clearAlert();
    const {
      fiscalYear,
      glPeriod,
      journalSource,
      conditionType,
      posted,
      notification,
    } = filters;
    const { page, rowsPerPage } = pagination;
    const { column, orderBy } = sort;

    const request = {
      url: `/transfers`,
      params: {
        fiscalYear: fiscalYear || undefined,
        glPeriod: glPeriod || undefined,
        journalSource: journalSource || undefined,
        conditionType: conditionType || undefined,
        posted: posted || undefined,
        notification: notification || undefined,
        p: page + 1,
        ps: rowsPerPage,
        s: `${column}:${orderBy}`,
      },
    };
    setLoading(true);
    const response = await axios(request);
    setTransfers(response.data.values || []);
    setTotalCount(response.data.totalCount);
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

export const getGLPeriod = async (
  setGLPeriodOptions,
  setGLPeriod,
  filters,
  setFilters,
  pagination,
  setPagination,
  setGLPeriodError,
  { fiscalYear, size, value }
) => {
  const params = {
    fiscalYear: fiscalYear || undefined,
    size: size || 10,
    value: value || undefined,
  };
  try {
    setGLPeriodError({
      exist: false,
      message: null,
    });
    const response = await axios({
      url: `/transfers/gl-periods/autocomplete`,
      params,
    });
    if (response.data.count) {
      setGLPeriodOptions(response.data.values);
      setGLPeriod(
        response.data.values ? response.data.values[0].displayText : ""
      );
      setFilters({
        ...filters,
        fiscalYear,
        glPeriod: response.data.values[0].displayText,
      });
      setPagination({
        ...pagination,
        page: 1,
      });
    } else {
      setGLPeriodOptions([]);
      setGLPeriod("");
    }
  } catch (error) {
    setGLPeriodError({
      exist: true,
      message: error.message,
    });
  }
};

export const getRedistributeTransfers = async (
  setTransferDetails,
  setRedistributeTransfers,
  setRedistributeTransfersCount,
  setSaveRedistributeTransfers,
  setRemainingToDistribute,
  setTransferInAmount,
  setLoading,
  clearAlert,
  setAlert,
  { transferId }
) => {
  try {
    clearAlert();
    setLoading(true);
    const response = await axios({
      url: `transfers/${transferId}/redistributes`,
    });

    setTransferDetails(response.data.transfer);
    setRedistributeTransfers(response.data.transfers.values || []);
    setRedistributeTransfersCount(response.data.transfers.count);
    setSaveRedistributeTransfers(new Array(response.data.transfers.count));
    setTransferInAmount(response.data.transfer.amount);
    setRemainingToDistribute(response.data.transfer.amount);
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

export const postRedistributeTransfers = async (
  id,
  params,
  setTransfers,
  transferIndex,
  clearAlert,
  setAlert,
  t
) => {
  const obj = params
    .filter((key) => key !== null)
    .map((row, index) => {
      return {
        commitment: { id: row.commitmentId, name: row.commitmentName },
        component: { id: row.componentId, componentName: row.commitmentName },
        ptaNumber: row.ptaNumber,
        amount: row.redistributedAmount,
      };
    });
  try {
    clearAlert();
    const response = await axios.post(`/transfers/${id}/redistributes`, {
      transfers: { values: obj },
    });

    const getRedistributeMessage = (pta, redistributedStatus) => {
      switch (redistributedStatus) {
        case "warning":
          setAlert(
            "warning",
            `${pta} ${t(
              "UploadedExpenses.mainView.redistributed.redistributedWithWarning"
            )}`
          );
          break;
        case "without_warning":
          setAlert(
            "success",
            `${pta} ${t(
              "UploadedExpenses.mainView.redistributed.redistributedWithoutWarnings"
            )}`,
            true
          );
          break;
        default:
          break;
      }
    };
    if (response) {
      var ptaNumber;
      if (!response.data.transfer.task) {
        ptaNumber = `${
          response.data.transfer.project
        }-${"\u00A0\u00A0\u00A0"}-${response.data.transfer.award}`;
      }

      if (response.data.transfer.task) {
        ptaNumber = response.data.transfer.ptaNumber;
      }

      getRedistributeMessage(
        ptaNumber,
        response.data.redistrbutedStatus?.toLowerCase()
      );

      setTransfers((transfers) => {
        const items = transfers.map((transfer, index) =>
          index === transferIndex ? response.data.transfer : transfer
        );
        return items;
      });
    }
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const postAdvanceGLPeriod = async (
  advanceGLPeriod,
  setShowConfirmationDialog,
  setOpenUploadTransfersDialog,
  location,
  clearAlert,
  setAlert
) => {
  const params = {
    lastUploadGLPeriod: advanceGLPeriod,
  };
  try {
    clearAlert();
    const response = await axios({
      method: "post",
      url: `/transfers/advance-gl-periods`,
      data: params,
    });
    if (response.data) {
      setShowConfirmationDialog(false);
      setOpenUploadTransfersDialog(false);
      window.location.href = `/transfers${location.search.replace(
        /\bglPeriod=[0-9a-zA-Z_@.#+-]{1,50}\b/,
        "glPeriod=" + advanceGLPeriod
      )}`;
    }
  } catch (error) {
    setAlert("error", error.message);
  }
};
