import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid as MuiGrid,
  Paper as MuiPaper,
  Tooltip as MuiTooltip,
  IconButton as MuiIconButton,
  Typography as MuiTypography,
} from "@mui/material";
import {
  Edit as MuiEditIcon,
  AddCircle as MuiAddCircleIcon,
} from "@mui/icons-material";
import { useBudgetingContext } from "app/services/budgetingContext";
import { BudgetingFormDialog } from "./BudgetingFormDialog";

export const InformationPanel = () => {
  const { t } = useTranslation();

  const {
    infoPanelDisabled,
    setInfoDialogOpen,
    budgetFiscalYearResponse,
    setRefreshInformationPanel,
  } = useBudgetingContext();

  // Open/Close add/edit budget fiscal year dialog
  const [
    openAddEditBudgetFiscalYearDialog,
    setOpenAddEditBudgetFiscalYearDialog,
  ] = useState(false);

  return (
    <>
      <MuiGrid
        container
        component={MuiPaper}
        sx={{ padding: "16px 18px" }}
        justifyContent={"space-between"}
      >
        <MuiGrid item xs={2}>
          <MuiTypography
            component="span"
            color={"textSecondary"}
            variant={"body2"}
          >
            {t("Budgeting.mainView.informationPanel.budgetFiscalYear")}
          </MuiTypography>
          :{" "}
          <MuiTypography component="span" variant={"body2"}>
            {budgetFiscalYearResponse.budgetFiscalYear}
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item xs={2}>
          <MuiTypography
            component="span"
            color={"textSecondary"}
            variant={"body2"}
          >
            {t("Budgeting.mainView.informationPanel.editStatus")}:{" "}
          </MuiTypography>
          <MuiTypography component="span" variant={"body2"}>
            {budgetFiscalYearResponse.openEditStatus &&
              t("Commitments.mainView.list.tableValues.statusOpenText")}
            {!budgetFiscalYearResponse.openEditStatus &&
              t("Commitments.mainView.list.tableValues.statusClosedText")}
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item xs={4}>
          <MuiTypography
            component="span"
            color={"textSecondary"}
            variant={"body2"}
          >
            {t("Budgeting.mainView.informationPanel.initialEditPeriod")}:{" "}
          </MuiTypography>
          <MuiTypography component="span" variant={"body2"}>
            {budgetFiscalYearResponse.initialEditStartDate} -{" "}
            {budgetFiscalYearResponse.initialEditEndDate}
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item xs={3}>
          <MuiTypography
            component="span"
            color={"textSecondary"}
            variant={"body2"}
          >
            {t("Budgeting.mainView.informationPanel.revisionEditPeriod")}:{" "}
          </MuiTypography>
          <MuiTypography component="span" variant={"body2"}>
            {budgetFiscalYearResponse.revisionEditStartDate} -{" "}
            {budgetFiscalYearResponse.revisionEditEndDate}
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item xs={1} container justifyContent="flex-end">
          {budgetFiscalYearResponse._links &&
            budgetFiscalYearResponse._links.edit !== undefined && (
              <MuiTooltip
                title={t(
                  "Budgeting.mainView.informationPanel.editBudgetFiscalYearToolTip"
                )}
                disableFocusListener={infoPanelDisabled}
                disableHoverListener={infoPanelDisabled}
                disableTouchListener={infoPanelDisabled}
              >
                <MuiIconButton
                  size="small"
                  onClick={() => {
                    if (!infoPanelDisabled) {
                      setOpenAddEditBudgetFiscalYearDialog("EDIT");
                      setRefreshInformationPanel(false);
                      setInfoDialogOpen(true);
                    }
                  }}
                >
                  <MuiEditIcon
                    color={infoPanelDisabled ? "disabled" : "inherit"}
                  />
                </MuiIconButton>
              </MuiTooltip>
            )}
          {budgetFiscalYearResponse._links &&
            budgetFiscalYearResponse._links.create !== undefined && (
              <MuiTooltip
                title={t(
                  "Budgeting.mainView.informationPanel.addBudgetFiscalYearToolTip"
                )}
                disableFocusListener={infoPanelDisabled}
                disableHoverListener={infoPanelDisabled}
                disableTouchListener={infoPanelDisabled}
              >
                <MuiIconButton
                  size="small"
                  onClick={() => {
                    if (!infoPanelDisabled) {
                      setOpenAddEditBudgetFiscalYearDialog("ADD");
                      setRefreshInformationPanel(false);
                      setInfoDialogOpen(true);
                    }
                  }}
                >
                  <MuiAddCircleIcon
                    color={infoPanelDisabled ? "disabled" : "inherit"}
                  />
                </MuiIconButton>
              </MuiTooltip>
            )}
        </MuiGrid>
      </MuiGrid>

      {openAddEditBudgetFiscalYearDialog && (
        <BudgetingFormDialog
          openDialog={openAddEditBudgetFiscalYearDialog}
          setOpenDialog={setOpenAddEditBudgetFiscalYearDialog}
        />
      )}
    </>
  );
};
