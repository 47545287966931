import { useTranslation } from "react-i18next";
import {
  TextField as MuiTextField,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import { useBudgetingContext } from "app/services/budgetingContext";

export const DepartmentNameControl = (props) => {
  const { t } = useTranslation();

  // context
  const {
    orgs,
    setOrgCode,
    setOrgName,
    deptSelection,
    setDeptSelection,
    orgByCodeError,
    loading,
  } = useBudgetingContext();
  return (
    <MuiAutocomplete
      id="departmentName"
      loading={loading}
      options={orgs}
      getOptionLabel={(option) => option.displayText || ""}
      value={deptSelection}
      autoHighlight
      disableClearable
      handleHomeEndKeys
      openOnFocus
      onChange={(event, val) => {
        setOrgCode((val && val.value.orgCode) || "");
        setOrgName((val && val.displayText) || "");
        setDeptSelection(val);
      }}
      fullWidth
      renderInput={(params) => (
        <MuiTextField
          {...params}
          label={`${t("Budgeting.mainView.departmentNameDropdown.title")}`}
          variant="outlined"
          error={orgByCodeError.exist}
          helperText={orgByCodeError.message}
        />
      )}
    />
  );
};
