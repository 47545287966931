import { useState } from "react";
import qs from "qs";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  Grid as MuiGrid,
  Radio as MuiRadio,
  Button as MuiButton,
  FormLabel as MuiFormLabel,
  RadioGroup as MuiRadioGroup,
  FormControl as MuiFormControl,
  FormControlLabel as MuiFormControlLabel,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import { FileDownload as MuiFileDownloadIcon } from "@mui/icons-material";
import { useUsers } from "common";
import { useDownload } from "app/shared/ui/Download";
import { PositionedSnackbar } from "app/shared/ui/SnackBar";
import { useBudgetingContext } from "app/services/budgetingContext";
import { BudgetTable } from "./BudgetTable";
import { DepartmentNameControl } from "./DepartmentNameControl";

export const Budgeting = (props) => {
  const [snackBar, setSnackBar] = useState(false);

  // context
  const {
    budgetFiscalYearResponse,
    budgetType,
    setBudgetType,
    getQueryParams,
    loading,
  } = useBudgetingContext();

  const { t } = useTranslation();
  const { getDocument, downloadInProgress } = useDownload();
  const { currentUser } = useUsers();
  const { roles } = currentUser;

  // XXX: This logic should be replaced with looking at the existence of a edit budget hateoas link
  const isBudgetEditableByCurrentUser = () => {
    const isCommitmentAdmin = roles.includes("COMMITMENT_ADMIN");
    const isBudgetAdmin = roles.includes("BUDGET_ADMIN");
    if (isCommitmentAdmin) {
      return true;
    } else if (isBudgetAdmin && !!budgetFiscalYearResponse?.openEditStatus) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <MuiGrid
      container
      marginTop="24px"
      spacing={2}
      justifyContent="space-between"
    >
      <MuiGrid item xs={6} container spacing={2} justifyContent="space-between">
        <MuiGrid item xs={6}>
          <DepartmentNameControl />
        </MuiGrid>
        <MuiGrid item xs={6}>
          <MuiFormControl component="fieldset">
            <MuiFormLabel component="legend">
              {t("Budgeting.mainView.budgetTypeFilter.label")}
            </MuiFormLabel>
            <MuiRadioGroup
              row
              aria-label={t("Budgeting.mainView.budgetTypeFilter.label")}
              name="row-radio-buttons-group"
              value={budgetType}
              onChange={(_event, val) => {
                setBudgetType(val);
              }}
            >
              <MuiFormControlLabel
                value="ALL"
                control={<MuiRadio color="primary" />}
                label={t("Budgeting.mainView.budgetTypeFilter.options.all")}
              />
              <MuiFormControlLabel
                value="EXISTING"
                control={<MuiRadio color="primary" />}
                label={t(
                  "Budgeting.mainView.budgetTypeFilter.options.existing"
                )}
              />
              <MuiFormControlLabel
                value="NEW"
                control={<MuiRadio color="primary" />}
                label={t("Budgeting.mainView.budgetTypeFilter.options.new")}
              />
            </MuiRadioGroup>
          </MuiFormControl>
        </MuiGrid>
      </MuiGrid>
      <MuiGrid item>
        <MuiButton
          variant="outlined"
          color="inherit"
          startIcon={
            downloadInProgress ? (
              <MuiCircularProgress size={15} />
            ) : (
              <MuiFileDownloadIcon />
            )
          }
          disabled={!!downloadInProgress || loading}
          onClick={() => {
            getDocument(
              `${axios.defaults.baseURL}/budgets/excel?${qs.stringify(
                getQueryParams()
              )}`,
              "budget_table_export.xlsx"
            );
          }}
        >
          Export
        </MuiButton>
      </MuiGrid>

      {snackBar ? (
        <PositionedSnackbar
          message={t("Budgeting.mainView.list.budgetUpdated")}
        />
      ) : null}

      <MuiGrid item xs={12}>
        <BudgetTable
          setSnackBar={setSnackBar}
          isBudgetEditableByCurrentUser={isBudgetEditableByCurrentUser}
          loading={loading}
        />
      </MuiGrid>
    </MuiGrid>
  );
};

const getLastTwoDigits = (int) => parseInt(int, 10) % 100;

/**
 * @returns {string} the `FY{-3} Actuals` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getThreeYearsAgoActualsHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyActualsThree`, {
    fyActualsThree: fiscalYear && getLastTwoDigits(fiscalYear - 3),
  });
};
/**
 * @returns {string} the `FY{-2} Actuals` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getYearBeforeLastActualsHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyActualsTwo`, {
    fyActualsTwo: fiscalYear && getLastTwoDigits(fiscalYear - 2),
  });
};

/**
 * @returns {string} the `FY{-1} Actuals thru Feb` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getLastYearActualsHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyActualsOne`, {
    fyActualsOne: fiscalYear && getLastTwoDigits(fiscalYear - 1),
  });
};
/**
 * @returns {string} the `FY{-1} Budget` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getLastYearBudgetHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyBudgetOne`, {
    fyBudgetOne: fiscalYear && getLastTwoDigits(fiscalYear - 1),
  });
};
/**
 * @returns {string} the `FY{-1} Projection` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getLastYearProjectionHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyProjection`, {
    fyProjection: fiscalYear && getLastTwoDigits(fiscalYear - 1),
  });
};

/**
 * @returns {string} the `FY{XX} Budget` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getCurrentYearBudgetHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyBudgetTwo`, {
    fyBudgetTwo: fiscalYear && getLastTwoDigits(fiscalYear),
  });
};
/**
 * @returns {string} the `FY{xx} BudgetCap` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getCurrentYearBudgetCapHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyBudgetCap`, {
    fyBudgetCap: fiscalYear && getLastTwoDigits(fiscalYear),
  });
};

/**
 * @returns {string} the `FY{+1} Plan` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getNextYearPlanHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyPlanOne`, {
    fyPlanOne: fiscalYear && getLastTwoDigits(fiscalYear + 1),
  });
};
/**
 * @returns {string} the `FY{-+2} Plan` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getYearAfterNextPlanHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyPlanTwo`, {
    fyPlanTwo: fiscalYear && getLastTwoDigits(fiscalYear + 2),
  });
};
/**
 * @returns {string} the `FY{+2} Available After` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getYearAfterNextAvailableHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.availableAfter`, {
    availableAfter: fiscalYear && getLastTwoDigits(fiscalYear + 2),
  });
};
