import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const MonthlyExpenseTableHead = (props) => {
  const { t } = useTranslation();
  const tableColumnsStr =
    "UploadedTransfers.mainView.list.redistributeTransfersDialog.tableColumns.monthlyTransferDetail";
  const detailsPageCommonStr =
    "UploadedTransfers.mainView.list.redistributeTransfersDialog.detailsPage";
  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell align="left" key="glPeriod" width="10%">
          {props.showDetailsView &&
            `${t(`${detailsPageCommonStr}.sourceLabel`)} \n `}
          {t(`${tableColumnsStr}.glPeriod`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="project">
          {t(`${tableColumnsStr}.project`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="award">
          {t(`${tableColumnsStr}.award`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="source">
          {t(`${tableColumnsStr}.source`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="journalName" width="30%">
          {t(`${tableColumnsStr}.journalName`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="deptReference">
          {t(`${tableColumnsStr}.deptReference`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="objectCode">
          {t(`${tableColumnsStr}.objectCode`)}
        </MuiTableCell>
        {!props.showDetailsView && (
          <MuiTableCell align="left" key="journalLineText" width="30%">
            {t(`${tableColumnsStr}.journalLineText`)}
          </MuiTableCell>
        )}
        {props.showDetailsView && (
          <MuiTableCell align="left" key="redistributedAmount" width="15%">
            {t(`${detailsPageCommonStr}.redistributedAmount`)}
          </MuiTableCell>
        )}
        {props.showDetailsView && (
          <MuiTableCell align="left" key="mtdAmount" width="15%">
            {t(`${detailsPageCommonStr}.mtdAmount`)}
          </MuiTableCell>
        )}
      </MuiTableRow>
    </MuiTableHead>
  );
};
