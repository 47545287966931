import { Box as MuiBox } from "@mui/material";
import { DropzoneArea } from "mui-file-dropzone";
import { useTranslation } from "react-i18next";

export const DropZone = (props) => {
  const { t } = useTranslation();
  const options = {
    acceptedFiles: props.acceptedFiles || [
      "image/png", // png
      "image/jpeg", // jpeg
      "image/gif", // gif
      "application/pdf", // pdf
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xls
      "application/vnd.ms-excel", // xlsx
      "application/vnd.ms-outlook", // msg
      ".msg", // msg via extension
    ],
    filesLimit: 1,
    dropzoneText:
      props.dropzoneText ||
      t(
        "Commitments.commitmentDocuments.create.form.fields.uploadedDocument.label"
      ),
    disableRejectionFeedback: false,
    showPreviews: false,
    showPreviewsInDropzone: false,
    showFileNamesInPreview: false,
    previewText: "",
    showAlerts: false,
  };

  return (
    <MuiBox
      sx={{
        "& .MuiBox-root": {
          width: "100%",
          display: "flex",
          minHeight: "80px",
          flexDirection: "column",
        },
        "& .MuiSvgIcon-root": {
          display: "none",
        },
        "& .MuiTypography-root": {
          fontSize: "14px",
          whiteSpace: "pre-line",
        },
        "& .MuiSvgIcon-root ~ .MuiTypography-root": {
          whiteSpace: "nowrap",
        },
      }}
    >
      <DropzoneArea
        onChange={props.handleDocumentUpload}
        onDrop={props.handleDrop}
        {...options}
      />
    </MuiBox>
  );
};
