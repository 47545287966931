import {
  TextField as MuiTextfield,
  Typography as MuiTypography,
  InputAdornment as MuiInputAdornment,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { disabledTextFieldStyles } from "app/shared/ui/sharedStyles";

//Created a separate function to convert number into Currency
//which removes negative sign from number and added comma for thousand separator
//and show negative numbers in small bracket

export const CurrencyFormat = (value) => {
  return (
    <>
      <MuiTypography variant="inherit" component="span">
        {value !== undefined && "$"}
      </MuiTypography>
      <MuiTypography
        component="span"
        variant="inherit"
        color={`${(value < 0 && "error") || "inherit"}`}
      >
        {value !== undefined &&
          `${(parseFloat(value) < 0 && "(") || ""}${
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            })
              .format(Math.abs(value))
              .split("$")[1]
          }${(parseFloat(value) < 0 && ")") || ""}`}
      </MuiTypography>
    </>
  );
};

export const FormattedCurrencyField = (props) => {
  const isViewOnly = props.isViewOnly || false;
  const shrinkProps = isViewOnly && { shrink: true };
  let otherProps = { ...props };
  Object.hasOwn(otherProps, "isViewOnly") && delete otherProps.isViewOnly;
  Object.hasOwn(otherProps, "setFieldValue") && delete otherProps.setFieldValue;
  return (
    <NumericFormat
      decimalScale={0}
      fixedDecimalScale
      thousandSeparator={false}
      customInput={MuiTextfield}
      isAllowed={(args) => {
        const { value } = args;
        return value >= 0 && value <= 999999999;
      }}
      allowNegative={false}
      InputProps={{
        startAdornment: (
          <MuiInputAdornment position="start">$</MuiInputAdornment>
        ),
      }}
      autoComplete="off"
      fullWidth
      required={false}
      InputLabelProps={{ ...shrinkProps }}
      sx={{
        "& input": {
          textAlign: "right",
        },
        ...disabledTextFieldStyles,
        ...props.sx,
      }}
      {...otherProps}
    />
  );
};
