import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableSortLabel as MuiTableSortLabel,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useRequestsContext } from "app/services/requestsContext";
import { Theme, useUsers } from "common";

export function CommitmentRequestTableHeaders(props) {
  const { /*order, orderBy,*/ onRequestSort, budgetFiscalYear } = props;
  const { t } = useTranslation();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { sort } = useRequestsContext();
  const { currentUser } = useUsers();
  const { roles } = currentUser;

  const isCommitmentAdmin = roles.includes("COMMITMENT_ADMIN");

  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell key="drawerHandle" scope="col" width="42px">
          {" "}
        </MuiTableCell>
        <MuiTableCell align="left" key="department" scope="col" width="120px">
          <MuiTableSortLabel
            active={sort.column === "org.orgName"}
            direction={sort.column === "org.orgName" ? sort.orderBy : "asc"}
            onClick={createSortHandler("org.orgName")}
          >
            {t(`Requests.mainView.list.tableColumns.department`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="commitmentName"
          scope="col"
          width="200px"
          sortDirection={sort.column === "name" ? sort.orderBy : "asc"}
        >
          <MuiTableSortLabel
            active={sort.column === "name"}
            direction={sort.column === "name" ? sort.orderBy : "asc"}
            onClick={createSortHandler("name")}
          >
            {t(`Requests.mainView.list.tableColumns.commitmentName`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="right" scope="col" width="135px">
          {t(`Requests.mainView.list.tableColumns.totalCommitmentRequested`)}
        </MuiTableCell>
        <MuiTableCell align="right" scope="col" width="160px">
          {t(`Requests.mainView.list.tableColumns.totalCommitmentApproved`)}
        </MuiTableCell>
        <MuiTableCell align="right" scope="col" width="120px">
          {t(`Requests.mainView.list.tableColumns.totalFyProjection`, {
            fyProjection: (parseInt(budgetFiscalYear, 10) % 100) - 1,
          })}
        </MuiTableCell>
        <MuiTableCell align="right" scope="col" width="120px">
          {t(`Requests.mainView.list.tableColumns.totalFyBudget`, {
            fyBudgetOne: parseInt(budgetFiscalYear, 10) % 100,
          })}
        </MuiTableCell>
        {isCommitmentAdmin && (
          <MuiTableCell
            align="left"
            scope="col"
            sx={{
              width: "120px",
              [Theme.breakpoints.up("1700")]: {
                width: "200px",
              },
              [Theme.breakpoints.up("1900")]: {
                width: "300px",
              },
            }}
          >
            {t(`Requests.mainView.list.tableColumns.controllerNotes`)}
          </MuiTableCell>
        )}
        <MuiTableCell
          align="left"
          scope="col"
          sx={{
            width: "120px",
            [Theme.breakpoints.up("1700")]: {
              width: "200px",
            },
            [Theme.breakpoints.up("1900")]: {
              width: "300px",
            },
          }}
        >
          {t(`Requests.mainView.list.tableColumns.controllerComments`)}
        </MuiTableCell>
        <MuiTableCell
          align="center"
          scope="col"
          key="actions"
          sx={{ width: "110px" }}
        >
          {t(`Requests.mainView.list.tableColumns.actions`)}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
}

CommitmentRequestTableHeaders.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};
