import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Grid as MuiGrid,
  Paper as MuiPaper,
  Table as MuiTable,
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  Typography as MuiTypography,
  TableContainer as MuiTableContainer,
  TableSortLabel as MuiTableSortLabel,
  TablePagination as MuiTablePagination,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import {
  Edit as MuiEditIcon,
  Info as MuiInfoIcon,
  Error as MuiErrorIcon,
  Check as MuiCheckIcon,
  Warning as MuiWarningIcon,
  PlaylistAddCheck as MuiPlaylistAddCheckIcon,
  FormatListNumberedRtl as MuiFormatListNumberedRtlIcon,
} from "@mui/icons-material";
import {
  getAllExpenses,
  getRevalidateAndPost,
} from "app/services/expensesService";
import { Theme, useAlerts } from "common";
import { useExpensesContext } from "app/services/expensesContext";
import { CurrencyFormat } from "app/shared/ui/CurrencyFormat";
import { whiteTableHeaderLabel } from "app/shared/ui/sharedStyles";
import { NotificationStatus } from "./NotificationStatus";
import { RedistributeExpenses } from "./RedistributeExpenses";

function EnhancedTableHead(props) {
  const { t } = useTranslation();
  const { onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const tableColumnsStr = "UploadedExpenses.mainView.list.tableColumns";
  const filterPanelStr = "UploadedExpenses.filterPanelItems.options";

  const { sort } = useExpensesContext();

  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell
          align="left"
          key="notification"
          width="105"
          sortDirection={
            sort.column === "notification.status" ? sort.orderBy : false
          }
        >
          <MuiTableSortLabel
            active={sort.column === "notification.status"}
            direction={
              sort.column === "notification.status" ? sort.orderBy : "asc"
            }
            onClick={createSortHandler("notification.status")}
          >
            {t(`${filterPanelStr}.notification.label`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="fiscalYear"
          width="65"
          sortDirection={sort.column === "fiscalYear" ? sort.orderBy : "asc"}
        >
          <MuiTableSortLabel
            active={sort.column === "fiscalYear"}
            direction={sort.column === "fiscalYear" ? sort.orderBy : "asc"}
            onClick={createSortHandler("fiscalYear")}
          >
            {t(`${filterPanelStr}.fiscalYear.label`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="glPeriod"
          width="70"
          sortDirection={sort.column === "glPeriod" ? sort.orderBy : "asc"}
        >
          <MuiTableSortLabel
            active={sort.column === "glPeriod"}
            direction={sort.column === "glPeriod" ? sort.orderBy : "asc"}
            onClick={createSortHandler("glPeriod")}
          >
            {t(`${tableColumnsStr}.glPeriod`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="commitmentName"
          width="110"
          sortDirection={
            sort.column === "commitment.name" ? sort.orderBy : "asc"
          }
        >
          <MuiTableSortLabel
            active={sort.column === "commitment.name"}
            direction={sort.column === "commitment.name" ? sort.orderBy : "asc"}
            onClick={createSortHandler("commitment.name")}
          >
            {t(`${tableColumnsStr}.commitmentName`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="componentName"
          width="110"
          sortDirection={
            sort.column === "component.componentName" ? sort.orderBy : "asc"
          }
        >
          <MuiTableSortLabel
            active={sort.column === "component.componentName"}
            direction={
              sort.column === "component.componentName" ? sort.orderBy : "asc"
            }
            onClick={createSortHandler("component.componentName")}
          >
            {t(`${tableColumnsStr}.componentName`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="pta"
          width="70"
          sortDirection={sort.column === "pta" ? sort.orderBy : "asc"}
        >
          <MuiTableSortLabel
            active={sort.column === "pta"}
            direction={sort.column === "pta" ? sort.orderBy : "asc"}
            onClick={createSortHandler("pta")}
          >
            {t(`${tableColumnsStr}.pta`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="expenditureCategory"
          width="110"
          sortDirection={
            sort.column === "expenditure.name" ? sort.orderBy : "asc"
          }
        >
          <MuiTableSortLabel
            active={sort.column === "expenditure.name"}
            direction={
              sort.column === "expenditure.name" ? sort.orderBy : "asc"
            }
            onClick={createSortHandler("expenditure.name")}
          >
            {t(`${tableColumnsStr}.expenditureCategory`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="mtdAmount"
          width="90"
          sortDirection={sort.column === "mtdAmount" ? sort.orderBy : "asc"}
        >
          <MuiTableSortLabel
            active={sort.column === "mtdAmount"}
            direction={sort.column === "mtdAmount" ? sort.orderBy : "asc"}
            onClick={createSortHandler("mtdAmount")}
          >
            {t(`${tableColumnsStr}.mtdAmount`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="center"
          key="posted"
          width="70"
          sortDirection={sort.column === "isPosted" ? sort.orderBy : "asc"}
        >
          <MuiTableSortLabel
            active={sort.column === "isPosted"}
            direction={sort.column === "isPosted" ? sort.orderBy : "asc"}
            onClick={createSortHandler("isPosted")}
          >
            {t(`${filterPanelStr}.posted.label`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="errorWarnings"
          width="120"
          sortDirection={
            sort.column === "validateStatus" ? sort.orderBy : "asc"
          }
        >
          <MuiTableSortLabel
            active={sort.column === "validateStatus"}
            direction={sort.column === "validateStatus" ? sort.orderBy : "asc"}
            onClick={createSortHandler("validateStatus")}
          >
            {t(`${tableColumnsStr}.errorWarnings`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="center" key="actions" width="70">
          {t(`${tableColumnsStr}.actions`)}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export const Expenses = (props) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("validateStatus");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [expenses, setExpenses] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const [openRedistributeDialog, setOpenRedistributeDialog] = useState(false);
  const [statusToChange, setStatusToChange] = useState();
  const [expenseIdToUpdate, setExpenseIdToUpdate] = useState();
  const [expenseIndexToUpdate, setExpenseIndexToUpdate] = useState();

  const [expenseIdToRedistribute, setExpenseIdToRedistribute] = useState();

  const [showDetailsView, setShowDetailsView] = useState(false);

  const {
    expensesFilters,
    pagination,
    sort,
    setPagination,
    setSort,
    lastUploadedInfo,
    initialLoad,
  } = useExpensesContext();

  const { clearAlert, setAlert } = useAlerts();

  const uploadExpensesFilterPanelItemsOptions =
    "UploadedExpenses.filterPanelItems.options.notificationDropdownValues";

  const uploadExpensesMainViewList = "UploadedExpenses.mainView.list";

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);
    setSort({
      column: property,
      orderBy: isAsc,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    var pageValue = page;
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      totalCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      setPage(0);
      pageValue = 0;
    }
    setPagination({
      rowsPerPage: parseInt(event.target.value, 10),
      page: pageValue,
    });
  };

  const _handleRedistribute = (rowId, index) => {
    setOpenRedistributeDialog(true);
    setExpenseIdToRedistribute(rowId);
    setExpenseIndexToUpdate(index);
    setShowDetailsView(false);
  };

  const _handleEdit = (rowId, index, notificationStatus) => {
    setExpenseIdToUpdate(rowId);
    setExpenseIndexToUpdate(index);
    setStatusToChange(notificationStatus);
    setOpenNotificationDialog(true);
  };

  const StatusInfo = ({ notification }) => {
    const unresolvedString = (
      <span style={{ fontWeight: 600 }}>
        {t(`${uploadExpensesFilterPanelItemsOptions}.unprocessed`)}
      </span>
    );
    const inProcessString = (
      <span style={{ fontWeight: 600 }}>
        {t(`${uploadExpensesFilterPanelItemsOptions}.inProcess`)}
      </span>
    );
    const resolvedString = (
      <span style={{ fontWeight: 600 }}>
        {t(`${uploadExpensesFilterPanelItemsOptions}.resolved`)}
      </span>
    );
    const byString = t(
      `${uploadExpensesMainViewList}.notificationStatusTooltip.by`
    );
    const onString = t(
      `${uploadExpensesMainViewList}.notificationStatusTooltip.on`
    );
    switch (notification.status) {
      case "UNRESOLVED":
        return (
          <>
            {unresolvedString}{" "}
            {`${t(byString)} ${notification.unresolvedBy} ${t(onString)} ${
              notification.unresolvedDate
            }`}
          </>
        );
      case "INPROCESS":
        return (
          <>
            {unresolvedString}{" "}
            {`${t(byString)} ${notification.unresolvedBy} ${t(onString)} ${
              notification.unresolvedDate
            }`}
            {"\n\n"}
            {notification.inProcessBy && (
              <>
                {inProcessString}{" "}
                {`${t(byString)} ${notification.inProcessBy} ${t(onString)} ${
                  notification.inProcessDate
                }
            ${notification.inProcessNote}`}
              </>
            )}
          </>
        );
      case "RESOLVED":
        return (
          <>
            {unresolvedString}{" "}
            {`${t(byString)} ${notification.unresolvedBy} ${t(onString)} ${
              notification.unresolvedDate
            }`}
            {notification.inProcessBy && (
              <>
                {"\n\n"}
                {inProcessString}{" "}
                {`${t(byString)} ${notification.inProcessBy} ${t(onString)} ${
                  notification.inProcessDate
                }
            ${notification.inProcessNote}`}
              </>
            )}
            {notification.resolvedBy && (
              <>
                {"\n\n"}
                {resolvedString}{" "}
                {`${t(byString)} ${notification.resolvedBy} ${t(onString)} ${
                  notification.resolvedDate
                }
            ${notification.resolvedNote}`}
              </>
            )}
          </>
        );
      default:
        return "";
    }
  };

  const getStatusName = (status) => {
    switch (status) {
      case "UNRESOLVED":
        return t(`${uploadExpensesFilterPanelItemsOptions}.unprocessed`);
      case "INPROCESS":
        return t(`${uploadExpensesFilterPanelItemsOptions}.inProcess`);
      case "RESOLVED":
        return t(`${uploadExpensesFilterPanelItemsOptions}.resolved`);
      default:
        return "";
    }
  };

  useEffect(() => {
    !initialLoad &&
      getAllExpenses(
        expensesFilters,
        pagination,
        sort,
        setExpenses,
        setTotalCount,
        setLoading,
        clearAlert,
        setAlert
      );
    // eslint-disable-next-line
  }, [expensesFilters, pagination, sort]);

  const displayLastGLPeriod = (preference) => {
    return t("UploadedExpenses.mainView.subTitle", {
      glPeriod: lastUploadedInfo.lastUploadGLPeriod || "",
    });
  };

  return (
    <>
      <MuiGrid container direction="row" spacing={1}>
        <MuiGrid item xs={12}>
          <MuiTypography
            variant="subtitle1"
            sx={{ color: Theme.palette.grey[700] }}
          >
            {displayLastGLPeriod()}
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <MuiTableContainer component={MuiPaper}>
            <MuiTable
              aria-label="customized table"
              stickyHeader
              sx={whiteTableHeaderLabel}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <MuiTableBody>
                {/* {error && `Error: ${error}`} */}
                {loading && (
                  <MuiTableRow>
                    <MuiTableCell colSpan="11" sx={{ textAlign: "center" }}>
                      <MuiCircularProgress />
                    </MuiTableCell>
                  </MuiTableRow>
                )}
                {!loading &&
                  expenses.map((row, index) => (
                    <MuiTableRow key={index}>
                      <MuiTableCell align="left" component="th" scope="row">
                        <span style={{ gap: "5px", display: "flex" }}>
                          <MuiTooltip
                            title={
                              row.notification && (
                                <StatusInfo notification={row.notification} />
                              )
                            }
                          >
                            <MuiTypography variant="body2">
                              {row.notification &&
                                getStatusName(row.notification.status)}
                            </MuiTypography>
                          </MuiTooltip>
                          {row.notification &&
                            row.notification.status.toLowerCase() !==
                              "resolved" && (
                              <MuiTooltip
                                title={t(
                                  `${uploadExpensesMainViewList}.editNotificationToolTip`
                                )}
                              >
                                <MuiEditIcon
                                  fontSize="small"
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    _handleEdit(
                                      row.id,
                                      index,
                                      row.notification.status
                                    );
                                  }}
                                />
                              </MuiTooltip>
                            )}
                        </span>
                      </MuiTableCell>
                      <MuiTableCell align="left">{row.fiscalYear}</MuiTableCell>
                      <MuiTableCell align="left" nowrap="nowrap">
                        {row.glPeriod}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {row.commitment && (
                          <RouterLink
                            style={{ textDecoration: "none" }}
                            to={{
                              pathname:
                                "/expenses/" +
                                row.id +
                                "/commitments/" +
                                row.commitment.id +
                                "/edit",
                            }}
                            target={"blank_" + row.commitment.id}
                          >
                            <MuiTooltip
                              title={row.commitment.name}
                              placement="left"
                            >
                              <MuiTypography noWrap variant="body2" width={180}>
                                {row.commitment.name}
                              </MuiTypography>
                            </MuiTooltip>
                          </RouterLink>
                        )}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {row.component && (
                          <RouterLink
                            style={{ textDecoration: "none" }}
                            to={{
                              pathname:
                                "/expenses/" +
                                row.id +
                                "/commitments/" +
                                row.commitment.id +
                                "/components/" +
                                row.component.id +
                                "/edit",
                            }}
                            target={`${row.commitment.id}${row.component.id}`}
                          >
                            <MuiTooltip
                              title={row.component.componentName}
                              placement="left"
                            >
                              <MuiTypography noWrap variant="body2" width={180}>
                                {row.component.componentName}
                              </MuiTypography>
                            </MuiTooltip>
                          </RouterLink>
                        )}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {row.pta && (
                          <RouterLink
                            style={{ textDecoration: "none" }}
                            to={{
                              pathname:
                                "/expenses/" +
                                row.id +
                                "/ptas/" +
                                row.ptaNumber,
                            }}
                            target={"blank_" + row.ptaNumber}
                          >
                            <MuiTypography variant="body2">
                              {row.ptaNumber}
                            </MuiTypography>
                          </RouterLink>
                        )}
                        {!row.pta && (
                          <MuiTypography variant="body2">
                            {!row.task &&
                              `${row.project}-${"\u00A0\u00A0\u00A0"}-${
                                row.award
                              }`}
                            {row.task && row.ptaNumber}
                          </MuiTypography>
                        )}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {row.expenditureCategory}
                      </MuiTableCell>
                      <MuiTableCell align="right">
                        {CurrencyFormat(row.mtdAmount)}
                      </MuiTableCell>
                      <MuiTableCell align="center">
                        {row.posted ? <MuiCheckIcon /> : ""}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {row.notification &&
                          row.notification.errorWarnings &&
                          row.notification.errorWarnings.map(
                            (anObjectMapped, index) => {
                              return (
                                <span
                                  key={index}
                                  style={{ gap: "5px", display: "flex" }}
                                >
                                  {row.validateStatus &&
                                  row.validateStatus.toLowerCase() ===
                                    "warning" ? (
                                    <MuiTooltip
                                      title={t(
                                        `${uploadExpensesMainViewList}.warningToolTip`
                                      )}
                                    >
                                      <MuiWarningIcon
                                        fontSize="small"
                                        sx={{
                                          color: Theme.palette.warning.main,
                                        }}
                                      />
                                    </MuiTooltip>
                                  ) : (
                                    <MuiTooltip
                                      title={t(
                                        `${uploadExpensesMainViewList}.errorToolTip`
                                      )}
                                    >
                                      <MuiErrorIcon
                                        fontSize="small"
                                        sx={{ color: Theme.palette.error.main }}
                                      />
                                    </MuiTooltip>
                                  )}

                                  <MuiTypography variant="body2" gutterBottom>
                                    {anObjectMapped}
                                  </MuiTypography>
                                </span>
                              );
                            }
                          )}
                        {row.validateStatus &&
                          row.validateStatus.toLowerCase() ===
                            "redistributed" && (
                            <span style={{ gap: "5px", display: "flex" }}>
                              <MuiTooltip
                                title={t(
                                  `${uploadExpensesMainViewList}.redistributedToolTip`
                                )}
                              >
                                <MuiInfoIcon
                                  fontSize="small"
                                  sx={{ color: Theme.palette.primary.main }}
                                />
                              </MuiTooltip>

                              {/* <RouterLink
                              onClick={() => {
                                setOpenRedistributeDialog(true);
                                setExpenseIdToRedistribute(row.id);
                                setShowDetailsView(true);
                              }}
                            > */}
                              <MuiTypography variant="body2">
                                {t(
                                  "UploadedExpenses.filterPanelItems.options.conditionTypeDropdownValues.redistributed"
                                )}
                              </MuiTypography>
                              {/* </RouterLink> */}
                            </span>
                          )}
                      </MuiTableCell>
                      <MuiTableCell align="center">
                        {row.validateStatus &&
                          row.validateStatus.toLowerCase() === "error" && (
                            <span
                              style={{
                                gap: "5px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <span>
                                <MuiTooltip
                                  title={t(
                                    `${uploadExpensesMainViewList}.revalidateAndPostToolTip`
                                  )}
                                >
                                  <MuiPlaylistAddCheckIcon
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      getRevalidateAndPost(
                                        setExpenses,
                                        index,
                                        clearAlert,
                                        setAlert,
                                        t,
                                        {
                                          expenseId: row.id,
                                        }
                                      );
                                    }}
                                  />
                                </MuiTooltip>
                              </span>

                              <span>
                                <MuiTooltip
                                  title={t(
                                    `${uploadExpensesMainViewList}.redistributeToolTip`
                                  )}
                                >
                                  <MuiFormatListNumberedRtlIcon
                                    fontSize="small"
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      _handleRedistribute(row.id, index);
                                    }}
                                  />
                                </MuiTooltip>
                              </span>
                            </span>
                          )}
                      </MuiTableCell>
                    </MuiTableRow>
                  ))}
                {!loading && !expenses.length && (
                  <MuiTableRow>
                    <MuiTableCell colSpan="11">
                      <MuiTypography
                        color="inherit"
                        align="center"
                        paragraph={false}
                      >
                        {t("globals.list.messages.noData")}
                      </MuiTypography>
                    </MuiTableCell>
                  </MuiTableRow>
                )}
              </MuiTableBody>
            </MuiTable>
            <MuiTablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MuiTableContainer>
        </MuiGrid>
      </MuiGrid>
      {openRedistributeDialog && (
        <RedistributeExpenses
          openDialog={openRedistributeDialog}
          setOpenDialog={setOpenRedistributeDialog}
          expenseIdToRedistribute={expenseIdToRedistribute}
          setExpenses={setExpenses}
          expenseIndex={expenseIndexToUpdate}
          showDetailsView={showDetailsView}
        />
      )}
      {openNotificationDialog && (
        <NotificationStatus
          openDialog={openNotificationDialog}
          setOpenDialog={setOpenNotificationDialog}
          statusToChange={statusToChange}
          expenseId={expenseIdToUpdate}
          setExpenses={setExpenses}
          expenseIndex={expenseIndexToUpdate}
        />
      )}
    </>
  );
};
