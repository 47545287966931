import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  TextField as MuiTextField,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import { getAllPrograms } from "app/services/commitmentsService";

export const CommitmentProgramField = ({
  required,
  error,
  helperText,
  ...passedParams
}) => {
  const { t } = useTranslation();

  // Programs
  const [programs, setPrograms] = useState([]);

  const [programError, setProgramError] = useState({
    exist: false,
    message: null,
  });

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();

    getAllPrograms(setPrograms, setProgramError, cancelSource);
    return () => {
      cancelSource.cancel();
    };
  }, []);

  return (
    <>
      {programs && (
        <MuiAutocomplete
          options={programs}
          getOptionLabel={(option) => option && option.name}
          autoHighlight
          disableClearable
          handleHomeEndKeys
          openOnFocus
          fullWidth
          clearIcon={false}
          required={required}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t(`Commitments.create.form.fields.program.label`)}
              variant={"outlined"}
              required={required}
              error={programError.exist || error}
              helperText={programError.message || helperText}
            />
          )}
          {...passedParams}
        />
      )}
    </>
  );
};
