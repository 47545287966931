import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Select as MuiSelect,
  TextField as MuiTextField,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import {
  getFiscalYearsByMatch,
  getGLPeriodsByMatch,
  getJournalSourcesByMatch,
  getGLPeriod,
} from "app/services/transfersService";
import { useTransfersContext } from "app/services/transfersContext";
import {
  ExpensesPosted,
  ExpensesConditionType,
  ExpensesNotification,
} from "app/shared/constants";
import { ResetFilter } from "app/shared/ui/FilterPanel/ResetFilter";

export const TransfersFilter = () => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const {
    filters,
    setFilters,
    pagination,
    setPagination,
    lastUploadedInfo,
    // setLastUploadedInfo,
  } = useTransfersContext();

  const [fiscalYearOptions, setFiscalYearOptions] = useState([]);
  const [glPeriodOptions, setGLPeriodOptions] = useState([]);
  const [journalSourceOptions, setJournalSourceOptions] = useState([]);

  // Controllable States - Fiscal year
  const [fiscalYear, setFiscalYear] = useState(
    lastUploadedInfo.lastUploadFiscalYear || ""
  );
  // Controllable States - GL Period
  const [glPeriod, setGLPeriod] = useState(
    lastUploadedInfo.lastUploadGLPeriod || ""
  );
  // Controllable State - Posted
  const [posted, setPosted] = useState(filters.posted);
  // Controllable State - Condition Type
  const [conditionType, setConditionType] = useState(filters.conditionType);
  // Controllable State - Notification Type
  const [notification, setNotification] = useState(filters.notification);
  // Controllable State - Journal Source
  const [journalSource, setJournalSource] = useState(filters.journalSource);
  const [fiscalYearError, setFiscalYearError] = useState({
    exist: false,
    message: null,
  });
  const [GLPeriodError, setGLPeriodError] = useState({
    exist: false,
    message: null,
  });
  const [journalSourcesError, setJournalSourcesError] = useState({
    exist: false,
    message: null,
  });

  useEffect(() => {
    getFiscalYearsByMatch("", setFiscalYearOptions, setFiscalYearError);
    getGLPeriodsByMatch(
      "",
      setGLPeriodOptions,
      setGLPeriodError,
      lastUploadedInfo.lastUploadFiscalYear || ""
    );
    getJournalSourcesByMatch(
      "",
      setJournalSourceOptions,
      setJournalSourcesError
    );
  }, [lastUploadedInfo.lastUploadFiscalYear]);

  useEffect(() => {
    const params = {
      fiscalYear,
      glPeriod,
      posted,
      conditionType,
      notification,
      journalSource,
    };
    const queryParams = Object.keys(params)
      .filter((key) => params[key])
      .map((key) => {
        return `${key}=${params[key]}`;
      })
      .join("&");
    navigate(`${location.pathname}?${queryParams}`);
  }, [
    fiscalYear,
    glPeriod,
    posted,
    conditionType,
    notification,
    journalSource,
    navigate,
    location.pathname,
  ]);

  useEffect(() => {
    setFiscalYear(lastUploadedInfo.lastUploadFiscalYear || "");
    setGLPeriod(lastUploadedInfo.lastUploadGLPeriod || "");
  }, [lastUploadedInfo]);

  // Function to reset the Filter value and re-load Deals data accordingly
  const resetFn = () => {
    setFiscalYear(lastUploadedInfo.lastUploadFiscalYear || "");
    setGLPeriod(lastUploadedInfo.lastUploadGLPeriod || "");
    setPosted("");
    setConditionType("");
    setNotification("");
    setJournalSource("");
    setFilters({
      fiscalYear: lastUploadedInfo.lastUploadFiscalYear || "",
      glPeriod: lastUploadedInfo.lastUploadGLPeriod || "",
      posted: "",
      conditionType: "",
      notification: "",
    });
    setPagination({
      page: 0,
      rowsPerPage: 10,
    });
  };

  const getPostedJSX = () => (
    <>
      <option value="" />
      {Object.keys(ExpensesPosted).map((key, index) => (
        <option value={key === "POSTED" ? true : false} key={key}>
          {t(ExpensesPosted[key])}
        </option>
      ))}
    </>
  );

  const getConditionTypeJSX = () => (
    <>
      <option value="" />
      {Object.keys(ExpensesConditionType).map((key, index) => (
        <option value={key.toLowerCase()} key={key}>
          {t(ExpensesConditionType[key])}
        </option>
      ))}
    </>
  );

  const getNotificationTypeJSX = () => (
    <>
      <option value="" />
      {Object.keys(ExpensesNotification).map((key, index) => (
        <option value={key.toLowerCase()} key={key}>
          {t(ExpensesNotification[key])}
        </option>
      ))}
    </>
  );
  return (
    <>
      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          id="fiscalYear"
          options={fiscalYearOptions}
          getOptionLabel={(option) =>
            (option && String(option.displayText)) || ""
          }
          clearOnEscape
          clearIcon={false}
          blurOnSelect
          value={{ displayText: fiscalYear }}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t(
                "UploadedTransfers.filterPanelItems.options.fiscalYear.label"
              )}
              variant="outlined"
              error={fiscalYearError.exist}
              helperText={fiscalYearError.message}
            />
          )}
          onChange={(event, newValue) => {
            const fiscalYear = newValue ? newValue.displayText : "";
            setFiscalYear(fiscalYear);
            getGLPeriod(
              setGLPeriodOptions,
              setGLPeriod,
              filters,
              setFilters,
              pagination,
              setPagination,
              setGLPeriodError,
              {
                fiscalYear: (newValue && newValue.displayText) || "",
                size: 25,
                value: "",
              }
            );
            setFilters({
              ...filters,
              fiscalYear,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          id="glPeriod"
          options={glPeriodOptions}
          getOptionLabel={(option) => option.displayText || ""}
          clearOnEscape
          clearIcon={false}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t("UploadedTransfers.mainView.list.tableColumns.glPeriod")}
              variant="outlined"
              error={GLPeriodError.exist}
              helperText={GLPeriodError.message}
            />
          )}
          value={{ displayText: glPeriod }}
          onChange={(event, newValue) => {
            const glPeriod = newValue ? newValue.displayText : "";
            setGLPeriod(glPeriod);
            setFilters({
              ...filters,
              glPeriod,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined" size="small">
        <MuiInputLabel id="postedLabel">
          {t("UploadedTransfers.filterPanelItems.options.posted.label")}
        </MuiInputLabel>
        <MuiSelect
          native
          labelId="postedSelectLabel"
          label={t("UploadedTransfers.filterPanelItems.options.posted.label")}
          id="posted"
          value={posted}
          onChange={(event) => {
            const posted = event.target.value;
            setPosted(posted);
            setFilters({
              ...filters,
              posted,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
        >
          {getPostedJSX()}
        </MuiSelect>
      </MuiFormControl>
      <MuiFormControl variant="outlined" size="small">
        <MuiInputLabel id="conditionTypeLabel">
          {t("UploadedTransfers.filterPanelItems.options.conditionType.label")}
        </MuiInputLabel>
        <MuiSelect
          native
          labelId="conditionTypeSelectLabel"
          label={t(
            "UploadedTransfers.filterPanelItems.options.conditionType.label"
          )}
          id="conditionType"
          value={conditionType}
          onChange={(event) => {
            const conditionType = event.target.value;
            setConditionType(conditionType);
            setFilters({
              ...filters,
              conditionType,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
        >
          {getConditionTypeJSX()}
        </MuiSelect>
      </MuiFormControl>
      <MuiFormControl variant="outlined" size="small">
        <MuiInputLabel id="notificationLabel">
          {t("UploadedTransfers.filterPanelItems.options.notification.label")}
        </MuiInputLabel>
        <MuiSelect
          native
          labelId="notificationSelectLabel"
          label={t(
            "UploadedTransfers.filterPanelItems.options.notification.label"
          )}
          id="notification"
          value={notification}
          onChange={(event) => {
            const notification = event.target.value;
            setNotification(notification);
            setFilters({
              ...filters,
              notification,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
        >
          {getNotificationTypeJSX()}
        </MuiSelect>
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          id="journalSource"
          options={journalSourceOptions}
          getOptionLabel={(option) =>
            (option.displayText && option.displayText) || ""
          }
          clearOnEscape
          clearIcon={false}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t(
                "UploadedTransfers.mainView.list.tableColumns.journalSource"
              )}
              variant="outlined"
              error={journalSourcesError.exist}
              helperText={journalSourcesError.message}
            />
          )}
          value={{ displayText: journalSource }}
          onChange={(event, newValue) => {
            const journalSource = newValue ? newValue.displayText : "";
            setJournalSource(journalSource);
            setFilters({
              ...filters,
              journalSource,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
        />
      </MuiFormControl>
      <ResetFilter resetFn={() => resetFn()} />
    </>
  );
};
