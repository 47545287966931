import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  Box as MuiBox,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Table as MuiTable,
  Button as MuiButton,
  Dialog as MuiDialog,
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  OutlinedInput as MuiOutlinedInput,
  InputAdornment as MuiInputAdornment,
  TableContainer as MuiTableContainer,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import { Theme, useAlerts } from "common";
import {
  getRedistributeTransfers,
  postRedistributeTransfers,
} from "app/services/transfersService";
import { TransferTypes } from "app/shared/constants";
import { FormButton } from "app/shared/ui/FormButton";
import { CurrencyFormat } from "app/shared/ui/CurrencyFormat";
import { ComponentTableHead } from "./ComponentTableHead";
import { MonthlyExpenseTableHead } from "./MonthlyExpenseTableHead";

export const RedistributeTransfers = ({
  openDialog,
  setOpenDialog,
  transferIdToRedistribute,
  setTransfers,
  transferIndex,
  showDetailsView,
}) => {
  const { t } = useTranslation();
  const handleClose = () => {
    setOpenDialog(false);
  };

  const [redistributeTransfers, setRedistributeTransfers] = useState([]);
  const [transferDetails, setTransferDetails] = useState([]);
  const [redistributeTransfersCount, setRedistributeTransfersCount] =
    useState();
  const [remainingToDistribute, setRemainingToDistribute] = useState(0);
  const [transferInAmount, setTransferInAmount] = useState(0);
  const [saveRedistributeTransfers, setSaveRedistributeTransfers] = useState(
    []
  );
  const [totalDistributeAmount, setTotalDistributeAmount] = useState(0);
  const [loading, setLoading] = useState(false);

  const { clearAlert, setAlert } = useAlerts();

  const commonTranslationString =
    "UploadedTransfers.mainView.list.redistributeTransfersDialog";

  const remainingRedistributeAmountcalculation = (
    index,
    redistributedAmount,
    ptaNumber,
    componentName,
    componentId,
    commitmentName,
    commitmentId
  ) => {
    saveRedistributeTransfers[index] =
      redistributedAmount !== ""
        ? {
            ptaNumber: ptaNumber,
            redistributedAmount: redistributedAmount,
            componentName: componentName,
            componentId: componentId,
            commitmentName: commitmentName,
            commitmentId: commitmentId,
          }
        : null;

    const totalAmount = saveRedistributeTransfers
      .filter((key) => key !== null && key.redistributedAmount !== "-")
      .reduce(
        (totalAmount, redistributeExpenses) =>
          totalAmount + parseFloat(redistributeExpenses.redistributedAmount),
        0
      );

    setTotalDistributeAmount(totalAmount);
    setRemainingToDistribute(transferInAmount - totalAmount);
  };

  const handleSubmit = () => {
    setOpenDialog(false);
    postRedistributeTransfers(
      transferDetails.id,
      saveRedistributeTransfers,
      setTransfers,
      transferIndex,
      clearAlert,
      setAlert,
      t
    );
  };

  useEffect(() => {
    getRedistributeTransfers(
      setTransferDetails,
      setRedistributeTransfers,
      setRedistributeTransfersCount,
      setSaveRedistributeTransfers,
      setRemainingToDistribute,
      setTransferInAmount,
      setLoading,
      clearAlert,
      setAlert,
      {
        transferId: transferIdToRedistribute,
      }
    );
    // eslint-disable-next-line
  }, [transferIdToRedistribute]);

  const DialogTitleMarkup = () => {
    const title = !showDetailsView
      ? `${commonTranslationString}.title`
      : `${commonTranslationString}.detailsPage.title`;

    return <MuiDialogTitle id="form-dialog-title">{t(title)}</MuiDialogTitle>;
  };

  return (
    <MuiDialog
      open={openDialog}
      aria-labelledby="form-dialog-title"
      fullWidth
      sx={{
        "& .MuiDialog-paperWidthSm": {
          maxWidth: "1150px",
          maxHeight: "none",
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitleMarkup />
        <MuiDialogContent>
          <MuiGrid
            container
            justifyContent="flex-end"
            direction="column"
            spacing={1}
            wrap="nowrap"
          >
            <MuiGrid item xs={12}>
              <MuiTableContainer component={MuiPaper}>
                <MuiTable
                  aria-label="customized table"
                  stickyHeader
                  sx={{
                    "& .MuiTableCell-stickyHeader": {
                      background: Theme.palette.primary.light,
                    },
                    "& .MuiTableCell-head": {
                      color: Theme.palette.text.primary,
                      padding: "16px 16px",
                      fontSize: "15px",
                      fontWeight: "600",
                      whiteSpace: "pre-line",
                    },
                    "& .MuiTableCell-root": {
                      padding: "10px 16px",
                    },
                  }}
                >
                  <MonthlyExpenseTableHead showDetailsView={showDetailsView} />
                  <MuiTableBody>
                    {loading && (
                      <MuiTableRow>
                        <MuiTableCell colSpan="9">
                          <MuiBox display="flex" justifyContent="center">
                            <MuiCircularProgress />
                          </MuiBox>
                        </MuiTableCell>
                      </MuiTableRow>
                    )}
                    {!loading && transferDetails && (
                      <MuiTableRow key={0}>
                        <MuiTableCell align="left" component="th" scope="row">
                          {transferDetails.glPeriod}
                        </MuiTableCell>
                        <MuiTableCell align="left">
                          {transferDetails.project}
                        </MuiTableCell>
                        <MuiTableCell align="left">
                          {transferDetails.award}
                        </MuiTableCell>
                        <MuiTableCell align="left">
                          {transferDetails.journalSource}
                        </MuiTableCell>
                        <MuiTableCell align="left">
                          {transferDetails.journalName}
                        </MuiTableCell>
                        <MuiTableCell align="left">
                          {transferDetails.journalLineDepartment}
                        </MuiTableCell>
                        <MuiTableCell align="left">
                          {transferDetails.objectCode}
                        </MuiTableCell>
                        {!showDetailsView && (
                          <MuiTableCell align="left">
                            {transferDetails.journalLineDescriptive}
                          </MuiTableCell>
                        )}
                        {showDetailsView && (
                          <MuiTableCell align="right"></MuiTableCell>
                        )}
                        {showDetailsView && (
                          <MuiTableCell align="right"></MuiTableCell>
                        )}
                      </MuiTableRow>
                    )}
                  </MuiTableBody>
                </MuiTable>
              </MuiTableContainer>
            </MuiGrid>
            {!showDetailsView && (
              <MuiGrid item xs={12}>
                <MuiGrid
                  container
                  justifyContent="flex-end"
                  direction="column"
                  spacing={1}
                >
                  <MuiGrid item>
                    <MuiTypography align="right" variant="body1">
                      {t(TransferTypes[transferDetails.transferType])}
                      {": "}
                      {CurrencyFormat(transferInAmount)}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item>
                    <MuiTypography align="right" variant="body1">
                      {t(`${commonTranslationString}.remainingToDistribute`)}
                      {": "}
                      {CurrencyFormat(remainingToDistribute)}
                    </MuiTypography>
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
            )}
            <MuiGrid item xs={12}>
              <MuiTableContainer
                component={MuiPaper}
                sx={{ maxHeight: "220px" }}
              >
                <MuiTable
                  aria-label="customized table"
                  stickyHeader
                  sx={{
                    "& .MuiTableCell-stickyHeader": {
                      background: Theme.palette.primary.light,
                    },
                    "& .MuiTableCell-head": {
                      color: Theme.palette.text.primary,
                      padding: "16px 16px",
                      fontSize: "15px",
                      fontWeight: "600",
                      whiteSpace: "pre-line",
                    },
                    "& .MuiTableCell-root": {
                      padding: "10px 16px",
                    },
                  }}
                >
                  <ComponentTableHead showDetailsView={showDetailsView} />
                  <MuiTableBody>
                    {loading && (
                      <MuiTableRow>
                        <MuiTableCell colSpan="5">
                          <MuiBox display="flex" justifyContent="center">
                            <MuiCircularProgress />
                          </MuiBox>
                        </MuiTableCell>
                      </MuiTableRow>
                    )}
                    {!loading &&
                      redistributeTransfersCount > 0 &&
                      redistributeTransfers.map((row, index) => (
                        <MuiTableRow key={index}>
                          {!showDetailsView && (
                            <MuiTableCell
                              align="left"
                              component="th"
                              scope="row"
                            >
                              {row.component.recordNumber}
                            </MuiTableCell>
                          )}
                          <MuiTableCell align="left">
                            {row.commitment && (
                              <RouterLink
                                style={{ textDecoration: "none" }}
                                to={{
                                  pathname:
                                    "/transfers/" +
                                    transferDetails.id +
                                    "/commitments/" +
                                    row.commitment.id +
                                    "/edit",
                                }}
                                target={"blank_" + row.commitment.id}
                              >
                                <MuiTooltip title={row.commitment.name}>
                                  <MuiTypography
                                    variant="body2"
                                    sx={{
                                      textOverflow: "ellipsis",
                                      width: "274px",
                                    }}
                                  >
                                    {row.commitment.name}
                                  </MuiTypography>
                                </MuiTooltip>
                              </RouterLink>
                            )}
                          </MuiTableCell>
                          <MuiTableCell align="left">
                            {row.component && (
                              <RouterLink
                                style={{ textDecoration: "none" }}
                                to={{
                                  pathname:
                                    "/transfers/" +
                                    transferDetails.id +
                                    "/commitments/" +
                                    row.commitment.id +
                                    "/components/" +
                                    row.component.id +
                                    "/edit",
                                }}
                                target={`${row.commitment.id}${row.component.id}`}
                              >
                                <MuiTooltip title={row.component.componentName}>
                                  <MuiTypography
                                    variant="body2"
                                    sx={{
                                      textOverflow: "ellipsis",
                                      width: "274px",
                                    }}
                                  >
                                    {row.component.componentName}
                                  </MuiTypography>
                                </MuiTooltip>
                              </RouterLink>
                            )}
                          </MuiTableCell>
                          <MuiTableCell align="left">
                            {row.pta && (
                              <RouterLink
                                style={{ textDecoration: "none" }}
                                to={{
                                  pathname:
                                    "/transfers/" +
                                    transferDetails.id +
                                    "/ptas/" +
                                    row.pta._links.self.href.split("/").pop(),
                                }}
                                target={
                                  "blank_" +
                                  row.pta._links.self.href.split("/").pop()
                                }
                              >
                                <MuiTypography
                                  variant="body2"
                                  sx={{
                                    textOverflow: "ellipsis",
                                    width: "200px",
                                  }}
                                >
                                  {row.ptaNumber}
                                </MuiTypography>
                              </RouterLink>
                            )}
                            {!row.pta && (
                              <MuiTypography variant="body2">
                                {!row.task &&
                                  `${row.project}-${"\u00A0\u00A0\u00A0"}-${
                                    row.award
                                  }`}
                                {row.task && row.ptaNumber}
                              </MuiTypography>
                            )}
                          </MuiTableCell>
                          <MuiTableCell>
                            {!showDetailsView && (
                              <NumericFormat
                                size="small"
                                thousandSeparator
                                decimalScale={2}
                                customInput={MuiOutlinedInput}
                                variant={"outlined"}
                                defaultValue={0.0}
                                isAllowed={(values) => {
                                  const { value } = values;
                                  return (
                                    value === "-" ||
                                    (value >= -99999999.99 &&
                                      value <= 99999999.99)
                                  );
                                }}
                                onValueChange={(values) => {
                                  const { value } = values;
                                  remainingRedistributeAmountcalculation(
                                    index,
                                    value,
                                    row.ptaNumber,
                                    row.component.componentName,
                                    row.component.id,
                                    row.commitment.name,
                                    row.commitment.id
                                  );
                                }}
                                fixedDecimalScale
                                autoComplete="off"
                                startAdornment={
                                  <MuiInputAdornment position="start">
                                    $
                                  </MuiInputAdornment>
                                }
                                sx={{ fontSize: "15px" }}
                              />
                            )}
                          </MuiTableCell>
                          {showDetailsView && (
                            <MuiTableCell align="right"></MuiTableCell>
                          )}
                        </MuiTableRow>
                      ))}
                  </MuiTableBody>
                </MuiTable>
                {!loading && redistributeTransfersCount === 0 && (
                  <MuiGrid
                    container
                    justifyContent="center"
                    sx={{
                      minHeight: "125px",
                      alignItems: "center",
                      color: Theme.palette.grey[500],
                    }}
                  >
                    <MuiTypography
                      variant="body1"
                      color="inherit"
                      align="center"
                      paragraph={false}
                    >
                      {t(
                        "UploadedExpenses.mainView.list.redistributeExpensesDialog.noComponentsMessage"
                      )}
                    </MuiTypography>
                  </MuiGrid>
                )}
              </MuiTableContainer>
            </MuiGrid>
          </MuiGrid>
        </MuiDialogContent>
        <MuiDialogActions>
          {!showDetailsView && (
            <MuiGrid
              container
              item
              justifyContent="flex-end"
              xs={3}
              spacing={2}
            >
              <FormButton
                cancel={handleClose}
                save={{
                  disabled:
                    parseFloat(transferInAmount) !==
                    parseFloat(totalDistributeAmount),
                }}
              />
            </MuiGrid>
          )}
          {showDetailsView && (
            <MuiButton
              variant="contained"
              color="primary"
              size="large"
              onClick={handleClose}
            >
              {t(`${commonTranslationString}.detailsPage.closeButtonText`)}
            </MuiButton>
          )}
        </MuiDialogActions>
      </form>
    </MuiDialog>
  );
};
