import { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  TextField as MuiTextField,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import { getPIOrComponentOwnerByMatch } from "app/services/componentsService";

export const ComponentOwnerField = ({
  value,
  onChange,
  setFieldError,
  labelText,
  helperText,
  error,
  isViewOnly = false,
  ...passedParams
}) => {
  const { t } = useTranslation();

  const shrinkProps = isViewOnly && { shrink: true };
  const label =
    labelText ??
    t(
      `Commitments.commitmentComponents.create.form.fields.piOrComponentOwner.label`
    );

  const [selectedValue, setSelectedValue] = useState(value);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  return (
    <MuiAutocomplete
      options={options || []}
      loading={loading}
      freeSolo
      loadingText={t(
        `Commitments.commitmentComponents.create.form.fieldDataLoadingText`
      )}
      getOptionLabel={(option) =>
        option.displayText ? option.displayText : ""
      }
      value={selectedValue}
      onChange={(event, val) => {
        setSelectedValue(val);
        onChange(event, val);
      }}
      onInputChange={(event, val) => {
        // to allow only on synthetic events
        if (event) {
          const cancelSource = axios.CancelToken.source();

          getPIOrComponentOwnerByMatch(
            val,
            () => {
              //onBeforeSend
              setFieldError(passedParams.id);
              setLoading(true);
            },
            () => {
              //onError
              setLoading(false);
            },
            (responseData) => {
              //onSuccess
              setOptions(responseData.values || []);
              setLoading(false);
            },
            cancelSource
          );

          onChange(event, val);
        }
      }}
      clearIcon={false}
      renderInput={(params) => {
        return (
          <MuiTextField
            {...params}
            label={label}
            variant={isViewOnly ? "standard" : "outlined"}
            InputLabelProps={{ ...shrinkProps }}
            helperText={helperText}
            error={error}
            InputProps={{
              ...params.InputProps,
              autoComplete: "off",
              notched: label?.length > 0 ? undefined : false,
              onBlur: (event) => {
                setSelectedValue({
                  displayText: event.target.value,
                });
              },
            }}
          />
        );
      }}
      {...passedParams}
    />
  );
};
