import { useEffect, useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import {
  Box as MuiBox,
  Grid as MuiGrid,
  Button as MuiButton,
  Checkbox as MuiCheckbox,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  Autocomplete as MuiAutocomplete,
  CircularProgress as MuiCircularProgress,
  FormControlLabel as MuiFormControlLabel,
} from "@mui/material";
import {
  NavigateNext as MuiNavigateNextIcon,
  CloudDownload as MuiCloudDownloadIcon,
  Done as DoneIcon,
} from "@mui/icons-material";
import { Theme, useAlerts, useUsers } from "common";
import {
  postDocument,
  putDocument,
  patchDocument,
  getDocumentById,
  getDocumentBrief,
} from "app/services/commitmentsService";
import { DocumentTypes } from "app/shared/constants";
import { useDownload } from "app/shared/ui/Download";
import { FormButton } from "app/shared/ui/FormButton";
import { DropZone } from "app/shared/ui/DropZone/DropZone";

export const CreateEditCommitmentDocumentForm = ({ pageType }) => {
  const { t } = useTranslation();
  const { currentUser } = useUsers();
  const { permissions } = currentUser;
  const navigate = useNavigate();
  const location = useLocation();
  const { getDocument, downloadInProgress } = useDownload();
  const {
    docVariant,
    documentType,
    documentName,
    documentNotes,
    departmentAdminView,
    acceptedFiles,
    dropzoneText,
  } = location.state || {};
  const params = useParams();
  const { clearAlert, setAlert } = useAlerts();
  const isNewHireTemplate = docVariant === "NEW_HIRE_EXCEL_TEMPLATE";
  const extraDropZoneProps = isNewHireTemplate
    ? { acceptedFiles, dropzoneText }
    : {};

  const handleClose = () => {
    if (documentType === "RECRUITMENT") {
      navigate(`/budgeting`);
    } else {
      navigate(`/commitments/${params.commitmentId}/documents`);
    }
  };

  const documentFormFieldsStr =
    "Commitments.commitmentDocuments.create.form.fields";
  const documentCreateStr = "Commitments.commitmentDocuments.create";
  const documentEditStr = "Commitments.commitmentDocuments.edit";
  const documentsMainView = "Commitments.commitmentDocuments.mainView";

  const [uploadedDocument, setUploadedDocument] = useState("");
  const [commitmentName, setCommitmentName] = useState("");

  /**
   * `formDataResponse` - Use to store the response of create/edit form data
   * `setFormDataResponse` - Use to set/modify the value of state/variable formDataResponse
   *
   */
  const [formDataResponse, setFormDataResponse] = useState({
    // Object keys use to create/edit form data
    documentType /* Document Type */: isNewHireTemplate ? documentType : null,
    documentName /* Document Name */: isNewHireTemplate ? documentName : "",
    documentNotes /* Notes */: isNewHireTemplate ? documentNotes : "",
    departmentAdminView /* Department Admin View */: isNewHireTemplate
      ? departmentAdminView
      : false,
    file /* Uploaded Document */: "",
    uploadedBy: "",
    uploadedDate: "",
  });

  const handleDocumentUpload = (files) => {
    files && files[0] && setUploadedDocument(files[0]);
  };

  const _handleSubmit = (values, setSubmitting, setFieldError) => {
    try {
      if (uploadedDocument instanceof File) {
        params.documentId
          ? putDocument(
              values,
              uploadedDocument,
              params.commitmentId,
              params.documentId,
              clearAlert,
              setAlert,
              t,
              setFieldError,
              navigate
            )
          : postDocument(
              values,
              uploadedDocument,
              params.commitmentId,
              clearAlert,
              setAlert,
              t,
              setFieldError,
              navigate
            );
      } else {
        params.documentId &&
          patchDocument(
            values,
            params.commitmentId,
            params.documentId,
            clearAlert,
            setAlert,
            t,
            setFieldError,
            navigate
          );
      }
    } catch (e) {}
  };

  const commitmentValidation = Yup.object().shape({
    documentType: Yup.string().required(),
    documentName: Yup.string().required(),
    documentNotes: Yup.string().when("documentType", {
      is: (val) => (val ? val === "OTHER" : false),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
    departmentAdminView: Yup.boolean(),
    file: Yup.mixed().required(),
  });

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    clearAlert();
    params.commitmentId &&
      getDocumentBrief(
        params.commitmentId,
        setCommitmentName,
        setAlert,
        clearAlert,
        cancelSource
      );
    // eslint-disable-next-line
  }, [params.CommitmentId]);

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    params.documentId &&
      getDocumentById(
        params.commitmentId,
        params.documentId,
        setFormDataResponse,
        setCommitmentName,
        setUploadedDocument,
        setAlert,
        clearAlert,
        cancelSource
      );

    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [params.CommitmentId, params.documentId]);

  const getMainHeadingTxt = () => {
    let txt;
    if (pageType === "new") {
      if (isNewHireTemplate) {
        txt = t(`${documentCreateStr}.newHireTemplate`);
      } else {
        txt = t(`${documentCreateStr}.title`);
      }
    } else if (pageType === "edit") {
      if (isNewHireTemplate) {
        txt = t(`${documentEditStr}.newHireTemplate`);
      } else {
        txt = t(`${documentEditStr}.title`);
      }
    }
    return txt + ": " + commitmentName;
  };

  return (
    ((pageType === "new" &&
      (permissions.ADD_DOCUMENTS ||
        permissions.UPLOAD_COMPLETED_NEW_HIRE_EXCEL_TEMPLATE)) ||
      (pageType === "edit" &&
        (permissions.EDIT_DOCUMENTS ||
          permissions.UPLOAD_COMPLETED_NEW_HIRE_EXCEL_TEMPLATE))) && (
      <MuiGrid container>
        <MuiGrid item xs={12}>
          <MuiBreadcrumbs
            separator={<MuiNavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <RouterLink style={{ textDecoration: "none" }} to={`/commitments`}>
              <MuiTypography color="textSecondary" variant="body2">
                {t("Commitments.mainView.title")}
              </MuiTypography>
            </RouterLink>
            <RouterLink
              style={{ textDecoration: "none" }}
              to={`/commitments/${params.commitmentId}/documents`}
            >
              <MuiTypography color="textSecondary" variant="body2">
                {t(`${documentsMainView}.documentsBreadCrumbLink`)}
              </MuiTypography>
            </RouterLink>
            <MuiTypography color="textPrimary" variant="subtitle1">
              {pageType === "new"
                ? t(`${documentCreateStr}.breadcrumbTitle`)
                : t(`${documentEditStr}.breadcrumbTitle`)}
            </MuiTypography>
          </MuiBreadcrumbs>
        </MuiGrid>
        <MuiGrid item xs={12} sx={{ padding: "8px 0" }}>
          <MuiTypography variant="h1">{getMainHeadingTxt()}</MuiTypography>
        </MuiGrid>

        {/* Formik - Wrapper of library `Formik` which set/reset/submit form values
        to create/edit a commitment */}
        <Formik
          // initialValues - User to store the Formik form's initial form values
          /** !Object */ initialValues={formDataResponse}
          /** !Boolean */ enableReinitialize
          // onSubmit - Callback definition to execute on the click of Form Submit
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            _handleSubmit(values, setSubmitting, setFieldError);
          }}
          validationSchema={commitmentValidation}
        >
          {(formikProps) => {
            const /** !Object */ {
                values,
                handleChange,
                setFieldValue,
                dirty,
                isValid,
                handleSubmit,
              } = formikProps;
            return (
              // Native form element to submit the form values
              <MuiGrid item xs={12}>
                <form onSubmit={handleSubmit}>
                  <MuiGrid item container xs={12} gap="8px">
                    {!isNewHireTemplate && (
                      <>
                        <MuiGrid item xs={12} md={5}>
                          <MuiAutocomplete
                            id="documentType"
                            name="documentType"
                            options={Object.values(DocumentTypes).filter(
                              (x) => x !== DocumentTypes["RECRUITMENT"]
                            )}
                            getOptionLabel={(option) => t(option)}
                            value={
                              values.documentType &&
                              t(DocumentTypes[values.documentType])
                            }
                            onChange={(event, valueObj) => {
                              setFieldValue(
                                "documentType",
                                Object.keys(DocumentTypes).find(
                                  (key) => DocumentTypes[key] === valueObj
                                )
                              );
                            }}
                            clearIcon={false}
                            renderInput={(args) => (
                              <MuiTextField
                                {...args}
                                label={t(
                                  `${documentFormFieldsStr}.documentType.label`
                                )}
                                required
                                variant="outlined"
                              />
                            )}
                          />
                        </MuiGrid>
                        <MuiGrid item md={7} />
                        <MuiGrid item xs={12} md={5}>
                          {/* Document Name - MuiTextField Input */}
                          <MuiTextField
                            id="documentName"
                            label={t(
                              `${documentFormFieldsStr}.documentName.label`
                            )}
                            required
                            value={values.documentName}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                          />
                        </MuiGrid>
                        <MuiGrid item md={7} />
                        <MuiGrid item xs={12} md={5}>
                          {/* Notes - Multiline Textfield Input */}
                          <MuiTextField
                            id="documentNotes"
                            label={t(
                              `${documentFormFieldsStr}.documentNotes.label`
                            )}
                            required={
                              values.documentType === "OTHER" ? true : false
                            }
                            value={values.documentNotes}
                            onChange={(event) => {
                              setFieldValue(
                                "documentNotes",
                                event.target.value
                              );
                            }}
                            multiline
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                          />
                        </MuiGrid>
                        <MuiGrid item md={7} />
                        <MuiGrid item xs={12} md={5}>
                          {/* Department Admin View - Checkbox Input */}
                          <MuiFormControlLabel
                            label={t(
                              `${documentFormFieldsStr}.departmentAdminView.label`
                            )}
                            control={
                              <MuiCheckbox
                                id="departmentAdminView"
                                color="default"
                                sx={{ color: Theme.palette.text.primary }}
                                checked={values.departmentAdminView}
                                onChange={(event) => {
                                  setFieldValue(
                                    "departmentAdminView",
                                    event.target.checked
                                  );
                                }}
                              />
                            }
                          />
                        </MuiGrid>
                        <MuiGrid item md={7} />
                      </>
                    )}
                    <MuiGrid item xs={12} md={5}>
                      <DropZone
                        handleDocumentUpload={handleDocumentUpload}
                        handleDrop={() => {
                          setFieldValue("file", handleDocumentUpload);
                        }}
                        {...extraDropZoneProps}
                      />
                      <MuiBox sx={{ color: Theme.palette.error.main }}>
                        <ErrorMessage name="file" />
                      </MuiBox>
                      {/* Display the uploaded file */}
                      <MuiBox mt={1}>
                        <MuiGrid container>
                          {uploadedDocument?.name && (
                            <MuiGrid
                              container
                              item
                              xs={12}
                              md={8}
                              justifyContent="flex-start"
                            >
                              <DoneIcon />
                              <MuiTypography component="span" variant="body2">
                                {uploadedDocument.name
                                  .split(".")[0]
                                  .substring(0, 40) +
                                  (uploadedDocument.name.length > 40
                                    ? "... ."
                                    : ".") +
                                  uploadedDocument.name.split(".").pop()}
                              </MuiTypography>
                            </MuiGrid>
                          )}
                          {formDataResponse?.file && (
                            <MuiGrid
                              container
                              item
                              xs={12}
                              md={4}
                              justifyContent="flex-end"
                            >
                              <MuiButton
                                variant="text"
                                color="inherit"
                                sx={{
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  whiteSpace: "nowrap",
                                  lineHeight: "22.5px",
                                }}
                                startIcon={
                                  downloadInProgress ? (
                                    <MuiCircularProgress size={15} />
                                  ) : (
                                    <MuiCloudDownloadIcon />
                                  )
                                }
                                disabled={!!downloadInProgress}
                                onClick={() => {
                                  getDocument(
                                    `/commitments/${params.commitmentId}/documents/${params.documentId}/download`,
                                    uploadedDocument?.name
                                  );
                                }}
                              >
                                {t(
                                  "Commitments.commitmentDocuments.mainView.list.downloadCommitmentDocumentsTooltip"
                                )}
                              </MuiButton>
                            </MuiGrid>
                          )}
                        </MuiGrid>
                      </MuiBox>
                    </MuiGrid>
                    <MuiGrid item md={7} />
                    {formDataResponse?.uploadedBy && (
                      <>
                        <MuiGrid item xs={12} md={5}>
                          <MuiTypography variant="caption">
                            <MuiTypography
                              component="span"
                              variant="caption"
                              color="textSecondary"
                            >
                              {t(
                                `${documentsMainView}.list.tableColumns.uploadedBy`
                              )}{" "}
                            </MuiTypography>
                            {formDataResponse.uploadedBy}
                            <MuiTypography
                              component="span"
                              variant="caption"
                              color="textSecondary"
                            >
                              {" "}
                              {t(
                                "UploadedExpenses.mainView.list.notificationStatusTooltip.on"
                              )}{" "}
                            </MuiTypography>
                            {formDataResponse.uploadedDate}
                          </MuiTypography>
                        </MuiGrid>
                        <MuiGrid item md={7} />
                      </>
                    )}
                    <MuiGrid item xs={12} md={5}>
                      <MuiGrid item md={12} container justifyContent="flex-end">
                        <MuiGrid
                          container
                          item
                          justifyContent="flex-end"
                          md={6}
                          spacing={2}
                        >
                          <FormButton
                            cancel={handleClose}
                            save={{ disabled: !dirty || !isValid }}
                          />
                        </MuiGrid>
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid item md={7} />
                  </MuiGrid>
                </form>
              </MuiGrid>
            );
          }}
        </Formik>
      </MuiGrid>
    )
  );
};
