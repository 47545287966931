import { useTranslation } from "react-i18next";
import {
  Box as MuiBox,
  TableRow as MuiTableRow,
  TableHead as MuiTableHead,
  TableCell as MuiTableCell,
} from "@mui/material";

export const FinancialHistoryFiscalYearTableHead = () => {
  const { t } = useTranslation();

  const tableColumnsStr =
    "Commitments.financialHistory.mainView.list.fiscalYearTable.tableColumns";

  return (
    <MuiTableHead>
      <MuiTableRow
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "unset",
          },
        }}
      >
        <MuiTableCell />
        <MuiTableCell />
        <MuiTableCell align="center" key="actuals" colSpan={4}>
          <MuiBox borderBottom={1} ml={2} height={30}>
            {t(`${tableColumnsStr}.actuals`)}
          </MuiBox>
        </MuiTableCell>

        <MuiTableCell align="center" key="planning" colSpan={4}>
          <MuiBox borderBottom={1} ml={4} height={30}>
            {t(`${tableColumnsStr}.planning`)}
          </MuiBox>
        </MuiTableCell>
        <MuiTableCell />
      </MuiTableRow>
      <MuiTableRow>
        <MuiTableCell width="2%"></MuiTableCell>
        <MuiTableCell align="left" key="fiscalYear" width="6%">
          {t(`${tableColumnsStr}.fiscalYear`)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fytdExpenseAmt" width="10%">
          {t(`${tableColumnsStr}.fytdExpenseAmt`)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fytdTransferAmt" width="12%">
          {t(`${tableColumnsStr}.fytdTransferAmt`)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fytdActualAmt" width="12%">
          {t(`${tableColumnsStr}.fytdActualAmt`)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fySpendCap" width="10%">
          {t(`${tableColumnsStr}.fySpendCap`)}
        </MuiTableCell>

        <MuiTableCell align="right" key="yepAmt" width="8%">
          {t(`${tableColumnsStr}.yepAmt`)}
        </MuiTableCell>
        <MuiTableCell align="right" key="budgetAmt" width="8%">
          {t(`${tableColumnsStr}.budgetAmt`)}
        </MuiTableCell>
        <MuiTableCell align="right" key="planAmt" width="8%">
          {t(`${tableColumnsStr}.planAmt`)}
        </MuiTableCell>
        <MuiTableCell align="right" key="lrff" width="8%">
          {t(`${tableColumnsStr}.lrff`)}
        </MuiTableCell>
        <MuiTableCell align="center" key="actions" width="5%">
          {t(`${tableColumnsStr}.actions`)}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};
