import { useEffect } from "react";
import axios from "axios";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  Tooltip as MuiTooltip,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
  IconButton as MuiIconButton,
  OutlinedInput as MuiOutlinedInput,
} from "@mui/material";
import {
  Check as MuiCheckIcon,
  Close as MuiCloseIcon,
  NewReleases as MuiNewReleasesIcon,
  KeyboardArrowUp as MuiKeyboardArrowUpIcon,
  KeyboardArrowDown as MuiKeyboardArrowDownIcon,
} from "@mui/icons-material";
import { CurrencyFormat } from "app/shared/ui/CurrencyFormat";
import { patchCommitmentRequest } from "app/services/requestsService";
import { Theme } from "common";

export const CommitmentRequestTableRowForm = ({
  commitment,
  setLoading,
  pushSnackbarMessage,
  formData,
  setFormData,
  setRefresh,
  setInlineEditRow,
  isDrawerOpen,
  setDrawerOpen,
  setOpenCommitmentId,
}) => {
  const { t } = useTranslation();

  const onBeforeSend = () => {
    setLoading(true);
  };

  const onError = (error) => {
    if (!axios.isCancel(error)) {
      setLoading(false);
      pushSnackbarMessage("error", error.message);
    }
  };
  const onSuccess = (values) => {
    setLoading(false);
    pushSnackbarMessage(
      "success",
      t("Commitments.create.notification.update", {
        commitmentName: `"${commitment.name.trim()}"`,
      })
    );
    setFormData(values);
    setRefresh((value) => value + 1);
    setInlineEditRow(false);
  };

  const saveComponent = (values) => {
    const cancelSource = axios.CancelToken.source();
    patchCommitmentRequest(
      values,
      commitment.id,
      onBeforeSend,
      onError,
      onSuccess,
      cancelSource
    );
  };

  // Cancel out of inline editing with the ESC key
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setInlineEditRow(false);
      }
    };
    window.addEventListener("keydown", handleEsc, false);
    return () => {
      window.removeEventListener("keydown", handleEsc, false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Formik initialValues={formData} enableReinitialize>
      {(formikProps) => {
        const { values, setFieldValue, dirty, isValid } = formikProps;
        return (
          <>
            {/* Drawer Buttons */}
            <MuiTableCell>
              {commitment?.components?.length > 0 && (
                <MuiIconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setDrawerOpen(!isDrawerOpen);
                    setOpenCommitmentId(commitment.id);
                  }}
                >
                  {!isDrawerOpen && <MuiKeyboardArrowDownIcon />}
                  {isDrawerOpen && <MuiKeyboardArrowUpIcon />}
                </MuiIconButton>
              )}
            </MuiTableCell>

            {/* Departments */}
            <MuiTableCell align="left">
              {commitment.departmentName && (
                <MuiTooltip title={commitment.departmentName} placement="left">
                  <MuiTypography variant="body2">
                    {commitment.departmentName}
                  </MuiTypography>
                </MuiTooltip>
              )}
            </MuiTableCell>
            {/* Commitment Name */}
            <MuiTableCell align="left">
              <MuiTypography variant="body2">
                {commitment.commitmentType === "REQUEST" && (
                  <MuiTooltip title={t(`Budgeting.mainView.newRequest`)}>
                    <MuiNewReleasesIcon
                      size="small"
                      sx={{
                        color: Theme.palette.warning.main,
                        verticalAlign: "text-bottom",
                        marginRight: "5px",
                      }}
                    />
                  </MuiTooltip>
                )}
                <MuiTooltip title={commitment.name} placement="left">
                  <span>{commitment.name}</span>
                </MuiTooltip>
              </MuiTypography>
            </MuiTableCell>

            {/* Amounts */}
            <MuiTableCell align="right">
              {CurrencyFormat(commitment.totalCommitmentAmountRequested)}
            </MuiTableCell>
            <MuiTableCell align="right">
              {CurrencyFormat(commitment.totalCommitmentAmountApproved)}
            </MuiTableCell>
            <MuiTableCell align="right">
              {CurrencyFormat(commitment.fyProjectionOne)}
            </MuiTableCell>
            <MuiTableCell align="right">
              {CurrencyFormat(commitment.fyBudget)}
            </MuiTableCell>

            {/* Note Columns */}
            <MuiTableCell align="left">
              <MuiOutlinedInput
                size="small"
                id="controllerNotes"
                multiline
                fullWidth
                value={values?.controllerNotes}
                onChange={(event) => {
                  setFieldValue("controllerNotes", event.target.value);
                }}
                inputProps={{
                  maxLength: 1000,
                }}
                autoFocus
                sx={{ fontSize: "15px", padding: "8px" }}
              />
            </MuiTableCell>
            <MuiTableCell align="left">
              <MuiOutlinedInput
                size="small"
                id="controllerComments"
                multiline
                fullWidth
                value={values?.controllerComments}
                onChange={(event) => {
                  setFieldValue("controllerComments", event.target.value);
                }}
                inputProps={{
                  maxLength: 1000,
                }}
                sx={{ fontSize: "15px", padding: "8px" }}
              />
            </MuiTableCell>

            {/* Actions */}
            <MuiTableCell align="left">
              <MuiIconButton
                size="small"
                onClick={() => {
                  if (dirty && isValid) {
                    saveComponent(values);
                  }
                }}
              >
                <MuiCheckIcon size="small" />
              </MuiIconButton>
              <MuiIconButton
                size="small"
                onClick={() => {
                  setInlineEditRow(false);
                }}
              >
                <MuiCloseIcon size="small" />
              </MuiIconButton>
            </MuiTableCell>
          </>
        );
      }}
    </Formik>
  );
};
