import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableFooter as MuiTableFooter,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CurrencyFormat } from "app/shared/ui/CurrencyFormat";
import { Theme, useUsers } from "common";

export const CommitmentRequestTableFooter = ({ totals, budgetFiscalYear }) => {
  const { t } = useTranslation();
  const { currentUser } = useUsers();
  const { roles } = currentUser;

  const isCommitmentAdmin = roles.includes("COMMITMENT_ADMIN");

  const footerCellStyles = {
    "&.MuiTableCell-footer": {
      borderBottomStyle: "none",
      backgroundColor: Theme.palette.primary.light,
      color: Theme.palette.text.primary,
    },
  };

  return (
    <MuiTableFooter>
      <MuiTableRow>
        <MuiTableCell colSpan={3} sx={footerCellStyles}></MuiTableCell>
        <MuiTableCell align="right" sx={footerCellStyles}>
          {t(`Requests.mainView.list.tableColumns.totalCommitmentRequested`)}
        </MuiTableCell>
        <MuiTableCell align="right" sx={footerCellStyles}>
          {t(`Requests.mainView.list.tableColumns.totalCommitmentApproved`)}
        </MuiTableCell>

        <MuiTableCell align="right" sx={footerCellStyles}>
          {t(`Requests.mainView.list.tableColumns.totalFyProjection`, {
            fyProjection: (parseInt(budgetFiscalYear, 10) % 100) - 1,
          })}
        </MuiTableCell>
        <MuiTableCell align="right" sx={footerCellStyles}>
          {t(`Requests.mainView.list.tableColumns.totalFyBudget`, {
            fyBudgetOne: parseInt(budgetFiscalYear, 10) % 100,
          })}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="fyActualsOne"
          sx={footerCellStyles}
          colSpan={isCommitmentAdmin ? 3 : 2}
        ></MuiTableCell>
      </MuiTableRow>
      <MuiTableRow>
        <MuiTableCell align="right" sx={footerCellStyles} colSpan={3}>
          {t(`Requests.mainView.list.tableColumns.grandTotal`)}
        </MuiTableCell>
        <MuiTableCell align="right" key="totalCommitment" sx={footerCellStyles}>
          {getAmount(totals?.totalRequested)}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="fYTotalActualsThree"
          sx={footerCellStyles}
        >
          {getAmount(totals?.totalApproved)}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="fYTotalActualsTwo"
          sx={footerCellStyles}
        >
          {getAmount(totals?.totalFyProjection)}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="totalExpendituresTotal"
          sx={footerCellStyles}
        >
          {getAmount(totals?.totalFyBudget)}
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="actions"
          sx={footerCellStyles}
          colSpan={isCommitmentAdmin ? 3 : 2}
        ></MuiTableCell>
      </MuiTableRow>
    </MuiTableFooter>
  );
};

const getAmount = (amount) => {
  const roundupFloat = Math.round(amount);
  return CurrencyFormat(isNaN(roundupFloat) ? 0 : roundupFloat);
};
