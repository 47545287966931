import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { Link as RouterLink } from "react-router-dom";
import {
  Box as MuiBox,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Table as MuiTable,
  Button as MuiButton,
  Dialog as MuiDialog,
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  Typography as MuiTypography,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  OutlinedInput as MuiOutlinedInput,
  TableContainer as MuiTableContainer,
  InputAdornment as MuiInputAdornment,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import { Theme, useAlerts } from "common";
import {
  getRedistributeExpenses,
  postRedistributeExpenses,
} from "app/services/expensesService";
import { FormButton } from "app/shared/ui/FormButton";
import { CurrencyFormat } from "app/shared/ui/CurrencyFormat";

const MonthlyExpenseTableHead = ({ showDetailsView }) => {
  const { t } = useTranslation();

  const tableColumnsStr =
    "UploadedExpenses.mainView.list.redistributeExpensesDialog.tableColumns.montlyExpenseDetail";
  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell align="left" key="glPeriod" width="28%">
          {showDetailsView &&
            `${t(`${tableColumnsStr}.source`)} \n ${t(
              `${tableColumnsStr}.sourceGlPeriod`
            )}`}
          {!showDetailsView && t(`${tableColumnsStr}.glPeriod`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="pta" width="28%">
          {t(`${tableColumnsStr}.pta`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="expenditureCategory" width="24%">
          {t(`${tableColumnsStr}.expenditureCategory`)}
        </MuiTableCell>
        {showDetailsView && (
          <MuiTableCell align="left" key="redistributedAmount" width="24%">
            {t(
              "UploadedExpenses.mainView.list.redistributeExpensesDialog.tableColumns.componentDetail.redistributedAmount"
            )}
          </MuiTableCell>
        )}
        <MuiTableCell align="right" key="mtdAmount" width="20%">
          {t(`${tableColumnsStr}.mtdAmount`)}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};

const ComponentTableHead = ({ showDetailsView }) => {
  const { t } = useTranslation();

  const tableColumnsStr =
    "UploadedExpenses.mainView.list.redistributeExpensesDialog.tableColumns.componentDetail";
  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell align="left" key="commitmentName" width="28%">
          {showDetailsView && `${t(`${tableColumnsStr}.destination`)}\n`}
          {t(`${tableColumnsStr}.commitmentName`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="componentName" width="28%">
          {t(`${tableColumnsStr}.componentName`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="pta" width="24%">
          {t(`${tableColumnsStr}.pta`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="redistributedAmount" width="20%">
          {t(`${tableColumnsStr}.redistributedAmount`)}
        </MuiTableCell>
        {showDetailsView && (
          <MuiTableCell align="right" key="mtdAmount" width="20%">
            {t(
              "UploadedExpenses.mainView.list.redistributeExpensesDialog.tableColumns.montlyExpenseDetail.mtdAmount"
            )}
          </MuiTableCell>
        )}
      </MuiTableRow>
    </MuiTableHead>
  );
};

export const RedistributeExpenses = ({
  openDialog,
  setOpenDialog,
  expenseIdToRedistribute,
  setExpenses,
  expenseIndex,
  showDetailsView,
}) => {
  const { t } = useTranslation();
  const [redistributeExpenses, setRedistributeExpenses] = useState([]);
  const [monthlyExpenseDetails, setMonthlyExpenseDetails] = useState([]);
  const [redistributeExpensesCount, setRedistributeExpensesCount] = useState();
  const [remainingToDistribute, setRemainingToDistribute] = useState(0);
  const [mtdAmount, setMTDAmount] = useState(0);
  const [saveRedistributeExpenses, setSaveRedistributeExpenses] = useState([]);
  const [totalDistributeAmount, setTotalDistributeAmount] = useState(0);

  const [loading, setLoading] = useState(false);
  const { clearAlert, setAlert } = useAlerts();
  const handleClose = () => {
    setOpenDialog(false);
  };

  const UploadedExpensesStr = "UploadedExpenses.mainView.list";

  const handleSubmit = () => {
    setOpenDialog(false);
    postRedistributeExpenses(
      monthlyExpenseDetails.id,
      saveRedistributeExpenses,
      setExpenses,
      expenseIndex,
      clearAlert,
      setAlert,
      t
    );
  };

  const remainingRedistributeAmountcalculation = (
    index,
    redistributedAmount,
    ptaNumber,
    componentName,
    componentId,
    commitmentName,
    commitmentId
  ) => {
    saveRedistributeExpenses[index] =
      redistributedAmount !== ""
        ? {
            ptaNumber: ptaNumber,
            redistributedAmount: redistributedAmount,
            componentName: componentName,
            componentId: componentId,
            commitmentName: commitmentName,
            commitmentId: commitmentId,
          }
        : null;

    const totalAmount = saveRedistributeExpenses
      .filter((key) => key !== null && key.redistributedAmount !== "-")
      .reduce(
        (totalAmount, redistributeExpenses) =>
          totalAmount + parseFloat(redistributeExpenses.redistributedAmount),
        0
      );
    setTotalDistributeAmount(totalAmount);
    setRemainingToDistribute(mtdAmount - totalAmount);
  };

  useEffect(() => {
    getRedistributeExpenses(
      setMonthlyExpenseDetails,
      setRedistributeExpenses,
      setRedistributeExpensesCount,
      setSaveRedistributeExpenses,
      setRemainingToDistribute,
      setMTDAmount,
      setLoading,
      clearAlert,
      setAlert,
      t,
      {
        expenseId: expenseIdToRedistribute,
      }
    );
    // eslint-disable-next-line
  }, [expenseIdToRedistribute]);
  return (
    <MuiDialog
      open={openDialog}
      aria-labelledby="form-dialog-title"
      fullWidth
      PaperProps={{
        sx: {
          width: "1048px",
          maxWidth: "100%",
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <MuiDialogTitle id="form-dialog-title">
          {!showDetailsView &&
            t(`${UploadedExpensesStr}.redistributeExpensesDialog.title`)}
          {showDetailsView && t(`${UploadedExpensesStr}.redistributedToolTip`)}
        </MuiDialogTitle>

        <MuiDialogContent>
          <MuiGrid
            container
            justifyContent="flex-end"
            direction="column"
            spacing={1}
            wrap="nowrap"
          >
            <MuiGrid item xs={12}>
              <MuiTableContainer component={MuiPaper}>
                <MuiTable
                  aria-label="customized table"
                  stickyHeader
                  sx={{
                    "& .MuiTableCell-stickyHeader": {
                      background: Theme.palette.primary.light,
                    },
                    "& .MuiTableCell-head": {
                      color: Theme.palette.text.primary,
                      padding: "16px 16px",
                      fontSize: "15px",
                      fontWeight: "600",
                      whiteSpace: "pre-line",
                    },
                    "& .MuiTableCell-root": {
                      padding: "10px 16px",
                    },
                  }}
                >
                  <MonthlyExpenseTableHead showDetailsView={showDetailsView} />
                  <MuiTableBody>
                    {loading && (
                      <MuiTableRow>
                        <MuiTableCell colSpan="4">
                          <MuiBox display="flex" justifyContent="center">
                            <MuiCircularProgress />
                          </MuiBox>
                        </MuiTableCell>
                      </MuiTableRow>
                    )}
                    {!loading && monthlyExpenseDetails && (
                      <MuiTableRow key={0}>
                        <MuiTableCell align="left" component="th" scope="row">
                          {monthlyExpenseDetails.glPeriod}
                        </MuiTableCell>
                        <MuiTableCell align="left">
                          {monthlyExpenseDetails.pta && (
                            <RouterLink
                              style={{ textDecoration: "none" }}
                              to={{
                                pathname:
                                  "/expenses/" +
                                  monthlyExpenseDetails.id +
                                  "/ptas/" +
                                  monthlyExpenseDetails.ptaNumber,
                              }}
                              target={
                                "blank_" + monthlyExpenseDetails.ptaNumber
                              }
                            >
                              <MuiTypography variant="body2">
                                {monthlyExpenseDetails.ptaNumber}
                              </MuiTypography>
                            </RouterLink>
                          )}
                          {!monthlyExpenseDetails.pta && (
                            <MuiTypography variant="body2">
                              {!monthlyExpenseDetails.task &&
                                `${
                                  monthlyExpenseDetails.project
                                }-${"\u00A0\u00A0\u00A0"}-${
                                  monthlyExpenseDetails.award
                                }`}
                              {monthlyExpenseDetails.task &&
                                monthlyExpenseDetails.ptaNumber}
                            </MuiTypography>
                          )}
                        </MuiTableCell>
                        <MuiTableCell align="left">
                          {monthlyExpenseDetails.expenditureCategory}
                        </MuiTableCell>
                        {showDetailsView && (
                          <MuiTableCell align="right"></MuiTableCell>
                        )}
                        <MuiTableCell align="right">
                          {CurrencyFormat(mtdAmount)}
                        </MuiTableCell>
                      </MuiTableRow>
                    )}
                  </MuiTableBody>
                </MuiTable>
              </MuiTableContainer>
            </MuiGrid>
            {!showDetailsView && (
              <MuiGrid
                item
                xs={12}
                sx={{
                  marginRight: "15px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <MuiTypography align="right">
                  {t(
                    `${UploadedExpensesStr}.redistributeExpensesDialog.remainingToDistribute`
                  )}
                  {": "}
                  {CurrencyFormat(remainingToDistribute)}
                </MuiTypography>
              </MuiGrid>
            )}
            <MuiGrid item xs={12}>
              <MuiTableContainer
                component={MuiPaper}
                sx={{ maxHeight: "220px" }}
              >
                <MuiTable
                  aria-label="customized table"
                  stickyHeader
                  sx={{
                    "& .MuiTableCell-stickyHeader": {
                      background: Theme.palette.primary.light,
                    },
                    "& .MuiTableCell-head": {
                      color: Theme.palette.text.primary,
                      padding: "16px 16px",
                      fontSize: "15px",
                      fontWeight: "600",
                      whiteSpace: "pre-line",
                    },
                    "& .MuiTableCell-root": {
                      padding: "10px 16px",
                    },
                  }}
                >
                  <ComponentTableHead showDetailsView={showDetailsView} />
                  <MuiTableBody>
                    {loading && (
                      <MuiTableRow>
                        <MuiTableCell colSpan="4">
                          <MuiBox display="flex" justifyContent="center">
                            <MuiCircularProgress />
                          </MuiBox>
                        </MuiTableCell>
                      </MuiTableRow>
                    )}
                    {!loading &&
                      redistributeExpensesCount > 0 &&
                      redistributeExpenses.map((row, index) => (
                        <MuiTableRow key={index}>
                          <MuiTableCell align="left" component="th" scope="row">
                            {row.commitment && (
                              <RouterLink
                                style={{ textDecoration: "none" }}
                                to={{
                                  pathname:
                                    "/expenses/" +
                                    monthlyExpenseDetails.id +
                                    "/commitments/" +
                                    row.commitment.id +
                                    "/edit",
                                }}
                                target={"blank_" + row.commitment.id}
                              >
                                <MuiTooltip title={row.commitment.name}>
                                  <MuiTypography variant="body2">
                                    {row.commitment.name}
                                  </MuiTypography>
                                </MuiTooltip>
                              </RouterLink>
                            )}
                          </MuiTableCell>
                          <MuiTableCell align="left">
                            {row.component && (
                              <RouterLink
                                style={{ textDecoration: "none" }}
                                to={{
                                  pathname:
                                    "/expenses/" +
                                    monthlyExpenseDetails.id +
                                    "/commitments/" +
                                    row.commitment.id +
                                    "/components/" +
                                    row.component.id +
                                    "/edit",
                                }}
                                target={`${row.commitment.id}${row.component.id}`}
                              >
                                <MuiTooltip title={row.component.componentName}>
                                  <MuiTypography variant="body2">
                                    {row.component.componentName}
                                  </MuiTypography>
                                </MuiTooltip>
                              </RouterLink>
                            )}
                          </MuiTableCell>
                          <MuiTableCell align="left">
                            {row.pta && (
                              <RouterLink
                                style={{ textDecoration: "none" }}
                                to={{
                                  pathname:
                                    "/expenses/" +
                                    monthlyExpenseDetails.id +
                                    "/ptas/" +
                                    row.ptaNumber,
                                }}
                                target={"blank_" + row.ptaNumber}
                              >
                                <MuiTypography variant="body2">
                                  {row.ptaNumber}
                                </MuiTypography>
                              </RouterLink>
                            )}
                            {!row.pta && (
                              <MuiTypography variant="body2">
                                {!row.task &&
                                  `${row.project}-${"\u00A0\u00A0\u00A0"}-${
                                    row.award
                                  }`}
                                {row.task && row.ptaNumber}
                              </MuiTypography>
                            )}
                          </MuiTableCell>
                          <MuiTableCell>
                            {!showDetailsView && (
                              <NumericFormat
                                size="small"
                                thousandSeparator
                                decimalScale={2}
                                customInput={MuiOutlinedInput}
                                variant={"outlined"}
                                defaultValue={0.0}
                                isAllowed={(values) => {
                                  const { value } = values;
                                  return (
                                    value === "-" ||
                                    (value >= -999999.99 && value <= 999999.99)
                                  );
                                }}
                                onValueChange={(values) => {
                                  const { value } = values;
                                  remainingRedistributeAmountcalculation(
                                    index,
                                    value,
                                    row.ptaNumber,
                                    row.component.componentName,
                                    row.component.id,
                                    row.commitment.name,
                                    row.commitment.id
                                  );
                                }}
                                fixedDecimalScale
                                autoComplete="off"
                                allowNegative
                                startAdornment={
                                  <MuiInputAdornment position="start">
                                    $
                                  </MuiInputAdornment>
                                }
                              />
                            )}
                          </MuiTableCell>
                          {showDetailsView && (
                            <MuiTableCell align="right"></MuiTableCell>
                          )}
                        </MuiTableRow>
                      ))}
                  </MuiTableBody>
                </MuiTable>
                {!loading && redistributeExpensesCount === 0 && (
                  <MuiGrid
                    container
                    justifyContent="center"
                    sx={{
                      minHeight: "125px",
                      alignItems: "center",
                      color: Theme.palette.grey[500],
                    }}
                  >
                    <MuiTypography
                      variant="body1"
                      color="inherit"
                      align="center"
                      paragraph={false}
                    >
                      {t(
                        `${UploadedExpensesStr}.redistributeExpensesDialog.noComponentsMessage`
                      )}
                    </MuiTypography>
                  </MuiGrid>
                )}
              </MuiTableContainer>
            </MuiGrid>
          </MuiGrid>
        </MuiDialogContent>
        <MuiDialogActions>
          {!showDetailsView && (
            <MuiGrid
              container
              item
              justifyContent="flex-end"
              xs={3}
              spacing={2}
            >
              <FormButton
                cancel={handleClose}
                save={{
                  disabled:
                    parseFloat(mtdAmount) !== parseFloat(totalDistributeAmount),
                }}
              />
            </MuiGrid>
          )}
          {showDetailsView && (
            <MuiButton
              variant="contained"
              color="primary"
              size="large"
              onClick={handleClose}
            >
              {t(
                `${UploadedExpensesStr}.redistributeExpensesDialog.closebutton.label`
              )}
            </MuiButton>
          )}
        </MuiDialogActions>
      </form>
    </MuiDialog>
  );
};
