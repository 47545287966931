import {
  Box as MuiBox,
  Table as MuiTable,
  Collapse as MuiCollapse,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
} from "@mui/material";
import { getColSpan } from "./Requests";
import { ComponentRequestTableRow } from "./ComponentRequestTableRow";
import { ComponentRequestTableHeaders } from "./ComponentRequestTableHeaders";
import { Theme, useUsers } from "common";

export const ComponentRequestsTableDrawer = ({
  commitment,
  components,
  open,
  budgetFiscalYear,
  inlineEditRow,
  setInlineEditRow,
  pushSnackbarMessage,
  setRefresh,
  setLoading,
}) => {
  const { currentUser } = useUsers();
  const { permissions, roles } = currentUser;

  return (
    <MuiTableRow
      key={commitment.id}
      sx={{
        backgroundColor: Theme.palette.primary.light,
        "& .MuiTableCell-head": {
          color: Theme.palette.grey[700],
          fontSize: 13,
          fontWeight: 600,
        },
        "& .MuiTableCell-body": {
          borderBottom: "unset",
          fontSize: 13,
          fontWeight: 500,
        },
        "& .MuiTableCell-sizeSmall": { padding: "6px 5px 6px 5px" },
      }}
    >
      <MuiTableCell
        colSpan={getColSpan(permissions, roles)}
        sx={
          open
            ? { padding: "10px 3px 10px 45px" }
            : { padding: 0, borderBottomWidth: 0 }
        }
      >
        <MuiCollapse in={open} timeout="auto" unmountOnExit>
          <MuiBox>
            <MuiTable
              size="small"
              aria-label="Component Requests MuiTable"
              sx={{ tableLayout: "fixed" }}
            >
              <ComponentRequestTableHeaders
                budgetFiscalYear={budgetFiscalYear}
              />

              <MuiTableBody>
                {components &&
                  components.map((row, index) => (
                    <ComponentRequestTableRow
                      component={row}
                      commitmentId={commitment.id}
                      commitmentName={commitment.name}
                      commitmentFinalized={commitment.finalized}
                      key={index}
                      inlineEditRow={inlineEditRow}
                      setInlineEditRow={setInlineEditRow}
                      pushSnackbarMessage={pushSnackbarMessage}
                      budgetFiscalYear={budgetFiscalYear}
                      setRefresh={setRefresh}
                      setLoading={setLoading}
                    />
                  ))}
              </MuiTableBody>
            </MuiTable>
          </MuiBox>
        </MuiCollapse>
      </MuiTableCell>
    </MuiTableRow>
  );
};
