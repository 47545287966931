import { useEffect, useState } from "react";
import axios from "axios";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import {
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  IconButton as MuiIconButton,
  Typography as MuiTypography,
  OutlinedInput as MuiOutlinedInput,
  InputAdornment as MuiInputAdornment,
} from "@mui/material";
import {
  Check as MuiCheckIcon,
  Close as MuiCloseIcon,
  NewReleases as NewReleasesIcon,
} from "@mui/icons-material";
import { Theme, useAlerts } from "common";
import { getBudgetByComponentId } from "app/services/budgetingService";
import { RenderPTAs, getAmount, getComponentLink } from "./BudgetTable";

export const InlineForm = (props) => {
  const { t } = useTranslation();
  const { setAlert, clearAlert } = useAlerts();
  // Props
  const {
    row,
    commitmentId,
    componentId,
    setEditComponent,
    putBudgetByComponentId,
    budgetFiscalYear,
    setSnackBar,
    setRefresh,
    editComponent,
    setInfoPanelDisabled,
  } = props;
  // State
  const [formData, setFormData] = useState({
    fyProjectionOne: "",
    fyBudget: "",
    fyPlanOne: "",
    fyPlanTwo: "",
    comments: "",
  });
  // Styles
  const childCellSecondaryStyles = {
    "&.MuiTableCell-root": {
      borderBottomStyle: "none",
      padding: "2px 8px",
    },
  };

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    getBudgetByComponentId(
      componentId,
      budgetFiscalYear,
      setAlert,
      clearAlert,
      setFormData,
      cancelSource
    );
    return () => {
      cancelSource.cancel();
    };
  }, [budgetFiscalYear, componentId, setAlert, clearAlert]);

  return (
    <Formik initialValues={formData} enableReinitialize>
      {(formikProps) => {
        const { values, setFieldValue } = formikProps;
        return (
          <MuiTableRow
            sx={{
              backgroundColor: Theme.palette.grey[100],
              "&:last-child td": {
                paddingBottom: "20px",
              },
            }}
          >
            <MuiTableCell
              sx={{
                textIndent: 20,
                borderBottomStyle: "none",
                padding: "2px 2px",
              }}
            >
              <MuiTypography
                noWrap
                sx={{ textOverflow: "ellipsis", width: "280px" }}
              >
                {row.componentType === "REQUEST" && (
                  <MuiTooltip title={t(`Budgeting.mainView.newComponent`)}>
                    <NewReleasesIcon
                      size="small"
                      sx={{
                        color: Theme.palette.warning.main,
                        verticalAlign: "text-bottom",
                        marginRight: "5px",
                      }}
                    />
                  </MuiTooltip>
                )}
                <RenderPTAs component={row} editComponent={editComponent} />{" "}
                <MuiTooltip title={row.componentName} placement="top">
                  {getComponentLink(
                    editComponent,
                    commitmentId,
                    componentId,
                    row.componentName
                  )}
                </MuiTooltip>
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell align="right" sx={childCellSecondaryStyles}>
              <MuiTypography variant="body2" align="right">
                {getAmount(row.totalComponentAmountRequested)}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell align="right" sx={childCellSecondaryStyles}>
              <MuiTypography variant="body2" align="right">
                {getAmount(row.fyActualsThree)}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell align="right" sx={childCellSecondaryStyles}>
              <MuiTypography variant="body2" align="right">
                {getAmount(row.fyActualsTwo)}
              </MuiTypography>
            </MuiTableCell>

            <MuiTableCell align="right" sx={childCellSecondaryStyles}>
              <MuiTypography variant="body2" align="right">
                {getAmount(row.totalExpenditure)}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell align="right" sx={childCellSecondaryStyles}>
              <MuiTypography variant="body2" align="right">
                {getAmount(row.totalUnspentAmount)}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell align="right" sx={childCellSecondaryStyles}>
              <MuiTypography variant="body2" align="right">
                {getAmount(row.fyActualsOne)}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell align="right" sx={childCellSecondaryStyles}>
              <MuiTypography variant="body2" align="right">
                {getAmount(row.fyBudgetOne)}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell align="right" sx={childCellSecondaryStyles}>
              <NumberFormatField
                id="fyProjectionOne"
                setFieldValue={setFieldValue}
                formValues={values.fyProjectionOne}
                restrictions={false}
              />
            </MuiTableCell>
            <MuiTableCell sx={childCellSecondaryStyles}>
              <NumberFormatField
                id="fyBudget"
                setFieldValue={setFieldValue}
                formValues={values.fyBudget}
                restrictions
              />
            </MuiTableCell>
            <MuiTableCell sx={childCellSecondaryStyles}>
              <NumberFormatField
                id="fyPlanOne"
                setFieldValue={setFieldValue}
                formValues={values.fyPlanOne}
                restrictions
              />
            </MuiTableCell>
            <MuiTableCell sx={childCellSecondaryStyles}>
              <NumberFormatField
                id="fyPlanTwo"
                setFieldValue={setFieldValue}
                formValues={values.fyPlanTwo}
                restrictions
              />
            </MuiTableCell>
            <MuiTableCell align="right" sx={childCellSecondaryStyles}>
              <MuiTypography variant="body2" align="right">
                {getAmount(row.availableAfterFYPlanTwo)}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell align="right" sx={childCellSecondaryStyles}>
              <MuiTypography variant="body2" align="right">
                {getAmount(row.fyBudgetCap)}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell sx={childCellSecondaryStyles}>
              <MuiOutlinedInput
                size="small"
                id="comments"
                multiline
                sx={{ padding: "8px", fontSize: "15px" }}
                value={values.comments}
                onChange={(event) => {
                  setFieldValue("comments", event.target.value);
                }}
                inputProps={{
                  maxLength: 30,
                }}
              />
            </MuiTableCell>
            <MuiTableCell sx={childCellSecondaryStyles}>
              <MuiIconButton
                size="small"
                onClick={() => {
                  setSnackBar(false);
                  putBudgetByComponentId(
                    values,
                    componentId,
                    budgetFiscalYear,
                    setEditComponent,
                    setSnackBar,
                    setRefresh,
                    setInfoPanelDisabled,
                    setAlert,
                    clearAlert
                  );
                }}
              >
                <MuiCheckIcon size="small" />
              </MuiIconButton>
              <MuiIconButton
                size="small"
                onClick={() => {
                  setInfoPanelDisabled(false);
                  setEditComponent(0);
                }}
              >
                <MuiCloseIcon size="small" />
              </MuiIconButton>
            </MuiTableCell>
          </MuiTableRow>
        );
      }}
    </Formik>
  );
};

const NumberFormatField = (props) => {
  return (
    <NumericFormat
      size="small"
      sx={{ fontSize: "15px" }}
      thousandSeparator
      decimalScale={2}
      customInput={MuiOutlinedInput}
      value={props.formValues}
      isAllowed={(values) => {
        const { value } = values;
        if (props.restrictions) {
          return value !== "-" && value >= 0 && value <= 999999999;
        } else {
          return value === "-" || (value >= -999999999 && value <= 999999999);
        }
      }}
      fixedDecimalScale
      autoComplete="off"
      allowNegative={!props.restrictions}
      startAdornment={
        <MuiInputAdornment component="div" position="start">
          <MuiTypography variant="body2">$</MuiTypography>
        </MuiInputAdornment>
      }
      onValueChange={(data) => {
        props.setFieldValue(props.id, data.value);
      }}
    />
  );
};
