import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
} from "@mui/material";
import { ComponentTypes } from "app/shared/constants";

export const ComponentTypeField = ({
  required,
  includeRequestTypes,
  labelText,
  error,
  helperText,
  size,
  value = "",
  ...passedParams
}) => {
  const { t } = useTranslation();

  const label =
    labelText ??
    t(
      `Commitments.commitmentComponents.create.form.fields.componentType.label`
    );

  // Create an initial list of values that excludes 'DEAN_COMMITMENT'
  const componentTypeMap = Object.assign(ComponentTypes);
  delete componentTypeMap.DEAN_COMMITMENT;

  // Optionally exclude 'REQUEST'
  const nonRequestValues = (() => {
    let opts = Object.assign({}, componentTypeMap);
    delete opts.REQUEST;
    return opts;
  })();

  const options = !!includeRequestTypes ? componentTypeMap : nonRequestValues;
  const labelNum = Math.floor(Math.random() * 99999);

  return (
    <MuiFormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
      <MuiInputLabel id={`componentTypeLabel${labelNum}`}>
        {label}
      </MuiInputLabel>
      <MuiSelect
        {...passedParams}
        value={value}
        labelId={`componentTypeSelectLabel${labelNum}`}
        id="componentType"
        notched
        size={size}
        required={required}
        error={error}
      >
        {Object.entries(options).map(([key, val], index) => (
          <MuiMenuItem key={index} value={key}>
            {t(val)}
          </MuiMenuItem>
        ))}
      </MuiSelect>
      <MuiFormHelperText>{helperText}</MuiFormHelperText>
    </MuiFormControl>
  );
};

ComponentTypeField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
