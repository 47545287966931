import React from "react";
import qs from "qs";
import { useLocation } from "react-router-dom";

export const CommitmentContext = React.createContext();

export const CommitmentProvider = ({ children }) => {
  // Getting location object from useLocation (react-router-dom)
  const location = useLocation();

  // State for filters
  const params = qs.parse(location.search);
  const [filters, setFilters] = React.useState({
    dealName: params.dealName || "",
    departmentName: params.departmentName || "",
    departmentValue: params.departmentName
      ? params.departmentName.split("(").pop().split(")")[0].trim()
      : "",
    status: params.status || "",
    project: params.project || "",
    task: params.task || "",
    award: params.award || "",
  });

  // State for Sort
  const [sort, setSort] = React.useState({
    column: "name",
    orderBy: "asc",
  });

  // State for pagination
  const [pagination, setPagination] = React.useState({
    page: 0,
    rowsPerPage: 25,
  });

  // State for keeping commitment id for opened component drawer
  const [openCommitmentId, setOpenCommitmentId] = React.useState(0);

  // For commitment documents

  // State for commitment document Sort
  const [docSort, setDocSort] = React.useState({
    column: "name",
    orderBy: "asc",
  });

  // State for commitemnt document pagination
  const [docPagination, setDocPagination] = React.useState({
    page: 0,
    rowsPerPage: 10,
  });

  // State for commitemnt document pagination
  const [financialHistoryPagination, setFinancialHistoryPagination] =
    React.useState({
      page: 0,
      rowsPerPage: 10,
      componentId: "",
    });

  const [
    financialHistorySelectedFiscaYear,
    setFinancialHistorySelectedFiscaYear,
  ] = React.useState(0);

  React.useEffect(() => {
    if (location.pathname.split("/")[1] !== "commitments") {
      setFilters({
        dealName: params.dealName || "",
        departmentName: params.departmentName || "",
        departmentValue: params.departmentValue || "",
        status: params.status || "",
        project: params.project || "",
        task: params.task || "",
        award: params.award || "",
      });
      setSort({
        column: "name",
        orderBy: "asc",
      });
      setPagination({
        page: 0,
        rowsPerPage: 25,
      });
      // For documents

      setDocSort({
        column: "name",
        orderBy: "asc",
      });
      setDocPagination({
        page: 1,
        rowsPerPage: 10,
      });
      setFinancialHistoryPagination({
        page: 0,
        rowsPerPage: 10,
        componentId: "",
      });
    }
  }, [
    location,
    params.dealName,
    params.departmentName,
    params.departmentValue,
    params.status,
    params.project,
    params.task,
    params.award,
  ]);

  return (
    <CommitmentContext.Provider
      value={{
        filters,
        setFilters,
        sort,
        setSort,
        pagination,
        setPagination,
        openCommitmentId,
        setOpenCommitmentId,
        docPagination,
        setDocPagination,
        docSort,
        setDocSort,
        financialHistoryPagination,
        setFinancialHistoryPagination,
        financialHistorySelectedFiscaYear,
        setFinancialHistorySelectedFiscaYear,
      }}
    >
      {children}
    </CommitmentContext.Provider>
  );
};

export const useCommitmentContext = () => {
  return React.useContext(CommitmentContext);
};
