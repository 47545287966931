import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  Grid as MuiGrid,
  Dialog as MuiDialog,
  FormLabel as MuiFormLabel,
  TextField as MuiTextField,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from "@mui/material";
import {
  MobileDatePicker as MuiMobileDatePicker,
  LocalizationProvider as MuiLocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns as MuiAdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Theme, useAlerts } from "common";
import {
  postBudgetFiscalYear,
  putBudgetFiscalYear,
} from "app/services/budgetingService";
import { useBudgetingContext } from "app/services/budgetingContext";
import { getIsValidDate } from "app/shared/utils";
import { FormButton } from "app/shared/ui/FormButton";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";

export const BudgetingFormDialog = ({ openDialog, setOpenDialog }) => {
  const { t } = useTranslation();
  const { setAlert, clearAlert } = useAlerts();
  // State management
  const {
    setInfoDialogOpen,
    budgetFiscalYearResponse,
    setRefreshInformationPanel,
  } = useBudgetingContext();

  const [budgetFiscalYear] = useState(
    (openDialog === "EDIT" &&
      parseInt(budgetFiscalYearResponse.budgetFiscalYear) - 1) ||
      budgetFiscalYearResponse.budgetFiscalYear
  );
  const [newBudgetFiscalYear] = useState(parseInt(budgetFiscalYear) + 1);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isInitialEditPeriodStartDate, setIsInitialEditPeriodStartDate] =
    useState(openDialog === "EDIT" || false);
  const [isInitialEditPeriodEndDate, setIsInitialEditPeriodEndDate] = useState(
    openDialog === "EDIT" || false
  );
  const [budgetFiscalYearValues, setBudgetFiscalYearValues] = useState();
  const [isValidDate, setIsValidDate] = useState(true);
  const [formDataResponse] = useState({
    // Object keys use to create/edit form data
    initialEditPeriodStartDate:
      (openDialog === "EDIT" &&
        budgetFiscalYearResponse.initialEditStartDate) ||
      null,
    initialEditPeriodEndDate:
      (openDialog === "EDIT" && budgetFiscalYearResponse.initialEditEndDate) ||
      null,
    revisionEditPeriodStartDate:
      (openDialog === "EDIT" &&
        budgetFiscalYearResponse.revisionEditStartDate) ||
      null,
    revisionEditPeriodEndDate:
      (openDialog === "EDIT" && budgetFiscalYearResponse.revisionEditEndDate) ||
      null,
  });

  // yup validation schema
  const budgetingValidation = Yup.object().shape({
    initialEditPeriodStartDate: Yup.date().required(),
    initialEditPeriodEndDate: Yup.date().required(),
  });

  const budgetingCreateDialog = "Budgeting.createDialog";
  const budgetingCreateDialogFormFields = "Budgeting.createDialog.form.fields";

  const getDialogTitle = (dialogState, fiscalYear) => {
    let title = "";
    if (dialogState === "ADD") {
      title = t(`${budgetingCreateDialog}.addtitle`, { fiscalYear });
    }
    if (dialogState === "EDIT") {
      title = t(`${budgetingCreateDialog}.editTitle`, { fiscalYear });
    }
    return title;
  };

  const handleClose = () => {
    setOpenDialog(false);
    setInfoDialogOpen(false);
    clearAlert();
  };

  const _handleSubmit = (values) => {
    openDialog === "ADD" && setShowConfirmationDialog(true);
    openDialog === "EDIT" &&
      putBudgetFiscalYear(
        newBudgetFiscalYear,
        values,
        setRefreshInformationPanel,
        setOpenDialog,
        setInfoDialogOpen,
        setAlert,
        clearAlert
      );
    setBudgetFiscalYearValues(values);
  };

  return (
    <>
      {openDialog === "ADD" && showConfirmationDialog && (
        <ConfirmationDialog
          open={showConfirmationDialog}
          title={t(`${budgetingCreateDialog}.addtitle`)}
          message={t(`${budgetingCreateDialog}.confirmationMessage`, {
            fiscalYear: newBudgetFiscalYear,
          })}
          handleCancel={() => {
            setShowConfirmationDialog(false);
          }}
          handleOk={() => {
            setShowConfirmationDialog(false);
            postBudgetFiscalYear({
              newBudgetFiscalYear,
              budgetFiscalYearValues,
              setRefreshInformationPanel,
              setOpenDialog,
              setInfoDialogOpen,
              setAlert,
              clearAlert,
            });
          }}
          okLabel={t(
            `${budgetingCreateDialog}.confirmationDialogActionButtons.addButton`
          )}
          cancelLabel={t("globals.form.actionButtons.cancel")}
        />
      )}

      <MuiDialog
        open={!!openDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <Formik
          // initialValues - User to store the Formik form's intial form values
          /** !Object */ initialValues={formDataResponse}
          /** !Boolean */ enableReinitialize
          // onSubmit - Callback definition to execute on the click of Form Submit
          onSubmit={(values, { setSubmitting }) => {
            _handleSubmit(values);
            setSubmitting(false);
          }}
          validationSchema={budgetingValidation}
        >
          {(props) => {
            const /** !Object */ {
                values,
                dirty,
                isValid,
                handleSubmit,
                setFieldValue,
              } = props;
            return (
              // Native form element to submit the form values
              <form onSubmit={handleSubmit}>
                <MuiDialogTitle id="form-dialog-title">
                  {getDialogTitle(openDialog, newBudgetFiscalYear)}
                </MuiDialogTitle>

                <MuiDialogContent>
                  <MuiGrid container spacing={2}>
                    <MuiGrid item xs={12}>
                      <MuiGrid item xs={12}>
                        <MuiFormLabel required>
                          {t(
                            `${budgetingCreateDialogFormFields}.initialEditPeriod.label`
                          )}
                        </MuiFormLabel>
                      </MuiGrid>
                      <MuiGrid
                        item
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="baseline"
                        wrap="nowrap"
                        sx={{
                          "& .MuiFormControl-root": { width: "185px" },
                          "& .MuiIconButton-root": { padding: 0 },
                        }}
                      >
                        <MuiLocalizationProvider
                          dateAdapter={MuiAdapterDateFns}
                        >
                          <MuiMobileDatePicker
                            id="initialEditPeriodStartDate"
                            format="MM/dd/yyyy"
                            value={getIsValidDate(
                              values.initialEditPeriodStartDate
                            )}
                            onChange={(value) => {
                              value !== "" &&
                                setIsInitialEditPeriodStartDate(true);
                              value === null &&
                                setIsInitialEditPeriodStartDate(false);
                              setFieldValue(
                                "initialEditPeriodStartDate",
                                value
                              );
                            }}
                            inputVariant={"outlined"}
                            maxDate={
                              (values.initialEditPeriodEndDate &&
                                new Date(values.initialEditPeriodEndDate)) ||
                              undefined
                            }
                            minDate={undefined}
                            minDateMessage={t(
                              `${budgetingCreateDialogFormFields}.fiscalYearDateRangeMessage`
                            )}
                            maxDateMessage={t(
                              `${budgetingCreateDialogFormFields}.initialEditPeriod.validation.maxDateMessage`
                            )}
                            inputProps={{ autoComplete: "off" }}
                            clearable
                            onError={(reason, value) => {
                              setIsValidDate(true);
                              reason && setIsValidDate(false);
                            }}
                            sx={{
                              "& .MuiIconButton-label": {
                                color: Theme.palette.grey[700],
                              },
                            }}
                            renderInput={(innerProps) => (
                              <MuiTextField {...innerProps} />
                            )}
                          />
                        </MuiLocalizationProvider>
                        <MuiFormLabel>
                          {t(`${budgetingCreateDialogFormFields}.to.label`)}
                        </MuiFormLabel>
                        <MuiLocalizationProvider
                          dateAdapter={MuiAdapterDateFns}
                        >
                          <MuiMobileDatePicker
                            id="initialEditPeriodEndDate"
                            format="MM/dd/yyyy"
                            value={getIsValidDate(
                              values.initialEditPeriodEndDate
                            )}
                            onChange={(value) => {
                              value !== "" &&
                                setIsInitialEditPeriodEndDate(true);
                              value === null &&
                                setIsInitialEditPeriodEndDate(false);
                              setFieldValue("initialEditPeriodEndDate", value);
                            }}
                            maxDate={undefined}
                            maxDateMessage={t(
                              `${budgetingCreateDialogFormFields}.fiscalYearDateRangeMessage`
                            )}
                            minDate={
                              (values.initialEditPeriodStartDate &&
                                new Date(
                                  new Date(
                                    values.initialEditPeriodStartDate
                                  ).setDate(
                                    new Date(
                                      values.initialEditPeriodStartDate
                                    ).getDate() + 1
                                  )
                                )) ||
                              undefined
                            }
                            minDateMessage={t(
                              `${budgetingCreateDialogFormFields}.initialEditPeriod.validation.minDateMessage`
                            )}
                            inputVariant={"outlined"}
                            inputProps={{ autoComplete: "off" }}
                            clearable
                            onError={(reason, value) => {
                              reason && setIsValidDate(false);
                            }}
                            sx={{
                              "& .MuiIconButton-label": {
                                color: Theme.palette.grey[700],
                              },
                            }}
                            renderInput={(innerProps) => (
                              <MuiTextField {...innerProps} />
                            )}
                          />
                        </MuiLocalizationProvider>
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid item xs={12}>
                      <MuiGrid item xs={12}>
                        <MuiFormLabel>
                          {t(
                            `${budgetingCreateDialogFormFields}.revisionEditPeriod.label`
                          )}
                        </MuiFormLabel>
                      </MuiGrid>
                      <MuiGrid
                        item
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="baseline"
                        sx={{
                          "& .MuiFormControl-root": { width: "185px" },
                          "& .MuiIconButton-root": { padding: 0 },
                        }}
                        wrap="nowrap"
                      >
                        <MuiLocalizationProvider
                          dateAdapter={MuiAdapterDateFns}
                        >
                          <MuiMobileDatePicker
                            id="revisionEditPeriodStartDate"
                            format="MM/dd/yyyy"
                            value={getIsValidDate(
                              values.revisionEditPeriodStartDate
                            )}
                            onChange={(value) => {
                              setFieldValue(
                                "revisionEditPeriodStartDate",
                                value
                              );
                            }}
                            maxDate={
                              (values.revisionEditPeriodEndDate &&
                                new Date(values.revisionEditPeriodEndDate)) ||
                              undefined
                            }
                            maxDateMessage={t(
                              `${budgetingCreateDialogFormFields}.revisionEditPeriod.validation.maxDateMessage`
                            )}
                            minDate={
                              (values.initialEditPeriodEndDate &&
                                new Date(
                                  new Date(
                                    values.initialEditPeriodEndDate
                                  ).setDate(
                                    new Date(
                                      values.initialEditPeriodEndDate
                                    ).getDate() + 1
                                  )
                                )) ||
                              undefined
                            }
                            minDateMessage={t(
                              `${budgetingCreateDialogFormFields}.revisionEditPeriod.validation.revisionEditPeriodStartDateAfterInitialEndDate`
                            )}
                            disabled={
                              values.revisionEditPeriodStartDate === null &&
                              (!isInitialEditPeriodEndDate ||
                                !isInitialEditPeriodStartDate)
                            }
                            onError={(reason, value) => {
                              reason && setIsValidDate(false);
                              values.revisionEditPeriodStartDate === null &&
                                values.revisionEditPeriodEndDate &&
                                setIsValidDate(false);
                            }}
                            inputVariant={"outlined"}
                            inputProps={{ autoComplete: "off" }}
                            clearable
                            sx={{
                              "& .MuiIconButton-label": {
                                color: Theme.palette.grey[700],
                              },
                            }}
                            renderInput={(innerProps) => (
                              <MuiTextField {...innerProps} />
                            )}
                          />
                        </MuiLocalizationProvider>
                        <MuiFormLabel>
                          {t(`${budgetingCreateDialogFormFields}.to.label`)}
                        </MuiFormLabel>
                        <MuiLocalizationProvider
                          dateAdapter={MuiAdapterDateFns}
                        >
                          <MuiMobileDatePicker
                            id="revisionEditPeriodEndDate"
                            format="MM/dd/yyyy"
                            value={getIsValidDate(
                              values.revisionEditPeriodEndDate
                            )}
                            onChange={(value) => {
                              setFieldValue("revisionEditPeriodEndDate", value);
                            }}
                            maxDate={undefined}
                            maxDateMessage={t(
                              `${budgetingCreateDialogFormFields}.fiscalYearDateRangeMessage`
                            )}
                            minDate={
                              (values.revisionEditPeriodStartDate &&
                                new Date(
                                  new Date(
                                    values.revisionEditPeriodStartDate
                                  ).setDate(
                                    new Date(
                                      values.revisionEditPeriodStartDate
                                    ).getDate() + 1
                                  )
                                )) ||
                              (values.initialEditPeriodEndDate &&
                                new Date(
                                  new Date(
                                    values.initialEditPeriodEndDate
                                  ).setDate(
                                    new Date(
                                      values.initialEditPeriodEndDate
                                    ).getDate() + 1
                                  )
                                )) ||
                              undefined
                            }
                            minDateMessage={t(
                              `${budgetingCreateDialogFormFields}.revisionEditPeriod.validation.minDateMessage`
                            )}
                            disabled={
                              values.revisionEditPeriodEndDate === null &&
                              (!isInitialEditPeriodEndDate ||
                                !isInitialEditPeriodStartDate)
                            }
                            onError={(reason, value) => {
                              reason && setIsValidDate(false);
                              values.revisionEditPeriodEndDate === null &&
                                values.revisionEditPeriodStartDate !== null &&
                                setIsValidDate(false);
                            }}
                            inputVariant={"outlined"}
                            inputProps={{ autoComplete: "off" }}
                            clearable
                            sx={{
                              "& .MuiIconButton-label": {
                                color: Theme.palette.grey[700],
                              },
                            }}
                            renderInput={(innerProps) => (
                              <MuiTextField {...innerProps} />
                            )}
                          />
                        </MuiLocalizationProvider>
                      </MuiGrid>
                    </MuiGrid>
                  </MuiGrid>
                </MuiDialogContent>
                <MuiDialogActions>
                  <MuiGrid
                    item
                    container
                    justifyContent="flex-end"
                    xs={7}
                    spacing={2}
                  >
                    <FormButton
                      cancel={handleClose}
                      save={{
                        disabled: !dirty || !isValid || !isValidDate,
                      }}
                    />
                  </MuiGrid>
                </MuiDialogActions>
              </form>
            );
          }}
        </Formik>
      </MuiDialog>
    </>
  );
};
