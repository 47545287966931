import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  TextField as MuiTextField,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import { getAllPresentations } from "app/services/commitmentsService";
import { useEffect, useState } from "react";

export const CommitmentPresentationField = ({
  required,
  error,
  helperText,
  ...passedParams
}) => {
  const { t } = useTranslation();

  // Presentations
  const [presentations, setPresentations] = useState([]);

  const [presentationError, setPresentationError] = useState({
    exist: false,
    message: null,
  });

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();

    getAllPresentations(setPresentations, setPresentationError, cancelSource);
    return () => {
      cancelSource.cancel();
    };
  }, []);

  return (
    <>
      {presentations && (
        <MuiAutocomplete
          options={presentations}
          getOptionLabel={(option) => option && option.name}
          autoHighlight
          disableClearable
          handleHomeEndKeys
          openOnFocus
          fullWidth
          clearIcon={false}
          required={required}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              required={required}
              label={t(`Commitments.create.form.fields.presentation.label`)}
              variant={"outlined"}
              error={presentationError.exist || error}
              helperText={presentationError.message || helperText}
            />
          )}
          {...passedParams}
        />
      )}
    </>
  );
};
