import { Fragment, useRef } from "react";
import { addDays } from "date-fns";
import { FieldArray } from "formik";
import { useTranslation } from "react-i18next";
import {
  Paper as MuiPaper,
  Table as MuiTable,
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TextField as MuiTextField,
  IconButton as MuiIconButton,
  TableContainer as MuiTableContainer,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import { RemoveCircle as MuiRemoveCircleIcon } from "@mui/icons-material";
import { AdapterDateFns as MuiAdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider as MuiLocalizationProvider,
} from "@mui/x-date-pickers";
import { getIsValidDate } from "app/shared/utils";
import { FormattedCurrencyField } from "app/shared/ui/CurrencyFormat";
import { disabledTextFieldStyles } from "app/shared/ui/sharedStyles";
import { CommitmentComponentRequestHeaders } from "./CommitmentComponentRequestHeaders";
import { CommitmentComponentRequestFooter } from "./CommitmentComponentRequestFooter";

export const CommitmentComponentRequestTable = ({
  loading,
  isViewOnly,
  isEditRecord,
  isDeleteRecord,
  inputVariant,
  currentFiscalYear,
  getEmptyComponent,
  values,
  errors,
  handleChange,
  setFieldValue,
  budgetEditableByCurrentUser,
}) => {
  const { t } = useTranslation();

  const componentFormField =
    "Commitments.commitmentComponents.create.form.fields";
  const rowRef = useRef(null);
  const lastRowRef = useRef(null);
  const size = "small";

  isViewOnly = isViewOnly || isDeleteRecord || false;

  const arrayHelpersRef = useRef(null);

  const getInputVariant = (readOnly) => {
    return readOnly ? "standard" : inputVariant || "outlined";
  };

  return (
    <MuiTableContainer component={MuiPaper}>
      <MuiTable
        aria-label="Commitment Components"
        aria-live="polite"
        stickyHeader
        size={size}
      >
        <CommitmentComponentRequestHeaders
          currentFiscalYear={currentFiscalYear}
          isViewOnly={isViewOnly}
        />
        <MuiTableBody>
          {loading && (
            <MuiTableRow>
              <MuiTableCell
                colSpan={!isViewOnly ? "10" : "9"}
                sx={{ textAlign: "center" }}
              >
                <MuiCircularProgress />
              </MuiTableCell>
            </MuiTableRow>
          )}
          {!loading && !values.components && (
            <MuiTableRow>
              <MuiTableCell
                colSpan={!isViewOnly ? "10" : "9"}
                sx={{ textAlign: "center" }}
              >
                {t("globals.list.messages.noData")}
              </MuiTableCell>
            </MuiTableRow>
          )}
          {!loading && values.components && (
            <FieldArray name="components">
              {(arrayHelpers) => {
                arrayHelpersRef.current = arrayHelpers;
                return values.components.map((i, indexI, componentList) => (
                  <Fragment key={indexI}>
                    <MuiTableRow
                      ref={
                        values.components.length === indexI + 1
                          ? lastRowRef
                          : rowRef
                      }
                    >
                      <MuiTableCell align="left">
                        <MuiTextField
                          id={`components[${indexI}].componentName`}
                          required
                          value={i?.componentName || ""}
                          onChange={handleChange}
                          fullWidth
                          multiline
                          helperText={
                            errors.components &&
                            errors?.components[indexI]?.componentName
                              ? errors.components[indexI].componentName
                              : ""
                          }
                          error={
                            errors.components &&
                            errors?.components[indexI]?.componentName &&
                            Boolean(errors.components[indexI].componentName)
                          }
                          variant={getInputVariant(
                            i?.hasOwnProperty("templateSourceType")
                          )}
                          disabled={isViewOnly}
                          autoComplete="none"
                          size={size}
                          inputProps={{
                            maxLength: 250,
                            readOnly: i?.hasOwnProperty("templateSourceType"),
                          }}
                          sx={disabledTextFieldStyles}
                        />
                      </MuiTableCell>
                      <MuiTableCell align="right">
                        <FormattedCurrencyField
                          id={`components[${indexI}].componentAmount`}
                          required={false}
                          value={i?.componentAmount || 0}
                          onChange={(event, _value) => {
                            setFieldValue(
                              `components[${indexI}].componentAmount`,
                              event.target.value.replace(/[^0-9.-]+/g, "")
                            );
                          }}
                          setFieldValue={setFieldValue}
                          variant={getInputVariant()}
                          isViewOnly={isViewOnly}
                          disabled={isViewOnly}
                          size={size}
                        />
                      </MuiTableCell>
                      <MuiTableCell align="right">
                        <FormattedCurrencyField
                          id={`components[${indexI}].budgetPlan.fyProjectionOne`}
                          required={false}
                          value={i?.budgetPlan?.fyProjectionOne}
                          onChange={(event, _value) => {
                            setFieldValue(
                              `components[${indexI}].budgetPlan.fyProjectionOne`,
                              event.target.value.replace(/[^0-9.-]+/g, "")
                            );
                          }}
                          setFieldValue={setFieldValue}
                          variant={getInputVariant()}
                          isViewOnly={isViewOnly}
                          disabled={isViewOnly}
                          size={size}
                        />
                      </MuiTableCell>
                      <MuiTableCell align="right">
                        <FormattedCurrencyField
                          id={`components[${indexI}].budgetPlan.fyBudget`}
                          required={false}
                          value={i?.budgetPlan?.fyBudget}
                          onChange={(event, _value) => {
                            setFieldValue(
                              `components[${indexI}].budgetPlan.fyBudget`,
                              event.target.value.replace(/[^0-9.-]+/g, "")
                            );
                          }}
                          setFieldValue={setFieldValue}
                          variant={getInputVariant()}
                          isViewOnly={isViewOnly}
                          disabled={isViewOnly}
                          size={size}
                        />
                      </MuiTableCell>
                      <MuiTableCell align="right">
                        <FormattedCurrencyField
                          id={`components[${indexI}].budgetPlan.fyPlanOne`}
                          required={false}
                          value={i?.budgetPlan?.fyPlanOne}
                          onChange={(event, _value) => {
                            setFieldValue(
                              `components[${indexI}].budgetPlan.fyPlanOne`,
                              event.target.value.replace(/[^0-9.-]+/g, "")
                            );
                          }}
                          setFieldValue={setFieldValue}
                          variant={getInputVariant()}
                          isViewOnly={isViewOnly}
                          disabled={isViewOnly}
                          size={size}
                        />
                      </MuiTableCell>
                      <MuiTableCell align="right">
                        <FormattedCurrencyField
                          id={`components[${indexI}].budgetPlan.fyPlanTwo`}
                          required={false}
                          value={i?.budgetPlan?.fyPlanTwo}
                          onChange={(event, _value) => {
                            setFieldValue(
                              `components[${indexI}].budgetPlan.fyPlanTwo`,
                              event.target.value.replace(/[^0-9.-]+/g, "")
                            );
                          }}
                          setFieldValue={setFieldValue}
                          variant={getInputVariant()}
                          isViewOnly={isViewOnly}
                          disabled={isViewOnly}
                          size={size}
                        />
                      </MuiTableCell>
                      <MuiTableCell align="right">
                        <ConfiguredDateField
                          id={`components[${indexI}].startDate`}
                          required={false}
                          value={getIsValidDate(i.startDate)}
                          onChange={(value) => {
                            setFieldValue(
                              `components[${indexI}].startDate`,
                              value
                            );
                          }}
                          inputVariant={getInputVariant()}
                          maxDate={
                            (i?.expirationDate &&
                              new Date(i?.expirationDate)) ||
                            undefined
                          }
                          maxDateMessage={t(
                            `${componentFormField}.startDate.validation.maxDateMessage`
                          )}
                          isViewOnly={isViewOnly}
                          size={size}
                        />
                      </MuiTableCell>
                      <MuiTableCell align="right">
                        <ConfiguredDateField
                          id={`components[${indexI}].expirationDate`}
                          required={false}
                          value={getIsValidDate(i.expirationDate)}
                          onChange={(value) => {
                            setFieldValue(
                              `components[${indexI}].expirationDate`,
                              value
                            );
                          }}
                          inputVariant={getInputVariant()}
                          minDate={
                            (i?.startDate &&
                              addDays(new Date(i?.startDate), 1)) ||
                            undefined
                          }
                          minDateMessage={t(
                            `${componentFormField}.expirationDate.validation.minDateMessage`
                          )}
                          isViewOnly={isViewOnly}
                          size={size}
                        />
                      </MuiTableCell>
                      <MuiTableCell align="right">
                        <MuiTextField
                          id={`components[${indexI}].componentRequestNotes`}
                          required={false}
                          value={i?.componentRequestNotes}
                          onChange={handleChange}
                          fullWidth
                          multiline
                          helperText={
                            errors[
                              `components[${indexI}].componentRequestNotes`
                            ]
                              ? errors[
                                  `components[${indexI}].componentRequestNotes`
                                ]
                              : ""
                          }
                          error={
                            errors[
                              `components[${indexI}].componentRequestNotes`
                            ] &&
                            Boolean(
                              errors[
                                `components[${indexI}].componentRequestNotes`
                              ]
                            )
                          }
                          variant={getInputVariant()}
                          disabled={isViewOnly}
                          autoComplete="off"
                          size={size}
                          inputProps={{
                            maxLength: 30,
                          }}
                          sx={disabledTextFieldStyles}
                        />
                      </MuiTableCell>
                      {!isViewOnly && (
                        <MuiTableCell align="center">
                          {componentList.length > 1 &&
                            budgetEditableByCurrentUser && (
                              <MuiTooltip
                                title={"Delete Component"}
                                placement="top"
                              >
                                <span>
                                  <MuiIconButton
                                    size="small"
                                    onClick={() => arrayHelpers.remove(indexI)}
                                    disabled={
                                      isEditRecord &&
                                      i?.hasOwnProperty("templateSourceType")
                                    }
                                  >
                                    <MuiRemoveCircleIcon size="small" />
                                  </MuiIconButton>
                                </span>
                              </MuiTooltip>
                            )}
                        </MuiTableCell>
                      )}
                    </MuiTableRow>
                  </Fragment>
                ));
              }}
            </FieldArray>
          )}
        </MuiTableBody>
        <CommitmentComponentRequestFooter
          isViewOnly={isViewOnly}
          components={values.components}
          arrayHelpers={arrayHelpersRef}
          getEmptyComponent={getEmptyComponent}
          budgetEditableByCurrentUser={budgetEditableByCurrentUser}
        />
      </MuiTable>
    </MuiTableContainer>
  );
};

const ConfiguredDateField = (props) => {
  const isViewOnly = props.isViewOnly || false;
  const shrinkProps = isViewOnly && { shrink: true };
  let otherProps = { ...props };
  Object.hasOwn(otherProps, "isViewOnly") && delete otherProps.isViewOnly;
  Object.hasOwn(otherProps, "setFieldValue") && delete otherProps.setFieldValue;
  return (
    <MuiLocalizationProvider dateAdapter={MuiAdapterDateFns}>
      <MuiDatePicker
        sx={{
          "& .MuiOutlinedInput-input": {
            padding: "8px",
          },
          "& .MuiInputAdornment-root": {
            padding: 0,
            margin: 0,
          },
        }}
        inputFormat="MM/dd/yyyy"
        clearable
        disabled={isViewOnly}
        keyboardIcon={isViewOnly ? false : undefined}
        fullWidth
        inputProps={{ autoComplete: "off" }}
        InputLabelProps={{ ...shrinkProps }}
        renderInput={(innerProps) => (
          <MuiTextField label={props.label} size={props.size} {...innerProps} />
        )}
        {...otherProps}
      />
    </MuiLocalizationProvider>
  );
};
