import { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  Grid as MuiGrid,
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
  IconButton as MuiIconButton,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import {
  Person as MuiPersonIcon,
  PriceChange as MuiPriceChangeIcon,
  NewReleases as MuiNewReleasesIcon,
  CheckCircle as MuiCheckCircleIcon,
  CloudDownload as MuiCloudDownloadIcon,
  KeyboardArrowUp as MuiKeyboardArrowUpIcon,
  KeyboardArrowDown as MuiKeyboardArrowDownIcon,
} from "@mui/icons-material";
import { Theme, useUsers } from "common";
import { useRequestsContext } from "app/services/requestsContext";
import { useDownload } from "app/shared/ui/Download";
import { CurrencyFormat } from "app/shared/ui/CurrencyFormat";
import { getViewUrl } from "./Requests";
import { ComponentRequestsTableDrawer } from "./ComponentRequestsTableDrawer";
import { CommitmentRequestTableRowForm } from "./CommitmentRequestTableRowForm";

export const CommitmentRequestTableRow = ({
  commitment,
  budgetFiscalYear,
  inlineEditRow,
  setInlineEditRow,
  pushSnackbarMessage,
  setRefresh,
  setLoading,
  setOpenDialog,
}) => {
  const { t } = useTranslation();
  const { currentUser } = useUsers();
  const { permissions, roles } = currentUser;
  const { getDocument, downloadInProgress } = useDownload();

  const { openCommitmentId, setOpenCommitmentId } = useRequestsContext();
  const [isDrawerOpen, setIsDrawerOpen] = useState(
    commitment.id === openCommitmentId || false
  );

  const [formData, setFormData] = useState({
    commitmentRequestType: commitment?.commitmentRequestType,
    commitmentType: commitment?.commitmentType || "REQUEST",
    controllerComments: commitment?.controllerComments || "",
    controllerNotes: commitment?.controllerNotes || "",
    departmentName: commitment?.departmentName || "",
    fyBudget: commitment?.fyBudget || 0,
    fyProjectionOne: commitment?.fyProjectionOne || 0,
    id: commitment?.id,
    name: commitment?.name || "",
    orgCode: commitment?.orgCode,
    totalComponentAmountRequested:
      commitment?.totalComponentAmountRequested || 0,
    totalComponentAmountApproved: commitment?.totalComponentAmountApproved || 0,
    _links: commitment?._links,
  });

  const rowId = `commitment${commitment.id}`;

  const isCommitmentAdmin = roles.includes("COMMITMENT_ADMIN");

  return (
    <>
      <MuiTableRow>
        {rowId === inlineEditRow ? (
          <CommitmentRequestTableRowForm
            commitment={commitment}
            budgetFiscalYear={budgetFiscalYear}
            pushSnackbarMessage={pushSnackbarMessage}
            formData={formData}
            setFormData={setFormData}
            setRefresh={setRefresh}
            setInlineEditRow={setInlineEditRow}
            isDrawerOpen={isDrawerOpen}
            setDrawerOpen={setIsDrawerOpen}
            setOpenCommitmentId={setOpenCommitmentId}
            setLoading={setLoading}
          />
        ) : (
          <>
            {/* Drawer Buttons */}
            <MuiTableCell
              sx={{
                padding: "1px",
                borderLeft: "8px solid",
                borderLeftColor: commitment?.published
                  ? "#7bdf89"
                  : commitment?.finalized && !commitment?.published
                  ? "#5ed2ff"
                  : "#ddd",
              }}
            >
              {commitment?.components?.length > 0 && (
                <MuiIconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setIsDrawerOpen(!isDrawerOpen);
                    setOpenCommitmentId(commitment.id);
                  }}
                >
                  {!isDrawerOpen && <MuiKeyboardArrowDownIcon />}
                  {isDrawerOpen && <MuiKeyboardArrowUpIcon />}
                </MuiIconButton>
              )}
            </MuiTableCell>

            {/* Departments */}
            <MuiTableCell align="left">
              {commitment.departmentName && (
                <MuiTooltip title={commitment.departmentName} placement="left">
                  <MuiTypography
                    noWrap
                    variant="body2"
                    sx={{
                      textOverflow: "ellipsis",
                      width: "140px",
                    }}
                  >
                    {commitment.departmentName}
                  </MuiTypography>
                </MuiTooltip>
              )}
            </MuiTableCell>
            {/* Commitment Name */}
            <MuiTableCell align="left">
              <MuiTypography
                variant="body2"
                noWrap
                sx={{
                  textOverflow: "ellipsis",
                  width: "200px",
                }}
              >
                {getCommitmentIcon(commitment, t)}
                {getCommitmentTitleLink(commitment)}
              </MuiTypography>
            </MuiTableCell>

            {/* Amounts */}
            <MuiTableCell align="right">
              {CurrencyFormat(commitment.totalCommitmentAmountRequested)}
            </MuiTableCell>
            <MuiTableCell align="right">
              {CurrencyFormat(commitment.totalCommitmentAmountApproved)}
            </MuiTableCell>
            <MuiTableCell align="right">
              {CurrencyFormat(commitment.fyProjectionOne)}
            </MuiTableCell>
            <MuiTableCell align="right">
              {CurrencyFormat(commitment.fyBudget)}
            </MuiTableCell>

            {/* Note Columns */}
            {isCommitmentAdmin && (
              <MuiTableCell align="left">
                {commitment.controllerNotes && (
                  <MuiTooltip
                    title={commitment.controllerNotes}
                    placement="left"
                  >
                    <MuiTypography
                      noWrap
                      variant="body2"
                      component="div"
                      sx={{
                        textOverflow: "ellipsis",
                        width: "120px",
                      }}
                    >
                      {commitment.controllerNotes}
                    </MuiTypography>
                  </MuiTooltip>
                )}
              </MuiTableCell>
            )}
            <MuiTableCell align="left">
              {commitment.controllerComments && (
                <MuiTooltip
                  title={commitment.controllerComments}
                  placement="left"
                >
                  <MuiTypography
                    variant="body2"
                    component="div"
                    noWrap
                    sx={{
                      textOverflow: "ellipsis",
                      width: "110px",
                    }}
                  >
                    {commitment.controllerComments}
                  </MuiTypography>
                </MuiTooltip>
              )}
            </MuiTableCell>

            {/* Actions */}
            <MuiTableCell align="left">
              <MuiGrid
                container
                justifyContent={isCommitmentAdmin ? "flex-start" : "center"}
                wrap="nowrap"
              >
                {commitment._links?.disposition &&
                  permissions.DISPOSITION_REQUESTS && (
                    <MuiGrid item>
                      <MuiTooltip
                        title={t(
                          "Requests.mainView.list.editCommitmentTooltip"
                        )}
                        placement="top"
                        disableFocusListener={rowId === inlineEditRow}
                        disableHoverListener={rowId === inlineEditRow}
                        disableTouchListener={rowId === inlineEditRow}
                      >
                        <span>
                          <MuiIconButton
                            size="small"
                            onClick={() => {
                              if (rowId !== inlineEditRow) {
                                setInlineEditRow(rowId);
                              }
                            }}
                            disabled={!!inlineEditRow}
                          >
                            <MuiPriceChangeIcon size="small" />
                          </MuiIconButton>
                        </span>
                      </MuiTooltip>
                    </MuiGrid>
                  )}
                {commitment._links?.disposition &&
                  permissions.DISPOSITION_REQUESTS && (
                    <MuiGrid>
                      <MuiTooltip
                        title={getFinalizeCommitmentTooltipText(commitment, t)}
                        placement="top"
                      >
                        <span>
                          <MuiIconButton
                            size="small"
                            onClick={() => {
                              setOpenDialog(commitment.id);
                            }}
                            sx={{
                              color: "#00a1cc",
                            }}
                            disabled={
                              !!inlineEditRow ||
                              commitment.overallDispositionStatus ===
                                "NOT_DISPOSITIONED" ||
                              commitment?.published
                            }
                          >
                            <MuiCheckCircleIcon size="small" />
                          </MuiIconButton>
                        </span>
                      </MuiTooltip>
                    </MuiGrid>
                  )}
                {commitment._links?.completedNewHireDocument && (
                  <MuiGrid item justifyContent="flex-end">
                    <MuiTooltip
                      title={t(
                        "Commitments.commitmentDocuments.mainView.viewNewHireTemplate"
                      )}
                      placement="top"
                      disableFocusListener={rowId === inlineEditRow}
                      disableHoverListener={rowId === inlineEditRow}
                      disableTouchListener={rowId === inlineEditRow}
                    >
                      <span>
                        <MuiIconButton
                          size="small"
                          disabled={!!inlineEditRow || !!downloadInProgress}
                          onClick={() => {
                            let url = parseUrl(
                              commitment._links?.completedNewHireDocument.href
                            );
                            getDocument(
                              axios.defaults.baseURL + url.pathname,
                              commitment._links?.completedNewHireDocument?.title
                            );
                          }}
                        >
                          {downloadInProgress ? (
                            <MuiCircularProgress size={15} />
                          ) : (
                            <MuiCloudDownloadIcon />
                          )}
                        </MuiIconButton>
                      </span>
                    </MuiTooltip>
                  </MuiGrid>
                )}
              </MuiGrid>
            </MuiTableCell>
          </>
        )}
      </MuiTableRow>

      <ComponentRequestsTableDrawer
        commitment={commitment}
        components={commitment?.components}
        open={isDrawerOpen}
        budgetFiscalYear={budgetFiscalYear}
        inlineEditRow={inlineEditRow}
        setInlineEditRow={setInlineEditRow}
        pushSnackbarMessage={pushSnackbarMessage}
        setRefresh={setRefresh}
        setLoading={setLoading}
      />
    </>
  );
};

const getFinalizeCommitmentTooltipText = (commitment, t) => {
  if (commitment?.published) {
    return t("Commitments.editRequest.complete.disabledButtonIsPublishedTitle");
  } else {
    return commitment.overallDispositionStatus === "DISPOSITIONED"
      ? t("Commitments.editRequest.complete.enabledButtonTitle")
      : t("Commitments.editRequest.complete.disabledButtonTitle");
  }
};

const getCommitmentIcon = (commitment, t) => {
  if (commitment.commitmentRequestType === "RECRUITMENT") {
    return (
      <MuiTooltip title={t(`Budgeting.mainView.newHireRequest`)}>
        <MuiPersonIcon
          size="small"
          sx={{
            backgroundColor: Theme.palette.warning.main,
            borderRadius: "30px",
            borderWidth: "1.5px",
            borderStyle: "dashed",
            color: "#ffffff",
            marginRight: "5px",
            padding: "2px",
            verticalAlign: "text-bottom",
          }}
        />
      </MuiTooltip>
    );
  } else if (commitment.commitmentType === "REQUEST") {
    return (
      <MuiTooltip title={t(`Budgeting.mainView.newRequest`)}>
        <MuiNewReleasesIcon
          size="small"
          sx={{
            color: Theme.palette.warning.main,
            verticalAlign: "text-bottom",
            marginRight: "5px",
          }}
        />
      </MuiTooltip>
    );
  }
};

/**
 * Parses any URL into it's component parts.
 *
 * @param {string} url to be parsed
 * @returns {object} map of URL components
 */
const parseUrl = (url) => {
  var tmpLink = document.createElement("a");
  tmpLink.href = url;

  let { protocol, host, hostname, port, pathname, hash, search, origin } =
    tmpLink;

  // IE does not prefix with a slash
  if (pathname && pathname[0] !== "/") {
    pathname = "/" + pathname;
  }
  return { protocol, host, hostname, port, pathname, hash, search, origin };
};

const getCommitmentTitleLink = (commitment) => {
  return commitment?._links?.self?.href ? (
    <RouterLink
      to={{ pathname: getViewUrl(commitment?._links?.self?.href) }}
      style={{ textDecoration: "none" }}
    >
      <MuiTooltip title={commitment.name} placement="left">
        <span>{commitment.name}</span>
      </MuiTooltip>
    </RouterLink>
  ) : (
    <MuiTooltip title={commitment.name} placement="left">
      <span>{commitment.name}</span>
    </MuiTooltip>
  );
};
