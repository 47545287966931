import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, Link as RouterLink } from "react-router-dom";
import {
  Grid as MuiGrid,
  Link as MuiLink,
  Paper as MuiPaper,
  Table as MuiTable,
  Button as MuiButton,
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  IconButton as MuiIconButton,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  TableSortLabel as MuiTableSortLabel,
  TableContainer as MuiTableContainer,
  TablePagination as MuiTablePagination,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import {
  Edit as MuiEditIcon,
  Check as MuiCheckIcon,
  Delete as MuiDeleteIcon,
  ArrowBack as MuiArrowBackIcon,
  AddCircle as MuiAddCircleIcon,
  CloudDownload as MuiCloudDownloadIcon,
  NavigateNext as MuiNavigateNextIcon,
} from "@mui/icons-material";
import { useAlerts, useUsers } from "common";
import {
  getAllDocuments,
  deleteDocument,
} from "app/services/commitmentsService";
import { useCommitmentContext } from "app/services/commitmentContext";
import { DocumentTypes } from "app/shared/constants";
import { useDownload } from "app/shared/ui/Download";
import { PositionedSnackbar } from "app/shared/ui/SnackBar";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";

function EnhancedTableHead(props) {
  const { t } = useTranslation();
  const { onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { docSort } = useCommitmentContext();
  const { currentUser } = useUsers();

  const prefColumn = docSort.column;
  const prefOrderBy = docSort.orderBy;

  const commitmentDocumentFieldStr =
    "Commitments.commitmentDocuments.mainView.list.tableColumns";

  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell
          align="left"
          key="type"
          sortDirection={prefColumn === "documentType" ? prefOrderBy : "asc"}
        >
          <MuiTableSortLabel
            active={prefColumn === "documentType"}
            direction={prefColumn === "documentType" ? prefOrderBy : "asc"}
            onClick={createSortHandler("documentType")}
          >
            {t(`${commitmentDocumentFieldStr}.type`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="name"
          sortDirection={prefColumn === "documentDetail" ? prefOrderBy : "asc"}
        >
          <MuiTableSortLabel
            active={prefColumn === "documentDetail"}
            direction={prefColumn === "documentDetail" ? prefOrderBy : "asc"}
            onClick={createSortHandler("documentDetail")}
          >
            {t(`${commitmentDocumentFieldStr}.detail`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="fileName"
          sortDirection={prefColumn === "fileName" ? prefOrderBy : "asc"}
        >
          <MuiTableSortLabel
            active={prefColumn === "fileName"}
            direction={prefColumn === "fileName" ? prefOrderBy : "asc"}
            onClick={createSortHandler("fileName")}
          >
            {t(`${commitmentDocumentFieldStr}.fileName`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="uploadedDate"
          sortDirection={prefColumn === "uploadedDate" ? prefOrderBy : "asc"}
        >
          <MuiTableSortLabel
            active={prefColumn === "uploadedDate"}
            direction={prefColumn === "uploadedDate" ? prefOrderBy : "asc"}
            onClick={createSortHandler("uploadedDate")}
          >
            {t(`${commitmentDocumentFieldStr}.uploadedDate`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="uploadedBy"
          sortDirection={prefColumn === "uploadedBy" ? prefOrderBy : "asc"}
        >
          <MuiTableSortLabel
            active={prefColumn === "uploadedBy"}
            direction={prefColumn === "uploadedBy" ? prefOrderBy : "asc"}
            onClick={createSortHandler("uploadedBy")}
          >
            {t(`${commitmentDocumentFieldStr}.uploadedBy`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        {currentUser.permissions.ADD_DOCUMENTS && (
          <MuiTableCell
            align="left"
            key="adminView"
            sortDirection={
              prefColumn === "departmentAdminView" ? prefOrderBy : "asc"
            }
          >
            <MuiTableSortLabel
              active={prefColumn === "departmentAdminView"}
              direction={
                prefColumn === "departmentAdminView" ? prefOrderBy : "asc"
              }
              onClick={createSortHandler("departmentAdminView")}
            >
              {t(`${commitmentDocumentFieldStr}.adminView`)}
            </MuiTableSortLabel>
          </MuiTableCell>
        )}
        <MuiTableCell align="center" key="actions" width="120px">
          {t(`${commitmentDocumentFieldStr}.actions`)}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export const CommitmentDocuments = (props) => {
  const location = useLocation();
  const params = useParams();
  const { commitmentId } = params;
  const { getDocument, downloadInProgress } = useDownload();
  const [commitmentName, setCommitmentName] = useState();
  const [, setMessageObj] = useState();
  const { t } = useTranslation();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("documentDetail");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [documents, setDocuments] = useState([]);
  const [, setOffset] = useState(page * rowsPerPage);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [deleteDocumentRecord, setDeleteDocumentRecord] = useState({});
  const [snackBar, setSnackBar] = useState(false);
  const { currentUser } = useUsers();
  const { setDocSort, docPagination, setDocPagination } =
    useCommitmentContext();

  const { clearAlert, setAlert } = useAlerts();
  const [refresh, setRefresh] = useState(0);

  const { permissions } = currentUser;
  const commitmentDocumentsDelete = "Commitments.commitmentDocuments.delete";
  const commitmentDocumentsMainView =
    "Commitments.commitmentDocuments.mainView";

  useEffect(() => {
    if (permissions.VIEW_DOCUMENTS) {
      getAllDocuments(
        setDocuments,
        setTotalCount,
        setLoading,
        setCommitmentName,
        setAlert,
        clearAlert,
        {
          page,
          rowsPerPage,
          orderBy,
          order,
          commitmentId,
        }
      );
      location.state && setMessageObj(location.state);
    }
    // eslint-disable-next-line
  }, [
    page,
    rowsPerPage,
    orderBy,
    order,
    commitmentId,
    permissions,
    location.state,
    refresh,
  ]);

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);
    setDocSort({
      column: property,
      orderBy: isAsc,
    });
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
    setOffset(page * rowsPerPage);
    setDocPagination({
      ...docPagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setDocPagination({
      ...docPagination,
      rowsPerPage: parseInt(event.target.value, 10),
    });
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      totalCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      setPage(0);
    }
  };

  //Function calls as per the confirmation from user
  const handleDeleteDocument = () => {
    setLoading(true);
    deleteDocument(
      commitmentId,
      deleteDocumentRecord.documentId,
      setRefresh,
      setSnackBar,
      setDeleteDocumentRecord,
      setAlert,
      clearAlert
    );
  };

  return (
    permissions.VIEW_DOCUMENTS && (
      <MuiGrid container>
        {snackBar && (
          <PositionedSnackbar
            message={t(`${commitmentDocumentsDelete}.notification.success`)}
          />
        )}
        <MuiGrid container item xs={12}>
          <MuiGrid item container xs={6} justifyContent="flex-start">
            <MuiBreadcrumbs
              separator={<MuiNavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <RouterLink
                to={`/commitments`}
                style={{ textDecoration: "none" }}
              >
                <MuiTypography color="textSecondary" variant="body2">
                  {t("Commitments.mainView.title")}
                </MuiTypography>
              </RouterLink>
              <MuiTypography color="textPrimary" variant="subtitle1">
                {permissions.VIEW_DEPARTMENT_DOCUMENT
                  ? t(`${commitmentDocumentsMainView}.view.breadcrumbTitle`)
                  : t(`${commitmentDocumentsMainView}.documentsBreadCrumbLink`)}
              </MuiTypography>
            </MuiBreadcrumbs>
          </MuiGrid>
          {permissions.VIEW_DEPARTMENT_DOCUMENT && (
            <MuiGrid item container xs={6} justifyContent="flex-end">
              <RouterLink
                style={{ textDecoration: "none" }}
                to={`/commitments`}
              >
                <MuiButton startIcon={<MuiArrowBackIcon />}>
                  {t("Commitments.view.goBackButtonLabel")}
                </MuiButton>
              </RouterLink>
            </MuiGrid>
          )}
        </MuiGrid>
        <MuiGrid item xs={12}>
          <MuiTypography variant="h1">
            {t(`${commitmentDocumentsMainView}.title`)} {commitmentName}
          </MuiTypography>
        </MuiGrid>
        {permissions.ADD_DOCUMENTS && (
          <MuiGrid container item xs={12} justifyContent="flex-end">
            <RouterLink
              style={{ textDecoration: "none" }}
              to={
                commitmentId
                  ? `/commitments/${commitmentId}/documents/new`
                  : "/commitments"
              }
            >
              <MuiButton
                variant="contained"
                color="primary"
                startIcon={<MuiAddCircleIcon />}
              >
                {t(`${commitmentDocumentsMainView}.textForAddDocumentButton`)}
              </MuiButton>
            </RouterLink>
          </MuiGrid>
        )}

        <MuiGrid item xs={12} marginTop="16px">
          <MuiTableContainer component={MuiPaper}>
            <MuiTable aria-label="customized table" stickyHeader>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <MuiTableBody>
                {Object.keys(deleteDocumentRecord).length > 0 && (
                  <ConfirmationDialog
                    open={true}
                    title={t(`${commitmentDocumentsDelete}.dialog.title`)}
                    message={t(`${commitmentDocumentsDelete}.dialog.content`, {
                      documentName: `"${deleteDocumentRecord.name}"`,
                    })}
                    okLabel={t(
                      `${commitmentDocumentsDelete}.dialog.deleteButton`
                    )}
                    cancelLabel={t(
                      `${commitmentDocumentsDelete}.dialog.cancelButton`
                    )}
                    handleCancel={() => {
                      setDeleteDocumentRecord({});
                    }}
                    handleOk={() => {
                      handleDeleteDocument();
                    }}
                  />
                )}
                {loading && (
                  <MuiTableRow>
                    <MuiTableCell
                      colSpan={permissions.ADD_DOCUMENTS ? "7" : "6"}
                      sx={{ textAlign: "center" }}
                    >
                      <MuiCircularProgress />
                    </MuiTableCell>
                  </MuiTableRow>
                )}

                {!loading &&
                  documents.map((row, index) => (
                    <MuiTableRow key={index}>
                      <MuiTableCell align="left" component="th" scope="row">
                        {t(DocumentTypes[row.documentType])}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        <MuiTooltip
                          title={
                            <div>
                              {t(
                                `${commitmentDocumentsMainView}.list.documentNotesTitle`
                              )}
                              <br />
                              <br />
                              {row.documentNotes}
                            </div>
                          }
                          placement="bottom"
                        >
                          <MuiTypography variant="body2">
                            {row.documentDetail}
                          </MuiTypography>
                        </MuiTooltip>
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        <MuiLink
                          component={"button"}
                          disabled={!!downloadInProgress}
                          onClick={() => {
                            const docId = row._links.self.href.substring(
                              row._links.self.href.lastIndexOf("/") + 1,
                              row._links.self.href.length
                            );
                            getDocument(
                              `/commitments/${commitmentId}/documents/${docId}/download`,
                              row.fileName
                            );
                          }}
                        >
                          <MuiTypography variant="body2">
                            {row.fileName}
                          </MuiTypography>
                        </MuiLink>
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {row.uploadedDate &&
                          new Date(row.uploadedDate).toLocaleDateString()}
                      </MuiTableCell>
                      <MuiTableCell align="left">{row.uploadedBy}</MuiTableCell>
                      {permissions.ADD_DOCUMENTS && (
                        <MuiTableCell align="left">
                          {row.departmentAdminView === true && <MuiCheckIcon />}
                        </MuiTableCell>
                      )}
                      <MuiTableCell align="center">
                        <MuiGrid container justifyContent="space-around">
                          {row._links.edit && (
                            <MuiGrid item>
                              <RouterLink
                                style={{ textDecoration: "none" }}
                                to={{
                                  pathname: `/commitments/${commitmentId}/documents/${new URL(
                                    row._links.edit.href
                                  ).pathname
                                    .split("/")
                                    .pop()}/edit`,
                                }}
                              >
                                <MuiTooltip
                                  title={t(
                                    `${commitmentDocumentsMainView}.list.editCommitmentDocumentsTooltip`
                                  )}
                                >
                                  <MuiIconButton size="small">
                                    <MuiEditIcon fontSize="small" />
                                  </MuiIconButton>
                                </MuiTooltip>
                              </RouterLink>
                            </MuiGrid>
                          )}
                          {row._links.delete && (
                            <MuiGrid item>
                              <MuiTooltip
                                title={t(
                                  `${commitmentDocumentsMainView}.list.deleteCommitmentDocumentsTooltip`
                                )}
                              >
                                <MuiIconButton
                                  size="small"
                                  onClick={() => {
                                    setDeleteDocumentRecord({
                                      name: row.documentDetail,
                                      documentId:
                                        row._links.delete.href.substring(
                                          row._links.delete.href.lastIndexOf(
                                            "/"
                                          ) + 1,
                                          row._links.delete.href.length
                                        ),
                                    });
                                    setSnackBar(false);
                                  }}
                                >
                                  <MuiDeleteIcon fontSize="small" />
                                </MuiIconButton>
                              </MuiTooltip>
                            </MuiGrid>
                          )}
                          {row._links.view && (
                            <MuiGrid item>
                              <MuiTooltip
                                title={t(
                                  `${commitmentDocumentsMainView}.list.downloadCommitmentDocumentsTooltip`
                                )}
                              >
                                <span>
                                  <MuiIconButton
                                    size="small"
                                    disabled={!!downloadInProgress}
                                    onClick={() => {
                                      const docId =
                                        row._links.self.href.substring(
                                          row._links.self.href.lastIndexOf(
                                            "/"
                                          ) + 1,
                                          row._links.self.href.length
                                        );
                                      getDocument(
                                        `/commitments/${commitmentId}/documents/${docId}/download`,
                                        row.fileName
                                      );
                                    }}
                                  >
                                    {downloadInProgress ? (
                                      <MuiCircularProgress size={15} />
                                    ) : (
                                      <MuiCloudDownloadIcon fontSize="small" />
                                    )}
                                  </MuiIconButton>
                                </span>
                              </MuiTooltip>
                            </MuiGrid>
                          )}
                        </MuiGrid>
                      </MuiTableCell>
                    </MuiTableRow>
                  ))}

                {!loading && !documents.length && (
                  <MuiTableRow>
                    <MuiTableCell
                      colSpan={permissions.ADD_DOCUMENTS ? "7" : "6"}
                    >
                      <MuiTypography
                        variant="body1"
                        color="inherit"
                        align="center"
                        paragraph={false}
                      >
                        {t("globals.list.messages.noData")}
                      </MuiTypography>
                    </MuiTableCell>
                  </MuiTableRow>
                )}
              </MuiTableBody>
            </MuiTable>
            <MuiTablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={docPagination.rowsPerPage}
              page={docPagination.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MuiTableContainer>
        </MuiGrid>
        {permissions.VIEW_DEPARTMENT_DOCUMENT && (
          <MuiGrid item xs={12} marginTop="16px">
            <MuiGrid container justifyContent="flex-end">
              <RouterLink
                style={{ textDecoration: "none" }}
                to={`/commitments`}
              >
                <MuiButton
                  variant="outlined"
                  size="large"
                  startIcon={<MuiArrowBackIcon />}
                >
                  {t("Commitments.view.goBackButtonLabel")}
                </MuiButton>
              </RouterLink>
            </MuiGrid>
          </MuiGrid>
        )}
      </MuiGrid>
    )
  );
};
