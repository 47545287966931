import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  Grid as MuiGrid,
  Paper as MuiPaper,
  Table as MuiTable,
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  Typography as MuiTypography,
  TableContainer as MuiTableContainer,
  TableSortLabel as MuiTableSortLabel,
  TablePagination as MuiTablePagination,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import { ListAlt as MuiListAltIcon } from "@mui/icons-material";
import { useAlerts, useUsers } from "common";
import { getAllPtas } from "app/services/ptasService";
import { usePtaContext } from "app/services/ptasContext";
import { whiteTableHeaderLabel } from "app/shared/ui/sharedStyles";

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const { t } = useTranslation();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const ptaTableStr = "PTAs.mainView.list.tableColumns";

  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell
          align="left"
          key="ptaNumber"
          width="150px"
          sortDirection={orderBy === "ptaNumber" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "ptaNumber"}
            direction={orderBy === "ptaNumber" ? order : "asc"}
            onClick={createSortHandler("ptaNumber")}
          >
            {t(`${ptaTableStr}.pta`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="ptaStatus"
          sortDirection={orderBy === "project.projectStatus" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "project.projectStatus"}
            direction={orderBy === "project.projectStatus" ? order : "asc"}
            onClick={createSortHandler("project.projectStatus")}
          >
            {t(`${ptaTableStr}.ptaStatus`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="taskStatus"
          sortDirection={orderBy === "task.taskStatus" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "task.taskStatus"}
            direction={orderBy === "task.taskStatus" ? order : "asc"}
            onClick={createSortHandler("task.taskStatus")}
          >
            {t(`${ptaTableStr}.taskStatus`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="projectName"
          sortDirection={orderBy === "project.projectName" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "project.projectName"}
            direction={orderBy === "project.projectName" ? order : "asc"}
            onClick={createSortHandler("project.projectName")}
          >
            {t(`${ptaTableStr}.projectName`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="taskName"
          sortDirection={orderBy === "task.taskName" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "task.taskName"}
            direction={orderBy === "task.taskName" ? order : "asc"}
            onClick={createSortHandler("task.taskName")}
          >
            {t(`${ptaTableStr}.taskName`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="projectOrg"
          sortDirection={orderBy === "project.org.orgName" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "project.org.orgName"}
            direction={orderBy === "project.org.orgName" ? order : "asc"}
            onClick={createSortHandler("project.org.orgName")}
          >
            {t(`${ptaTableStr}.projectOrg`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="projectOwner"
          sortDirection={
            orderBy === "project.projectOwnerFullName" ? order : false
          }
        >
          <MuiTableSortLabel
            active={orderBy === "project.projectOwnerFullName"}
            direction={
              orderBy === "project.projectOwnerFullName" ? order : "asc"
            }
            onClick={createSortHandler("project.projectOwnerFullName")}
          >
            {t(`${ptaTableStr}.projectOwner`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="taskOrg"
          sortDirection={orderBy === "task.org.orgName" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "task.org.orgName"}
            direction={orderBy === "task.org.orgName" ? order : "asc"}
            onClick={createSortHandler("task.org.orgName")}
          >
            {t(`${ptaTableStr}.taskOrg`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="taskOwner"
          sortDirection={orderBy === "task.taskOwnerFullName" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "task.taskOwnerFullName"}
            direction={orderBy === "task.taskOwnerFullName" ? order : "asc"}
            onClick={createSortHandler("task.taskOwnerFullName")}
          >
            {t(`${ptaTableStr}.taskOwner`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="center" key="actions">
          {t(`${ptaTableStr}.actions`)}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};

export const PTAs = () => {
  const { t } = useTranslation();

  const { sort, setSort, pagination, setPagination, ptaFilters } =
    usePtaContext();

  const [rowsPerPage, setRowsPerPage] = useState(pagination.rowsPerPage);
  const [page, setPage] = useState(pagination.page);
  const [ptas, setPtas] = useState([]);
  const [ptaCount, setPtaCount] = useState(0);
  const [order, setOrder] = useState(sort.orderBy);
  const [orderBy, setOrderBy] = useState(sort.column);
  const [loading, setLoading] = useState(true);
  const { clearAlert, setAlert } = useAlerts();

  const { currentUser } = useUsers();
  const { permissions } = currentUser;

  useEffect(() => {
    if (permissions.VIEW_PTAS) {
      const project = ptaFilters.project || "";
      const task = ptaFilters.task || "";
      const award = ptaFilters.award || "";
      const projectName = ptaFilters.projectName || "";
      const projectOwner = ptaFilters.projectOwner || "";
      const taskOwner = ptaFilters.taskOwner || "";

      getAllPtas(setPtas, setPtaCount, setLoading, setAlert, clearAlert, {
        page,
        rowsPerPage,
        orderBy,
        order,
        project,
        task,
        award,
        projectName,
        projectOwner,
        taskOwner,
      });
    }
    // eslint-disable-next-line
  }, [
    page,
    rowsPerPage,
    orderBy,
    order,
    permissions,
    ptaFilters.project,
    ptaFilters.task,
    ptaFilters.award,
    ptaFilters.projectName,
    ptaFilters.projectOwner,
    ptaFilters.taskOwner,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);
    setSort({
      column: property,
      orderBy: isAsc,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPagination({
      ...pagination,
      rowsPerPage: parseInt(event.target.value, 10),
    });
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      ptaCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      setPage(0);
    }
  };

  return (
    <MuiGrid
      container
      alignItems="flex-start"
      justifyContent="flex-end"
      direction="row"
      spacing={2}
    >
      {permissions.VIEW_PTAS && (
        <MuiGrid item xs={12}>
          <MuiTableContainer component={MuiPaper}>
            <MuiTable
              aria-label="customized table"
              stickyHeader
              sx={whiteTableHeaderLabel}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <MuiTableBody>
                {loading ? (
                  <MuiTableRow>
                    <MuiTableCell colSpan="10" sx={{ textAlign: "center" }}>
                      <MuiCircularProgress />
                    </MuiTableCell>
                  </MuiTableRow>
                ) : (
                  false
                )}
                {!loading && ptas && Array.isArray(ptas) && ptas.length > 0 ? (
                  ptas.map((row, index) => (
                    <MuiTableRow key={index}>
                      <MuiTableCell align="left" component="th" scope="row">
                        <RouterLink
                          style={{ textDecoration: "none" }}
                          to={{
                            pathname:
                              "/ptas/" +
                              new URL(row._links.self.href).pathname
                                .split("/")
                                .pop(),
                          }}
                        >
                          <MuiTypography variant="body2">
                            {row.project.projectNumber}-{row.task.taskNumber}-
                            {row.award.awardNumber}
                          </MuiTypography>
                        </RouterLink>
                      </MuiTableCell>
                      <MuiTableCell align="left">{row.ptaStatus}</MuiTableCell>
                      <MuiTableCell align="left">
                        {row.task.taskStatus}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {row.project.projectName}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {row.task.taskName}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {row.project.projectOrgCodeName}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {row.project.projectOwnerFullName}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {row.task.taskOrgCodeName}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {row.task.taskOwnerFullName}
                      </MuiTableCell>
                      <MuiTableCell align="center">
                        {row._links.viewDetail && (
                          <RouterLink
                            style={{ textDecoration: "none" }}
                            to={{
                              pathname:
                                "/ptas/" +
                                new URL(row._links.viewDetail.href).pathname
                                  .split("/")
                                  .pop(),
                            }}
                          >
                            <MuiTooltip
                              title={t("PTAs.mainView.list.actionsColToolTip")}
                            >
                              <MuiListAltIcon fontSize="small" />
                            </MuiTooltip>
                          </RouterLink>
                        )}
                      </MuiTableCell>
                    </MuiTableRow>
                  ))
                ) : !loading ? (
                  <MuiTableRow>
                    <MuiTableCell colSpan="10">
                      <MuiTypography
                        variant="body1"
                        color="inherit"
                        align="center"
                        paragraph={false}
                      >
                        {t("globals.list.messages.noData")}
                      </MuiTypography>
                    </MuiTableCell>
                  </MuiTableRow>
                ) : (
                  false
                )}
              </MuiTableBody>
            </MuiTable>
            <MuiTablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={ptaCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MuiTableContainer>
        </MuiGrid>
      )}
    </MuiGrid>
  );
};
