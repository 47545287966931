import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const ComponentTableHead = (props) => {
  const { t } = useTranslation();

  const tableColumnsStr =
    "UploadedTransfers.mainView.list.redistributeTransfersDialog.tableColumns.componentDetail.";

  const detailsPageCommonStr =
    "UploadedTransfers.mainView.list.redistributeTransfersDialog.detailsPage";
  return (
    <MuiTableHead>
      <MuiTableRow>
        {!props.showDetailsView && (
          <MuiTableCell align="left" key="recordNumber" width="6%">
            {t(`${tableColumnsStr}rec`)}
          </MuiTableCell>
        )}
        <MuiTableCell align="left" key="commitmentName" width="25%">
          {props.showDetailsView &&
            `${t(`${detailsPageCommonStr}.destinationLabel`)} \n`}
          {t(`${tableColumnsStr}commitmentName`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="componentName" width="25%">
          {t(`${tableColumnsStr}componentName`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="pta" width="20%">
          {t(`${tableColumnsStr}pta`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="redistributedAmount" width="20%">
          {t(`${tableColumnsStr}redistributedAmount`)}
        </MuiTableCell>
        {props.showDetailsView && (
          <MuiTableCell align="left" key="mtdAmount" width="15%">
            {t(`${detailsPageCommonStr}.mtdAmount`)}
          </MuiTableCell>
        )}
      </MuiTableRow>
    </MuiTableHead>
  );
};
