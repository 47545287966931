import { useTranslation } from "react-i18next";
import {
  TableRow as MuiTableRow,
  TableHead as MuiTableHead,
  TableCell as MuiTableCell,
} from "@mui/material";
import {
  getLastYearProjectionHeading,
  getCurrentYearBudgetHeading,
  getNextYearPlanHeading,
  getYearAfterNextPlanHeading,
} from "app/components/Budgeting/Budgeting";

export const CommitmentComponentRequestHeaders = (props) => {
  const { t } = useTranslation();
  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell align="left" key="commitmentName" width="250">
          {t(
            "Commitments.commitmentComponents.mainView.list.budgetTableHeaders.componentName"
          )}
          {!props.isViewOnly && <sup>*</sup>}
        </MuiTableCell>
        <MuiTableCell align="right" key="amount" width="100">
          {t(
            "Commitments.commitmentComponents.mainView.list.budgetTableHeaders.amount"
          )}
        </MuiTableCell>
        <MuiTableCell align="right" key="projection" width="100">
          {getLastYearProjectionHeading(t, props.currentFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyBudgetTwoBudget" width="100">
          {getCurrentYearBudgetHeading(t, props.currentFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyPlanOnePlan" width="100">
          {getNextYearPlanHeading(t, props.currentFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyPlanTwoPlan" width="100">
          {getYearAfterNextPlanHeading(t, props.currentFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="left" key="startDate" width="138">
          {t(
            "Commitments.commitmentComponents.mainView.list.budgetTableHeaders.startDate"
          )}
        </MuiTableCell>
        <MuiTableCell align="left" key="endDate" width="138">
          {t(
            "Commitments.commitmentComponents.mainView.list.budgetTableHeaders.endDate"
          )}
        </MuiTableCell>
        <MuiTableCell align="left" key="requestComments" width="140">
          {t(
            "Commitments.commitmentComponents.mainView.list.budgetTableHeaders.requestComments"
          )}
        </MuiTableCell>
        {!props.isViewOnly && (
          <MuiTableCell align="center" key="actions" width="50">
            {t(`Budgeting.mainView.list.tableColumns.actions`)}
          </MuiTableCell>
        )}
      </MuiTableRow>
    </MuiTableHead>
  );
};
