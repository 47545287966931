import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Theme, useUsers } from "common";

export const ComponentRequestTableHeaders = ({ budgetFiscalYear }) => {
  const { t } = useTranslation();
  const { currentUser } = useUsers();
  const { roles } = currentUser;

  const isCommitmentAdmin = roles.includes("COMMITMENT_ADMIN");

  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell align="left" scope="col" width="40px">
          {t(`Requests.mainView.list.tableColumns.year`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="department" scope="col" width="115px">
          {t(`Requests.mainView.list.tableColumns.dispositionStatus`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="commitmentName" scope="col">
          {t(`Requests.mainView.list.tableColumns.componentName`)}
        </MuiTableCell>
        <MuiTableCell align="right" scope="col" width="135px">
          {t(`Requests.mainView.list.tableColumns.requested`)}
        </MuiTableCell>
        <MuiTableCell align="right" scope="col" width="160px">
          {t(`Requests.mainView.list.tableColumns.approved`)}
        </MuiTableCell>
        <MuiTableCell align="right" scope="col" width="120px">
          {t(`Requests.mainView.list.tableColumns.fyProjection`, {
            fyProjection: (parseInt(budgetFiscalYear, 10) % 100) - 1,
          })}
        </MuiTableCell>
        <MuiTableCell align="right" scope="col" width="120px">
          {t(`Requests.mainView.list.tableColumns.fyBudget`, {
            fyBudgetOne: parseInt(budgetFiscalYear, 10) % 100,
          })}
        </MuiTableCell>
        {isCommitmentAdmin && (
          <MuiTableCell
            align="left"
            scope="col"
            sx={{
              width: "120px",
              [Theme.breakpoints.up("1700")]: {
                width: "200px",
              },
              [Theme.breakpoints.up("1900")]: {
                width: "300px",
              },
            }}
          >
            {t(`Requests.mainView.list.tableColumns.dispositionNotes`)}
          </MuiTableCell>
        )}
        <MuiTableCell
          align="left"
          scope="col"
          sx={{
            width: "120px",
            [Theme.breakpoints.up("1700")]: {
              width: "200px",
            },
            [Theme.breakpoints.up("1900")]: {
              width: "300px",
            },
          }}
        >
          {t(`Requests.mainView.list.tableColumns.dispositionComments`)}
        </MuiTableCell>
        <MuiTableCell align="center" scope="col" sx={{ width: "82px" }}>
          {" "}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};
