import { useTranslation } from "react-i18next";
import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableFooter as MuiTableFooter,
} from "@mui/material";
import { Theme } from "common";
import { CurrencyFormatMarkup } from "app/components/Budgeting/BudgetTable";
import {
  getThreeYearsAgoActualsHeading,
  getYearBeforeLastActualsHeading,
  getLastYearActualsHeading,
  getLastYearBudgetHeading,
  getLastYearProjectionHeading,
  getCurrentYearBudgetHeading,
  getNextYearPlanHeading,
  getYearAfterNextPlanHeading,
  getYearAfterNextAvailableHeading,
} from "./Budgeting";

const getAmount = (amount) => {
  if (amount || amount === 0) {
    return CurrencyFormatMarkup(amount);
  }
};

const footerCellStyles = {
  color: Theme.palette.text.primary,
  backgroundColor: Theme.palette.primary.light,
};

export const BudgetTableFooter = ({ budget, budgetFiscalYear }) => {
  const { t } = useTranslation();
  const commonTranslationStr = "Budgeting.mainView.list.tableColumns";

  return (
    <MuiTableFooter
      sx={{ boxShadow: `0 12px 20px 12px ${Theme.palette.grey[500]}` }}
    >
      <MuiTableRow>
        <MuiTableCell
          align="left"
          key="commitmentName"
          sx={footerCellStyles}
          scope="row"
        ></MuiTableCell>
        <MuiTableCell align="right" key="totalCommitment" sx={footerCellStyles}>
          {t(`${commonTranslationStr}.totalCommitment`)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyActualsThree" sx={footerCellStyles}>
          {getThreeYearsAgoActualsHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyActualsTwo" sx={footerCellStyles}>
          {getYearBeforeLastActualsHeading(t, budgetFiscalYear)}
        </MuiTableCell>

        <MuiTableCell
          align="right"
          key="totalExpendituresTotal"
          sx={footerCellStyles}
        >
          {t(`${commonTranslationStr}.totalExpenditures`)}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="unspentCommitments"
          sx={footerCellStyles}
        >
          {t(`${commonTranslationStr}.unspentCommitments`)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyActualsOne" sx={footerCellStyles}>
          {getLastYearActualsHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyBudgetOne" sx={footerCellStyles}>
          {getLastYearBudgetHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyProjection" sx={footerCellStyles}>
          {getLastYearProjectionHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyBudgetTwo" sx={footerCellStyles}>
          {getCurrentYearBudgetHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyPlanOne" sx={footerCellStyles}>
          {getNextYearPlanHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fyPlanTwo" sx={footerCellStyles}>
          {getYearAfterNextPlanHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell align="right" key="availableAfter" sx={footerCellStyles}>
          {getYearAfterNextAvailableHeading(t, budgetFiscalYear)}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="fyBudgetCap"
          sx={footerCellStyles}
        ></MuiTableCell>
        <MuiTableCell
          align="left"
          key="comments"
          sx={footerCellStyles}
        ></MuiTableCell>
        <MuiTableCell
          align="left"
          key="actions"
          sx={footerCellStyles}
        ></MuiTableCell>
      </MuiTableRow>
      <MuiTableRow>
        <MuiTableCell align="left" key="commitmentName" sx={footerCellStyles}>
          {t(`${commonTranslationStr}.grantTotal`)}
        </MuiTableCell>
        <MuiTableCell align="right" key="totalCommitment" sx={footerCellStyles}>
          {getAmount(budget.totalCommitmentRequested)}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="fYTotalActualsThree"
          sx={footerCellStyles}
        >
          {getAmount(budget.fyTotalActualsThree)}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="fYTotalActualsTwo"
          sx={footerCellStyles}
        >
          {getAmount(budget.fyTotalActualsTwo)}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="totalExpendituresTotal"
          sx={footerCellStyles}
        >
          {getAmount(budget.totalExpenditure)}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="totalUnspentCommitment"
          sx={footerCellStyles}
        >
          {getAmount(budget.totalUnspentCommitment)}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="fyTotalActualsOne"
          sx={footerCellStyles}
        >
          {getAmount(budget.fyTotalActualsOne)}
        </MuiTableCell>

        <MuiTableCell
          align="right"
          key="fYTotalBudgetOne"
          sx={footerCellStyles}
        >
          {getAmount(budget.fyTotalBudgetOne)}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="fYTotalProjectionOne"
          sx={footerCellStyles}
        >
          {getAmount(budget.fyTotalProjectionOne)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fYTotalBudget" sx={footerCellStyles}>
          {getAmount(budget.fyTotalBudget)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fYTotalPlanOne" sx={footerCellStyles}>
          {getAmount(budget.fyTotalPlanOne)}
        </MuiTableCell>
        <MuiTableCell align="right" key="fYTotalPlanTwo" sx={footerCellStyles}>
          {getAmount(budget.fyTotalPlanTwo)}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="fYTotalAvailableAfterTwo"
          sx={footerCellStyles}
        >
          {getAmount(budget.fyTotalAvailableAfterTwo)}
        </MuiTableCell>
        <MuiTableCell
          align="right"
          key="fyBudgetCap"
          sx={footerCellStyles}
        ></MuiTableCell>
        <MuiTableCell
          align="left"
          key="comments"
          sx={footerCellStyles}
        ></MuiTableCell>
        <MuiTableCell
          align="left"
          key="actions"
          sx={footerCellStyles}
        ></MuiTableCell>
      </MuiTableRow>
    </MuiTableFooter>
  );
};
