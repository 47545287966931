import { useTranslation } from "react-i18next";
import { TextField as MuiTextField } from "@mui/material";
import { useState } from "react";

export const CommitmentProjectGroupField = ({
  required,
  error,
  helperText,
  ...passedParams
}) => {
  const { t } = useTranslation();

  const [projectGroupError] = useState({
    exist: false,
    message: null,
  });

  return (
    <MuiTextField
      id="projectGroup"
      label={t(`Commitments.create.form.fields.projectGroup.label`)}
      placeholder={"Select Unit"}
      fullWidth
      variant="outlined"
      required={required}
      readOnly
      autoComplete="off"
      InputLabelProps={{ shrink: true }}
      helperText={projectGroupError.message || helperText}
      {...passedParams}
    />
  );
};
