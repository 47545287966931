import {
  Grid as MuiGrid,
  Button as MuiButton,
  Dialog as MuiDialog,
  Typography as MuiTypography,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { postAdvanceGLPeriod } from "app/services/transfersService";
import { useAlerts } from "common";

export const ConfirmationDialog = (props) => {
  const { t } = useTranslation();
  const { clearAlert, setAlert } = useAlerts();
  const commonTranslationString =
    "UploadedTransfers.uploadTransfersDialog.advanceGLPeriod";

  return (
    <MuiDialog open={props.open} fullWidth maxWidth="sm">
      <MuiDialogTitle>
        {t(`${commonTranslationString}.confirmationDialog.title`)}
      </MuiDialogTitle>
      <MuiDialogContent>
        <MuiGrid container spacing={2} alignItems={"center"}>
          <MuiGrid item xs={12}>
            <MuiTypography color={"textSecondary"}>
              {t(`${commonTranslationString}.confirmationDialog.description`)}
            </MuiTypography>
          </MuiGrid>
          <MuiGrid item xs={12}>
            <MuiTypography color={"textSecondary"}>
              {t(
                `${commonTranslationString}.confirmationDialog.confirmationText`,
                {
                  glPeriod: props.advanceGLPeriod,
                }
              )}
            </MuiTypography>
          </MuiGrid>
        </MuiGrid>
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton onClick={props.handleClose} color="primary">
          {t(`${commonTranslationString}.actionButtons.no`)}
        </MuiButton>
        <MuiButton
          color="primary"
          onClick={() =>
            postAdvanceGLPeriod(
              props.advanceGLPeriod,
              props.setShowConfirmationDialog,
              props.setOpenUploadTransfersDialog,
              props.location,
              clearAlert,
              setAlert
            )
          }
        >
          {t(`${commonTranslationString}.actionButtons.yes`)}
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};
