import axios from "axios";
import { Fragment, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { FastField, FieldArray, Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  Grid as MuiGrid,
  Paper as MuiPaper,
  Table as MuiTable,
  Dialog as MuiDialog,
  TableRow as MuiTableRow,
  TableHead as MuiTableHead,
  TextField as MuiTextField,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
  DialogTitle as MuiDialogTitle,
  Autocomplete as MuiAutocomplete,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  TableContainer as MuiTableContainer,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import { useAlerts } from "common";
import {
  getCoInvestmentByMatch,
  prepareCommitmentData,
} from "app/services/commitmentsService";
import {
  getCommitmentRequestById,
  patchCommitmentRequest,
} from "app/services/requestsService";
import { CommitmentTypes } from "app/shared/constants";
import { getIsRequiredText } from "app/shared/utils";
import { FormButton } from "app/shared/ui/FormButton";
import { NumberFormatField } from "app/shared/ui/NumberFormatField";
import { CurrencyFormat } from "app/shared/ui/CurrencyFormat";
import { disabledTextFieldStyles } from "app/shared/ui/sharedStyles";
import { CommitmentPresentationField } from "app/components/Commitments/CommitmentForm/CommitmentPresentationField";
import { CommitmentPresentationSubcategoryField } from "app/components/Commitments/CommitmentForm/CommitmentPresentationSubcategoryField";
import { CommitmentProgramField } from "app/components/Commitments/CommitmentForm/CommitmentProgramField";
import { CommitmentProjectGroupField } from "app/components/Commitments/CommitmentForm/CommitmentProjectGroupField";
import { CommitmentTypeField } from "app/components/Commitments/CommitmentForm/CommitmentTypeField";
import { CommitmentUnitField } from "app/components/Commitments/CommitmentForm/CommitmentUnitField";
import { ComponentExpirationDateField } from "app/components/Commitments/CommitmentComponents/ComponentExpirationDateField";
import { ComponentOwnerField } from "app/components/Commitments/CommitmentComponents/ComponentOwnerField";
import { ComponentStartDateField } from "app/components/Commitments/CommitmentComponents/ComponentStartDateField";
import { ComponentTypeField } from "app/components/Commitments/CommitmentComponents/ComponentTypeField";

export const RequestsCompletionDialog = ({
  openDialog: commitmentId,
  setOpenDialog,
  pushSnackbarMessage,
  setRefresh,
}) => {
  const { t } = useTranslation();
  const { setAlert, clearAlert } = useAlerts();

  const [showMemoAmount, setShowMemoAmount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coInvestmentValue, setCoInvestmentValue] = useState("");
  const [coInvestmentInputValue, setCoInvestmentInputValue] = useState("");
  const [coInvestmentOptions, setCoInvestmentOptions] = useState([]);
  const [departmentNameValue, setDepartmentNameValue] = useState({
    orgName: "",
    orgCode: "",
  });

  const arrayHelpersRef = useRef(null);

  const formPreFetchData = {
    commitmentType: "REGULAR", //Enum implementation
  };
  const [formDataResponse, setFormDataResponse] = useState({
    // Object keys use to create/edit form data
    name /* Commitment Name */: "",
    departmentName /* Department Name */: "",
    commitmentType /* Commitment Type */: "",
    projectGroup /* Project Group */: "",
    unit /* Unit */: "",
    program /* Program */: "",
    presentation /* Presentation */: "",
    presentationSubcategory /* Presentation Sub Category */: null,
    memoAmount: 0.0,
    orgCode: "",
    components: [],
  });

  const budgetingValidation = Yup.object().shape({
    memoAmount: Yup.number().when("commitmentType", {
      is: (i) => i && i.toLowerCase() === "memo",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
    projectGroup: Yup.mixed().required(),
    unit: Yup.mixed().required(),
    program: Yup.mixed().required(),
    presentation: Yup.mixed().required(),
    coInvestmentEntity: Yup.mixed().when("presentationSubcategory", {
      is: (i) => i && i.name && i.name.toLowerCase() === "co-investment",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
    components: Yup.array()
      .of(
        Yup.object().shape({
          componentType: Yup.string().when("dispositionStatus", {
            is: (i) => i === "APPROVED",
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          }),
        })
      )
      .compact(),
  });

  const handleClose = () => {
    setOpenDialog(false);
    clearAlert();
  };

  const _handleSubmit = (values) => {
    const cancelSource = axios.CancelToken.source();
    values = prepareCommitmentData(values, formPreFetchData, null);

    // Defines what to do after saving the data
    const onSuccess = () => {
      setOpenDialog(false);
      pushSnackbarMessage(
        "success",
        t("Commitments.create.notification.update", {
          commitmentName: `"${values.name.trim()}"`,
        }),
        true
      );
      setRefresh((value) => value + 1);
    };

    const onError = (error) => {
      pushSnackbarMessage("error", error);
    };

    patchCommitmentRequest(
      values,
      commitmentId,
      () => void 0,
      onError,
      onSuccess,
      cancelSource
    );
  };

  const commitmentIsDeferred = (commitment) => {
    let isDeferred = true;
    commitment.components.forEach((component) => {
      if (component.dispositionStatus === "APPROVED") {
        isDeferred = false;
      }
    });

    return isDeferred;
  };

  // Initialize the value of commitmentType if not defined
  const getCommitmentTypeValue = (values) => {
    if (!values.commitmentType) {
      if (commitmentIsDeferred(values)) {
        values.commitmentType = "DEFERRED";
      } else {
        values.commitmentType = "REGULAR";
      }
    }

    return values.commitmentType;
  };

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    const onBeforeSend = () => {
      clearAlert();
      setLoading(true);
    };
    const onError = (error) => {
      if (!axios.isCancel(error)) {
        setLoading(false);
        setOpenDialog(false);
        setAlert("error", error.message);
      }
    };
    const onSuccess = (responseData) => {
      setLoading(false);
      responseData.components = responseData.components.map((c) => {
        c.componentType =
          c.dispositionStatus === "APPROVED" && c.componentType === "REQUEST"
            ? ""
            : c.componentType;
        c.piOrComponentOwner = c.piOrComponentOwner
          ? { displayText: c.piOrComponentOwner }
          : "";
        return c;
      });
      setFormDataResponse({
        requestOperation: "COMPLETE_REQUEST",
        name: responseData.name,
        departmentName: responseData.departmentName,
        commitmentType:
          responseData.commitmentType === "REQUEST"
            ? ""
            : responseData.commitmentType,
        projectGroup: responseData.projectGroup,
        unit: responseData.unit,
        program: responseData.program,
        presentation: responseData.presentation,
        presentationSubcategory: responseData.presentationSubcategory,
        memoAmount: responseData.memoAmount ? responseData.memoAmount : 0.0,
        coInvestmentEntity: {
          displayText: responseData.coInvestmentEntity || "",
        },
        commitmentRequestType: responseData.commitmentRequestType,
        components: responseData.components,
      });
      setDepartmentNameValue({
        orgName: responseData.departmentName,
        orgCode: responseData.orgCode,
      });
    };

    commitmentId &&
      getCommitmentRequestById(
        commitmentId,
        onBeforeSend,
        onError,
        onSuccess,
        cancelSource
      );

    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [commitmentId]);

  return (
    <MuiDialog
      open={!!commitmentId}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
    >
      <Formik
        initialValues={formDataResponse}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          _handleSubmit(values);
          setSubmitting(false);
        }}
        validationSchema={budgetingValidation}
      >
        {(props) => {
          const /** !Object */ {
              values,
              errors,
              //touched,
              dirty,
              isValid,
              handleSubmit,
              setFieldValue,
              setFieldError,
            } = props;
          return (
            // Native form element to submit the form values
            <form onSubmit={handleSubmit}>
              <MuiDialogTitle id="form-dialog-title">
                {t(`Commitments.editRequest.complete.title`, {
                  commitmentName: values?.name,
                })}
                <MuiTypography variant="subtitle1" component="div">
                  {departmentNameValue?.orgName}
                </MuiTypography>
              </MuiDialogTitle>

              <MuiDialogContent>
                {loading && (
                  <MuiGrid container justifyContent="center">
                    <MuiGrid item>
                      <MuiCircularProgress />
                    </MuiGrid>
                  </MuiGrid>
                )}
                {!loading && (
                  <MuiGrid container gap={2}>
                    <MuiGrid container spacing={2} paddingTop={1}>
                      <MuiGrid item xs={6}>
                        {/* Commitment Type - MuiAutocomplete Input */}
                        <CommitmentTypeField
                          id="commitmentType"
                          name="commitmentType"
                          value={t(
                            CommitmentTypes[getCommitmentTypeValue(values)]
                          )}
                          required
                          includeRequestTypes={false}
                          commitmentIsDeferred={commitmentIsDeferred(values)}
                          onChange={(_event, value) => {
                            setFieldValue(
                              "commitmentType",
                              Object.keys(CommitmentTypes).find(
                                (key) => CommitmentTypes[key] === value
                              )
                            );

                            if (
                              Object.keys(CommitmentTypes).find(
                                (key) => CommitmentTypes[key] === value
                              ) === "MEMO"
                            ) {
                              setShowMemoAmount(true);
                              setFieldValue("memoAmount", 0.0);
                            } else {
                              setShowMemoAmount(false);
                              setFieldValue("memoAmount", "");
                            }
                          }}
                          error={!!errors.commitmentType}
                          helperText={errors.commitmentType}
                        />
                      </MuiGrid>

                      <MuiGrid item xs={6}>
                        {(showMemoAmount ||
                          values.commitmentType === "MEMO") && (
                          <NumberFormatField
                            id="memoAmount"
                            name="memoAmount"
                            label={t(
                              `Commitments.create.form.fields.memoAmount.label`
                            )}
                            setFieldValue={setFieldValue}
                            formValues={values?.memoAmount}
                            restrictions
                            fullWidth
                            autoComplete="off"
                            allowNegative={false}
                            required
                            sx={{
                              "& .MuiOutlinedInput-input, & .MuiInput-input": {
                                textAlign: "right",
                              },
                            }}
                            variant="outlined"
                            helperText={(() => {
                              return (
                                errors.memoAmount &&
                                getIsRequiredText(
                                  t,
                                  t(
                                    `Commitments.create.form.fields.memoAmount.label`
                                  )
                                )
                              );
                            })()}
                            error={!!errors.memoAmount}
                          />
                        )}
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid container spacing={2}>
                      <MuiGrid item xs={4}>
                        {/* Project Group - Auto populate from Unit */}
                        <CommitmentProjectGroupField
                          name="projectGroup"
                          value={
                            values.projectGroup ? values.projectGroup.name : ""
                          }
                          required
                          error={!!errors.projectGroup}
                          sx={disabledTextFieldStyles}
                          helperText={
                            !!errors.projectGroup &&
                            getIsRequiredText(
                              t,
                              t(
                                `Commitments.create.form.fields.projectGroup.label`
                              )
                            )
                          }
                        />
                      </MuiGrid>
                      <MuiGrid item xs={4}>
                        {/* Commitment Unit - MuiAutocomplete Input */}
                        <CommitmentUnitField
                          id="unit"
                          name="unit"
                          value={values.unit || ""}
                          required
                          onChange={(_event, valueObj) => {
                            setFieldValue("unit", valueObj);
                            setFieldValue(
                              "projectGroup",
                              valueObj.projectGroup
                            );
                          }}
                          error={!!errors.unit}
                          helperText={
                            !!errors.unit &&
                            getIsRequiredText(
                              t,
                              t(
                                `Commitments.create.form.fields.commitmentUnit.label`
                              )
                            )
                          }
                        />
                      </MuiGrid>
                      <MuiGrid item xs={4}>
                        {/* Program - MuiAutocomplete Input */}
                        <CommitmentProgramField
                          id="program"
                          name="program"
                          value={values.program || ""}
                          required
                          onChange={(_event, valueObj) => {
                            setFieldValue("program", valueObj);
                          }}
                          error={!!errors.program}
                          helperText={
                            !!errors.program &&
                            getIsRequiredText(
                              t,
                              t(`Commitments.create.form.fields.program.label`)
                            )
                          }
                        />
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid container spacing={2}>
                      <MuiGrid item xs={4}>
                        {/* Presentation - MuiAutocomplete Input */}
                        <CommitmentPresentationField
                          id="presentation"
                          name="presentation"
                          value={values.presentation || ""}
                          required
                          onChange={(_event, valueObj) => {
                            setFieldValue("presentation", valueObj);
                          }}
                          error={!!errors.presentation}
                          helperText={
                            !!errors.presentation &&
                            getIsRequiredText(
                              t,
                              t(
                                `Commitments.create.form.fields.presentation.label`
                              )
                            )
                          }
                        />
                      </MuiGrid>
                      <MuiGrid item xs={4}>
                        {/* Presentation Sub Category - MuiAutocomplete Input */}
                        <CommitmentPresentationSubcategoryField
                          id="presentationSubcategory"
                          name="presentationSubcategory"
                          value={values.presentationSubcategory || ""}
                          onChange={(_event, valueObj) => {
                            if (valueObj.id === undefined) {
                              valueObj = { name: valueObj };
                            }
                            setFieldValue("presentationSubcategory", valueObj);
                          }}
                          error={!!errors.presentationSubcategory}
                          helperText={
                            !!errors.presentationSubcategory &&
                            getIsRequiredText(
                              t,
                              t(
                                `Commitments.create.form.fields.presentationSubCategory.label`
                              )
                            )
                          }
                        />
                      </MuiGrid>
                      {values?.presentationSubcategory?.name?.toLowerCase() ===
                        "co-investment" && (
                        <MuiGrid item xs={4}>
                          {/* Co-Investment Entity - MuiAutocomplete Input */}
                          {/*<CommitmentCoInvestmentEntityField
                          id="coInvestmentEntity"
                          name="coInvestmentEntity"
                          label={t(`Commitments.create.form.fields.coInvestmentEntity.label`)}
                          isValid={
                            touched.coInvestmentEntity &&
                            Boolean(errors.coInvestmentEntity)
                          }
                          onChange={(_event, value) => {
                            setFieldValue("coInvestmentEntity", value);
                          }}
                          value={values?.coInvestmentEntity}
                          required 
                          setFieldError={setFieldError}
                          helperText={
                            !!errors.coInvestmentEntity && getIsRequiredText(
                              t, t(`Commitments.create.form.fields.coInvestmentEntity.label`)
                            )
                          }
                          error={!!errors.coInvestmentEntity}
                        />*/}
                          <MuiAutocomplete
                            id="coInvestmentEntity"
                            name="coInvestmentEntity"
                            options={coInvestmentOptions}
                            getOptionLabel={(option) =>
                              option && option.displayText
                            }
                            value={
                              coInvestmentValue ||
                              values.coInvestmentEntity ||
                              ""
                            }
                            onChange={(_event, value) => {
                              setCoInvestmentValue(value);
                              setFieldValue("coInvestmentEntity", value);
                            }}
                            inputValue={coInvestmentInputValue}
                            onInputChange={(_event, value) => {
                              setCoInvestmentInputValue(value);
                              value &&
                                getCoInvestmentByMatch(
                                  value,
                                  setCoInvestmentOptions,
                                  () => void 0,
                                  setFieldError
                                );
                            }}
                            clearIcon={false}
                            freeSolo
                            renderInput={(params) => (
                              <MuiTextField
                                {...params}
                                label={t(
                                  `Commitments.create.form.fields.coInvestmentEntity.label`
                                )}
                                required
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  onBlur: (event) => {
                                    const value = event.target.value
                                      ? {
                                          displayText: event.target.value,
                                        }
                                      : "";
                                    setCoInvestmentValue(value);
                                    setFieldValue("coInvestmentEntity", value);
                                  },
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  maxLength: 50,
                                }}
                                sx={disabledTextFieldStyles}
                                helperText={
                                  !!errors.coInvestmentEntity &&
                                  getIsRequiredText(
                                    t,
                                    t(
                                      `Commitments.create.form.fields.coInvestmentEntity.label`
                                    )
                                  )
                                }
                                error={!!errors.coInvestmentEntity}
                              />
                            )}
                          />
                        </MuiGrid>
                      )}
                    </MuiGrid>
                  </MuiGrid>
                )}

                {/* Components MuiTable */}
                <MuiGrid container spacing={2} paddingTop={2}>
                  <MuiGrid item xs={12}>
                    <MuiTypography variant="h2">
                      {t(`Commitments.editRequest.complete.componentTitle`)}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item xs={12}>
                    <MuiTableContainer component={MuiPaper}>
                      <MuiTable
                        aria-label="Commitment Components"
                        aria-live="polite"
                        stickyHeader
                      >
                        <MuiTableHead>
                          <MuiTableRow>
                            <MuiTableCell sx={{ width: "14.28571%" }}>
                              {t(
                                `Commitments.editRequest.complete.tableColumns.componentName`
                              )}
                            </MuiTableCell>
                            <MuiTableCell sx={{ width: "14.28571%" }}>
                              {t(
                                `Commitments.commitmentComponents.create.form.fields.componentType.label`
                              )}{" "}
                              <sup>*</sup>
                            </MuiTableCell>
                            <MuiTableCell sx={{ width: "14.28571%" }}>
                              {t(
                                `Commitments.editRequest.complete.tableColumns.componentDescription`
                              )}
                            </MuiTableCell>
                            <MuiTableCell sx={{ width: "14.28571%" }}>
                              {t(
                                `Commitments.editRequest.complete.tableColumns.approvedAmount`
                              )}
                            </MuiTableCell>
                            <MuiTableCell sx={{ width: "14.28571%" }}>
                              {t(
                                `Commitments.commitmentComponents.create.form.fields.piOrComponentOwner.label`
                              )}
                            </MuiTableCell>
                            <MuiTableCell sx={{ width: "14.28571%" }}>
                              {t(
                                `Commitments.commitmentComponents.mainView.list.budgetTableHeaders.startDate`
                              )}
                            </MuiTableCell>
                            <MuiTableCell sx={{ width: "14.28571%" }}>
                              {t(
                                `Commitments.commitmentComponents.mainView.list.budgetTableHeaders.endDate`
                              )}
                            </MuiTableCell>
                          </MuiTableRow>
                        </MuiTableHead>
                        <MuiTableBody>
                          {loading && (
                            <MuiTableRow>
                              <MuiTableCell
                                colSpan={3}
                                sx={{ textAlign: "center" }}
                              >
                                <MuiCircularProgress />
                              </MuiTableCell>
                            </MuiTableRow>
                          )}
                          {!loading && values.components && (
                            <FieldArray name="components">
                              {(arrayHelpers) => {
                                arrayHelpersRef.current = arrayHelpers;
                                return values.components.map((i, indexI) => (
                                  <Fragment key={indexI}>
                                    {i.dispositionStatus === "APPROVED" && (
                                      <MuiTableRow>
                                        <MuiTableCell>
                                          <span>{i?.componentName || ""}</span>
                                        </MuiTableCell>
                                        <MuiTableCell>
                                          <ComponentTypeField
                                            id={`components[${indexI}].componentType`}
                                            value={i.componentType}
                                            required
                                            onChange={(event) => {
                                              setFieldValue(
                                                `components[${indexI}].componentType`,
                                                event.target.value
                                              );
                                            }}
                                            labelText=""
                                            error={
                                              errors?.components &&
                                              errors?.components[indexI]
                                                ?.componentType &&
                                              Boolean(
                                                errors.components[indexI]
                                                  .componentType
                                              )
                                            }
                                          />
                                        </MuiTableCell>
                                        <MuiTableCell>
                                          <FastField
                                            as={MuiTextField}
                                            id={`components[${indexI}].componentDescription`}
                                            value={
                                              i?.componentDescription || ""
                                            }
                                            onChange={(event) => {
                                              setFieldValue(
                                                `components[${indexI}].componentDescription`,
                                                event.currentTarget.value
                                              );
                                            }}
                                            fullWidth
                                            multiline
                                            helperText={
                                              errors?.components &&
                                              errors?.components[indexI]
                                                ?.componentDescription
                                                ? errors.components[indexI]
                                                    .componentDescription
                                                : ""
                                            }
                                            error={
                                              errors?.components &&
                                              errors?.components[indexI]
                                                ?.componentDescription &&
                                              Boolean(
                                                errors.components[indexI]
                                                  .componentDescription
                                              )
                                            }
                                            autoComplete="none"
                                            variant="outlined"
                                            inputProps={{
                                              maxLength: 250,
                                            }}
                                          />
                                        </MuiTableCell>
                                        <MuiTableCell align="right">
                                          {CurrencyFormat(
                                            i.totalComponentAmountApproved || 0
                                          )}
                                        </MuiTableCell>
                                        <MuiTableCell>
                                          <ComponentOwnerField
                                            id={`components[${indexI}].piOrComponentOwner`}
                                            value={i.piOrComponentOwner}
                                            onChange={(_event, value) => {
                                              setFieldValue(
                                                `components[${indexI}].piOrComponentOwner`,
                                                value
                                              );
                                            }}
                                            setFieldError={setFieldError}
                                            sx={disabledTextFieldStyles}
                                            labelText=""
                                            helperText={
                                              errors?.components &&
                                              errors?.components[indexI]
                                                ?.piOrComponentOwner
                                                ? errors.components[indexI]
                                                    .piOrComponentOwner
                                                : ""
                                            }
                                            error={
                                              errors?.components &&
                                              errors?.components[indexI]
                                                ?.piOrComponentOwner &&
                                              Boolean(
                                                errors.components[indexI]
                                                  .piOrComponentOwner
                                              )
                                            }
                                          />
                                        </MuiTableCell>
                                        <MuiTableCell>
                                          <ComponentStartDateField
                                            id={`components[${indexI}].startDate`}
                                            value={i?.startDate}
                                            expirationDate={i?.expirationDate}
                                            onChange={(value) => {
                                              setFieldValue(
                                                `components[${indexI}].startDate`,
                                                value
                                              );
                                            }}
                                          />
                                        </MuiTableCell>
                                        <MuiTableCell>
                                          <ComponentExpirationDateField
                                            id={`components[${indexI}].expirationDate`}
                                            value={i?.expirationDate}
                                            startDate={i?.startDate}
                                            onChange={(value) => {
                                              setFieldValue(
                                                `components[${indexI}].expirationDate`,
                                                value
                                              );
                                            }}
                                          />
                                        </MuiTableCell>
                                      </MuiTableRow>
                                    )}
                                  </Fragment>
                                ));
                              }}
                            </FieldArray>
                          )}
                        </MuiTableBody>
                      </MuiTable>
                    </MuiTableContainer>
                  </MuiGrid>
                </MuiGrid>
              </MuiDialogContent>
              <MuiDialogActions>
                <MuiGrid
                  item
                  container
                  justifyContent="flex-end"
                  xs={3}
                  spacing={2}
                >
                  <FormButton
                    cancel={handleClose}
                    save={{
                      disabled: !dirty || !isValid,
                    }}
                  />
                </MuiGrid>
              </MuiDialogActions>
            </form>
          );
        }}
      </Formik>
    </MuiDialog>
  );
};
